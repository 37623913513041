import { Accordion } from '@abyss/web/ui/Accordion';
import { Alert } from '@abyss/web/ui/Alert';
import { Grid } from '@abyss/web/ui/Grid';
import { Indicator } from '@abyss/web/ui/Indicator';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { Details } from './components/Details';
import { RecommendationResponses } from './components/RecommendationResponses';
import { Styles } from './includes/styles';

/**
 * ActionPaths
 *
 * Displays affiliated action paths with the risk record.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const ActionPaths = (props) => {
  const { handleSearch, riskRecord } = props;

  const actionPaths = riskRecord?.recordActionPaths;
  const eid = riskRecord?.eid;

  return (
    <ErrorHandler location="src/routes/private/Analysis/screens/EidSearch/components/Layout/Main/components/Results/widgets/ActionPaths/ActionPaths.jsx">
      <Styles>
        {isEmpty(actionPaths) ? (
          <Grid.Col span={{ lg: '100%', md: '100%', sm: '100%', xs: '100%' }}>
            <Alert title="There are no actions paths for this risk record." variant="info" />
          </Grid.Col>
        ) : (
          <Accordion isCollapsible type="multiple">
            {actionPaths.map((actionPath) => {
              return (
                <Accordion.Item key={`${actionPath?.name}`} value={actionPath?.name}>
                  <Accordion.Trigger>
                    <Accordion.Header>
                      {actionPath?.manualAssociation === true ? (
                        <div style={{ width: 'auto' }}>
                          <Indicator color="var(--abyss-colors-warning1)" label="M" showZero={false} withBorder>
                            <div style={{ marginRight: 'var(--abyss-space-sm)' }}> {actionPath?.name}</div>
                          </Indicator>
                        </div>
                      ) : (
                        actionPath?.name
                      )}
                    </Accordion.Header>
                  </Accordion.Trigger>
                  <Accordion.Content>
                    <Details actionPath={actionPath} eid={eid} handleSearch={handleSearch} />
                    <RecommendationResponses actionPath={actionPath} />
                  </Accordion.Content>
                </Accordion.Item>
              );
            })}
          </Accordion>
        )}
      </Styles>
    </ErrorHandler>
  );
};

ActionPaths.propTypes = {
  handleSearch: PropTypes.func,
  riskRecord: PropTypes.shape({
    eid: PropTypes.string,
    recordActionPaths: PropTypes.arrayOf(
      PropTypes.shape({
        manualAssociation: PropTypes.bool,
        name: PropTypes.string,
      })
    ),
  }),
};

ActionPaths.defaultProps = {
  handleSearch: () => {},
  riskRecord: {},
};
