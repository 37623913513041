import { dayjs } from '@abyss/web/tools/dayjs';
import { Heading } from '@abyss/web/ui/Heading';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Table as TableComponent } from '@src/components/Table-static';
import { orderBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import configuration from './includes/configuration.json';

/**
 * Table
 *
 * Table to show recommendations from source.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Table = (props) => {
  const { recommendationResponses } = props;

  /**
   * renderCellDobLastModified
   *
   * Displays the date of birth last modified in a particular date/time format.
   *
   * @param value
   * @returns {*|string}
   */
  const renderCellStatusDate = ({ value }) => {
    return dayjs(value).format('MM/DD/YYYY, HH:mm:ss');
  };

  /**
   * Columns for table.
   */
  const columns = useMemo(() => {
    return orderBy(configuration?.initialColumns, ['order'], ['asc']).map((item) => {
      const column = item;

      if (column?.Header === 'Status Date') {
        column.Cell = renderCellStatusDate;
      }

      return column;
    });
  }, []);

  return (
    <ErrorHandler location="src/routes/private/Analysis/screens/EidSearch/components/Layout/Main/components/Results/widgets/ActionPaths/components/RecommendationResponses/components/Table/Table.jsx">
      <Heading
        css={{ marginBottom: 'var(--abyss-space-md) !important', marginTop: 'var(--abyss-space-sm)' }}
        offset={5}
      >
        Recommendation Responses
      </Heading>
      <TableComponent
        {...{
          accessor: 'eidSearch-actionPaths-RecommendationResponses',
          columns,
          configuration,
          noDataMessage: 'No recommendation responses found.',
          rows: recommendationResponses,
        }}
      />
    </ErrorHandler>
  );
};

Table.propTypes = {
  recommendationResponses: PropTypes.arrayOf(
    PropTypes.shape({
      assignedCode: PropTypes.string,
      assignedDescription: PropTypes.string,
      statusCode: PropTypes.string,
      statusDate: PropTypes.string,
    })
  ),
};

Table.defaultProps = {
  recommendationResponses: [],
};
