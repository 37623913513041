import { Box } from '@abyss/web/ui/Box';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { ErrorHandler } from '@src/components/ErrorHandler';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * WarningMessage
 *
 * Displays a warning message to the user when common criteria has changed.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const WarningMessage = (props) => {
  const { actionPath, context, criteria } = props;

  return (
    <ErrorHandler location="src/features/ActionPaths/components/modals/Activation/Activation.jsx">
      <Box
        color="transparent"
        css={{
          border: '1px solid var(--abyss-colors-warning1)',
          marginBottom: 'var(--abyss-space-lg)',
        }}
      >
        <Layout.Stack alignItems="left">
          <div>
            <Layout.Group>
              <div>
                <IconSymbol color="$warning1" icon="warning" size="24px" variant="outlined" />
              </div>
              <div>
                <strong>Common Criteria: {criteria?.name} has changed</strong>
              </div>
            </Layout.Group>
          </div>
          <div>Click the Review Criteria link to review the changes.</div>
          <div>
            {context === 'entrance' && criteria?.context?.includes('entrance') && (
              <Link href={`/action-paths/${actionPath?.id}/edit/automatic/draft/step/1`}>Review Entrance Criteria</Link>
            )}

            {context === 'exit' && criteria?.context?.includes('exit') && (
              <Link href={`/action-paths/${actionPath?.id}/edit/automatic/draft/step/4`}>Review Exit Criteria</Link>
            )}
          </div>
        </Layout.Stack>
      </Box>
    </ErrorHandler>
  );
};

WarningMessage.propTypes = {
  actionPath: PropTypes.shape({
    id: PropTypes.string,
  }),
  context: PropTypes.string,
  criteria: PropTypes.shape({
    context: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string,
  }),
};

WarningMessage.defaultProps = {
  actionPath: {},
  context: '',
  criteria: {},
};
