import { styled } from '@abyss/web/tools/styled';
import { ErrorHandler } from '@src/components/ErrorHandler';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * GlobalStyles
 *
 * @TODO - Needs description.
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const GlobalStyles = (props) => {
  const { children } = props;

  const Styles = styled('div', {
    'main#app-main': {
      minHeight: 'calc(100vh + 150px)',
    },
  });

  return (
    <ErrorHandler location="src/styles/GlobalStyles.jsx">
      <Styles>{children}</Styles>
    </ErrorHandler>
  );
};

GlobalStyles.propTypes = {
  children: PropTypes.node,
};

GlobalStyles.defaultProps = {
  children: null,
};
