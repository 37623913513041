import { dayjs } from '@abyss/web/tools/dayjs';
import { Alert } from '@abyss/web/ui/Alert';
import { Grid } from '@abyss/web/ui/Grid';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Loader } from '@src/components/Loader';
import { Widget } from '@src/components/Widget';
import { useApi } from '@src/context/Api';
import { isEmpty, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Table } from './components/Table';

/**
 * Widget: Events
 *
 * Displays a list of events associated with an action path.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Events = (props) => {
  const { reportDate } = props;

  const [requestArgs, setRequestArgs] = useState({
    endDate: dayjs(reportDate).add(2, 'months').format('YYYY-MM-DD'),
    eventTypeCodeList: [],
    page: 0,
    size: 25,
    sort: 'startDate,desc',
    startDate: dayjs(reportDate).subtract(2, 'months').format('YYYY-MM-DD'),
  });

  const [isLoadingAssets, setIsLoadingAssets] = useState(false);

  const { useApiQueries, useApiQuery } = useApi();

  const theAssets = ['ListEventTypes', 'ListImpactedSystems'];
  const assets = useApiQueries(theAssets);

  const [ListEvents, { data: events, isFetching, isLoading }] = useApiQuery('ListEvents');

  /**
   * Determines the overall loading state of all asset queries.
   */
  useEffect(() => {
    if (
      !isEmpty(assets) &&
      Object.keys(assets).length === theAssets.length &&
      isEmpty(
        Object.keys(assets).filter((assetKey) => {
          const asset = assets[assetKey];
          return !(!asset?.isLoading && !asset?.isFetching);
        })
      )
    ) {
      setIsLoadingAssets(false);
    } else {
      setIsLoadingAssets(true);
    }
  }, [assets, theAssets]);

  /**
   * Updates the event type code list in the list events request once the event types are loaded.
   */
  useEffect(() => {
    if (!isUndefined(assets?.ListEventTypes?.data)) {
      const eventTypeCodeList =
        assets?.ListEventTypes?.data?.map((eventType) => {
          return eventType?.codeId;
        }) || [];

      if (eventTypeCodeList !== requestArgs?.eventTypeCodeList) {
        setRequestArgs({ ...requestArgs, ...{ eventTypeCodeList } });
      }
    }
  }, [assets?.ListEventTypes?.data]);

  /**
   * Makes an api request to list events once the event type code list is updated.
   */
  useEffect(() => {
    if (isUndefined(events) && !isEmpty(requestArgs?.eventTypeCodeList)) {
      ListEvents(requestArgs);
    }
  }, [events, requestArgs]);

  return (
    <ErrorHandler location="src/routes/private/Dashboards/screens/Risk/components/widgets/Events/Events.jsx">
      <Widget
        description={`${dayjs(requestArgs?.startDate).format('MM/DD/YYYY')} - ${dayjs(requestArgs?.endDate).format(
          'MM/DD/YYYY'
        )}`}
        title="Events"
      >
        <Grid>
          {isUndefined(events) || isLoading || isFetching || isLoadingAssets ? (
            <Grid.Col
              span={{
                xs: '100%',
              }}
            >
              <Loader height="100%" width="100%" />
            </Grid.Col>
          ) : (
            <Grid.Col
              span={{
                xs: '100%',
              }}
            >
              {!isUndefined(events) && !isEmpty(events) ? (
                <Table assets={assets} rows={events?.content} />
              ) : (
                <Alert title="No events found in date range." variant="info" />
              )}
            </Grid.Col>
          )}
        </Grid>
      </Widget>
    </ErrorHandler>
  );
};

Events.propTypes = {
  reportDate: PropTypes.string,
};

Events.defaultProps = {
  reportDate: dayjs().format('YYYY-MM-DD'),
};
