import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '.abyss-data-table-root *': {
    border: 'none',
  },
  '.abyss-table-root': {
    '.abyss-table-styled-header': {
      '.abyss-table-header-sort-arrow-container': {
        right: '0',
      },
    },
    fontFamily: 'menlo',
    'tfoot tr:first-child td': {
      border: 'none',
    },
    thead: {
      outline: 'none',
    },
    tr: {
      backgroundColor: 'transparent',
      borderTop: '1px solid var(--abyss-colors-gray2)',
      'th,td': {
        backgroundColor: 'transparent',
      },
    },
  },
});
