import { useApi } from '@src/context/Api';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

/**
 * useSearch
 *
 * Makes API requests to retrieve the assets required for the wizard experience.
 *
 * @returns {{isLoading: boolean, data: *}}
 */
export const useSearch = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { useApiQueries } = useApi();

  const [theQueries, setTheQueries] = useState([]);

  const queries = useApiQueries(theQueries);

  /**
   * Determines the overall loading state of all asset queries.
   */
  useEffect(() => {
    if (!isEmpty(queries)) {
      if (Object.keys(queries).length === theQueries.length) {
        if (
          isEmpty(
            Object.keys(queries).filter((queryKey) => {
              const query = queries[queryKey];
              return !(!query?.isLoading && !query?.isFetching);
            })
          )
        ) {
          setIsLoading(false);
        }
      }
    }
  }, [queries, theQueries]);

  const fetchData = useCallback((payload = {}) => {
    const requests = [
      {
        args: { criteria: payload?.submittedValues?.criteria },
        key: 'GetRiskRecordsCount',
      },
    ];

    if (!isEmpty(payload?.submittedValues?.views)) {
      payload?.submittedValues?.views?.forEach((view) => {
        const theView = payload?.views.find((item) => {
          return item?.id === view;
        });

        if (view !== 'risk-records') {
          requests.push({
            args: { criteria: payload?.submittedValues?.criteria, view: theView?.id },
            key: `GetAggregationView-${view}`,
          });
        }
      });
    }

    if (requests !== theQueries) {
      setIsLoading(true);
      setTheQueries(requests);
    }
  }, []);

  const results = useMemo(() => {
    const theViews = [];
    if (!isEmpty(queries)) {
      Object.keys(queries).forEach((queryKey) => {
        if (queryKey.includes('GetAggregationView-')) {
          theViews.push(queries?.[queryKey]?.data);
        }
      });
    }
    return theViews;
  }, [queries]);

  const count = useMemo(() => {
    let theCount = {};
    if (!isEmpty(queries)) {
      Object.keys(queries).forEach((queryKey) => {
        if (queryKey === 'GetRiskRecordsCount') {
          theCount = queries?.[queryKey]?.data?.entranceCriteriaCount;
        }
      });
    }
    return theCount;
  }, [queries]);

  return useMemo(() => {
    return { count, data: queries, fetch: fetchData, isLoading, results };
  }, [isLoading, queries, results, count]);
};
