import { useRouter } from '@abyss/web/hooks/useRouter';
import { FloatingSection } from '@abyss/web/ui/FloatingSection';
import { Grid } from '@abyss/web/ui/Grid';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Layout } from '@abyss/web/ui/Layout';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import PropTypes from 'prop-types';
import React from 'react';

import { useWizardContext } from '../../../../context';

/**
 * Footer
 *
 * Provides a floating navigation bar for users to move forwards or backwards between steps at the bottom of the
 * wizard/stepper interface.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Footer = (props) => {
  const { form, handleSubmit, isCurrentStepValid, ref } = props;

  const wizardContext = useWizardContext();

  const { action, currentStep, firstStep, id, lastStep, mode, nextStep, previousStep, status } = wizardContext;

  const router = useRouter();

  return (
    <ErrorHandler location="src/features/ActionPaths/components/misc/Wizard/components/misc/Layout/Footer/Footer.jsx">
      <FloatingSection
        containerRef={ref}
        css={{
          'abyss-floating-section-root': {
            bottom: '0px',
            boxShadow: 'none !important',
            padding: `var(--abyss-space-xs) 0px !important`,
            zIndex: 999,
          },
          boxShadow: 'none !important',
        }}
        position="bottom"
        space="1"
      >
        <Grid
          css={{
            margin: '0px !important',
            padding: `${themeConfiguration?.theme?.space?.xl}px 0px !important`,
          }}
        >
          <Grid.Col
            css={{ paddingLeft: 0, paddingRight: 0 }}
            span={{
              lg: '50%',
              md: '50%',
              xs: '50%',
            }}
          >
            <Layout.Group alignLayout="left">
              {currentStep?.order === firstStep?.order ? (
                <Button
                  onClick={async () => {
                    await handleSubmit();
                    router?.navigate('/action-paths');
                  }}
                  type="button"
                  variant="outline"
                >
                  Cancel
                </Button>
              ) : (
                <Button
                  before={<IconSymbol icon="chevron_left" />}
                  onClick={async () => {
                    await handleSubmit();
                    router?.navigate(`/action-paths/${id}/${action}/${mode}/${status}/step/${previousStep?.order}`);
                  }}
                  type="button"
                  variant="outline"
                >
                  {previousStep?.label || ''}
                </Button>
              )}
            </Layout.Group>
          </Grid.Col>
          <Grid.Col
            css={{ paddingLeft: 0, paddingRight: 0 }}
            span={{
              lg: '50%',
              md: '50%',
              xs: '50%',
            }}
          >
            <Layout.Group alignLayout="right">
              {currentStep?.order === lastStep?.order ? (
                <Button
                  after={<IconSymbol css={{ color: '#fff' }} icon="keyboard_double_arrow_right" />}
                  disabled={!isCurrentStepValid}
                  onClick={async () => {
                    await handleSubmit();
                    router?.navigate(`/action-paths?highlight=${form?.getValues('id')}`);
                  }}
                  type="button"
                  variant="solid"
                >
                  Save Action Path
                </Button>
              ) : (
                <Button
                  after={<IconSymbol css={{ color: '#fff' }} icon="chevron_right" />}
                  disabled={!isCurrentStepValid}
                  onClick={async () => {
                    if (isCurrentStepValid) {
                      await handleSubmit();
                      if (action === 'edit') {
                        router?.navigate(`/action-paths/${id}/${action}/${mode}/${status}/step/${nextStep?.order}`);
                      }
                    }
                  }}
                  type="button"
                  variant="solid"
                >
                  {nextStep?.label || ''}
                </Button>
              )}
            </Layout.Group>
          </Grid.Col>
        </Grid>
      </FloatingSection>
    </ErrorHandler>
  );
};

Footer.propTypes = {
  form: PropTypes.shape({
    formState: PropTypes.shape({
      errors: PropTypes.shape(
        PropTypes.shape({
          name: PropTypes.shape({
            message: PropTypes.string,
          }),
        })
      ),
    }),
    getValues: PropTypes.func,
    handleSubmit: PropTypes.func,
  }),
  handleSubmit: PropTypes.func,
  isCurrentStepValid: PropTypes.bool,
  ref: PropTypes.shape({}),
};

Footer.defaultProps = {
  form: {},
  handleSubmit: () => {},
  isCurrentStepValid: true,
  ref: {},
};
