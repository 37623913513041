import { dayjs } from '@abyss/web/tools/dayjs';
import { logger } from '@src/includes/logger';
import { every, isEmpty, isUndefined } from 'lodash';

/**
 * translateAssignments
 *
 * Translate assignments between the API and the UI.
 *
 * @param assignments
 * @param context
 * @returns {*[]}
 */
export function translateAssignments(assignments = [], context = null) {
  try {
    let theAssignments = [];

    if (!isEmpty(assignments)) {
      const removeAssignments = every(Object.values(assignments?.[0]), isEmpty);

      if (removeAssignments === false) {
        theAssignments = assignments.map((assignment) => {
          const theAssignment = assignment;

          if (context === 'incoming') {
            theAssignment.retryAttempts = String(theAssignment?.retryAttempts);
            theAssignment.retryInterval = String(theAssignment?.retryInterval);
          }

          if (context === 'outgoing') {
            theAssignment.retryAttempts = Number(theAssignment?.retryAttempts);
            theAssignment.retryInterval = Number(theAssignment?.retryInterval);
          }

          return theAssignment;
        });
      }
    }

    return theAssignments;
  } catch (error) {
    logger.error('src/features/ActionPaths/includes/functions.js -> translateAssignments()', false, error);

    throw error;
  }
}

/**
 * translateCriteria
 *
 * Translate criteria between the API and the UI.
 *
 * @param criteria
 * @param context
 * @returns {{}}
 */
export function translateCriteria(criteria = {}, context = null) {
  try {
    const theCriteria = {};

    if (!isUndefined(criteria?.entrance)) {
      theCriteria.entrance = {
        ...criteria.entrance,
        ...{
          additional: translateCriteriaFilters(criteria?.entrance?.additional, context),
          common: translateCriteriaFilters(criteria?.entrance?.common, context),
          merged: translateCriteriaFilters(criteria?.entrance?.merged, context),
        },
      };
    }

    if (!isUndefined(criteria?.exit)) {
      theCriteria.exit = {
        ...criteria.exit,
        ...{
          additional: translateCriteriaFilters(criteria?.exit?.additional, context),
          common: translateCriteriaFilters(criteria?.exit?.common, context),
          merged: translateCriteriaFilters(criteria?.exit?.merged, context),
        },
      };
    }

    return theCriteria;
  } catch (error) {
    logger.error('src/features/ActionPaths/includes/functions.js -> translateCriteria()', false, error);

    throw error;
  }
}

/**
 * translateCriteriaFilters
 *
 * Translate criteria filters between the API and the UI.
 *
 * @param filters
 * @param context
 * @returns {*[]}
 */
export function translateCriteriaFilters(filters = [], context = 'incoming') {
  try {
    let theFilters = [];

    if (!isEmpty(filters)) {
      const removeFilters = every(Object.values(filters?.[0]), isEmpty);

      if (removeFilters === false) {
        theFilters = filters.map((filter) => {
          const theFilter = { ...filter };

          if (context === 'incoming') {
            if (['CREATED_DATE', 'LAST_MODIFIED_DATE'].includes(theFilter?.column)) {
              theFilter.value = dayjs(theFilter?.value).format('MM/DD/YYYY');
            }

            if (theFilter?.column?.toLowerCase().includes('count')) {
              theFilter.value = String(theFilter?.value);
            }
          }

          if (context === 'outgoing') {
            if (['CREATED_DATE', 'LAST_MODIFIED_DATE'].includes(filter?.column)) {
              theFilter.value = dayjs(filter?.value).format('YYYY-MM-DD');
            }

            if (filter?.column?.toLowerCase().includes('count')) {
              theFilter.value = Number(filter?.value);
            }
          }

          return theFilter;
        });
      }
    }

    return theFilters;
  } catch (error) {
    logger.error('src/features/ActionPaths/includes/functions.js -> translateCriteriaFilters()', false, error);

    throw error;
  }
}
