import { dayjs } from '@abyss/web/tools/dayjs';
import { Carousel, Slide } from '@abyss/web/ui/Carousel';
import { Flex } from '@abyss/web/ui/Flex';
import { Grid } from '@abyss/web/ui/Grid';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import { Timeline } from '@abyss/web/ui/Timeline';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Loader } from '@src/components/Loader';
import { useApi } from '@src/context/Api';
import { chunk, isEmpty, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';

import { Styles } from './includes/styles';

const EventTypes = {
  ACTION_PATH_ADD: {
    icon: 'create_new_folder',
    label: 'Action Path',
  },
  FLIP: {
    icon: 'change_circle',
    label: 'Flip',
  },
  REMEDIATION: {
    icon: 'flag',
    label: 'Remediation',
  },
  RISK_CHANGE: {
    icon: 'warning',
    label: 'Risk Change',
  },
  SPLIT_MERGE: {
    icon: 'alt_route',
    label: 'Split Merge',
  },
};

/**
 * EventTimeline
 *
 * Displays a timeline of events.
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const EventTimeline = (props) => {
  const { riskRecord } = props;

  const eid = riskRecord?.eid;

  const { useApiQuery } = useApi();

  const [GetRiskRecordTimeline, { data, isFetching, isLoading }] = useApiQuery('GetRiskRecordTimeline');

  /**
   * Retrieve the timeline data from the API.
   */
  useEffect(() => {
    if (isUndefined(data) && !isLoading && !isFetching) {
      GetRiskRecordTimeline({ eid });
    }
  }, [data, isLoading, isFetching]);

  const renderSlide = (theTimelineItems = []) => {
    return (
      <Slide>
        <Slide.Container>
          <Flex alignItems="center" className="eventTimelineFlex">
            <Timeline
              centeredItems
              className={theTimelineItems
                .map((item, index) => {
                  return isEmpty(item?.eventDate) ? `hide-${index}` : '';
                })
                .join(' ')}
              horizontal
            >
              {theTimelineItems?.map((timelineItem, timelineItemIndex) => {
                let bullet = null;

                if (!isEmpty(timelineItem?.eventDate)) {
                  bullet = (
                    <Layout.Group>
                      {timelineItem?.events?.map((event) => {
                        return (
                          <IconSymbol
                            icon={EventTypes[event]?.icon}
                            key={`${timelineItem?.eventDate}-${event}-${EventTypes[event]?.icon}`}
                            size="24px"
                            variant="outlined"
                          />
                        );
                      })}
                    </Layout.Group>
                  );
                }

                return (
                  <Timeline.Item
                    bullet={bullet}
                    key={`${timelineItemIndex}-${timelineItem?.eventDate}`}
                    title={(timelineItem?.eventDate && dayjs(timelineItem?.eventDate).format('MMM DD, YYYY')) || ''}
                  >
                    {timelineItem?.events?.map((event, eventIndex) => {
                      return (
                        <Layout.Stack alignLayout="center" key={`${eventIndex}-${timelineItem?.eventDate}-${event}`}>
                          <Text>{EventTypes[event]?.label}</Text>
                        </Layout.Stack>
                      );
                    })}
                  </Timeline.Item>
                );
              })}
            </Timeline>
          </Flex>
        </Slide.Container>
      </Slide>
    );
  };

  /**
   * The timeline data.
   *
   * Map the timeline data from the API response into a more usable format.
   *
   * @type {[]|{events: *, eventDate: *}[]}
   */
  const slides = useMemo(() => {
    let theSlides = [];

    if (isUndefined(data?.timelines)) {
      return theSlides;
    }

    const timelines = Object.keys(data?.timelines).map((eventDate) => {
      const events = data?.timelines[eventDate];
      return {
        eventDate,
        events,
      };
    });

    const theTimeline = chunk(timelines, 5);

    theSlides = theTimeline.map((timelineItems) => {
      const theTimelineItems = [...timelineItems];

      if (theTimelineItems.length < 5) {
        for (let i = theTimelineItems.length; i < 5; i++) {
          theTimelineItems.push({
            eventDate: '',
            events: [''],
          });
        }
      }

      return renderSlide(theTimelineItems);
    });

    return theSlides;
  }, [data]);

  return (
    <ErrorHandler location="src/routes/private/Analysis/screens/EidSearch/components/Layout/Main/components/Results/widgets/EventTimeline/EventTimeline.jsx">
      <Styles>
        <Grid>
          {isUndefined(data) || isLoading || isFetching ? (
            <Grid.Col
              span={{
                xs: '100%',
              }}
            >
              <Loader height="100%" width="100%" />
            </Grid.Col>
          ) : (
            <Grid.Col
              className="eventTimeline"
              span={{
                xs: '100%',
              }}
            >
              <Carousel minimal noLoop slides={slides} slidesPerView={1} />
            </Grid.Col>
          )}
        </Grid>
      </Styles>
    </ErrorHandler>
  );
};

EventTimeline.propTypes = {
  riskRecord: PropTypes.shape({
    eid: PropTypes.string,
  }),
};

EventTimeline.defaultProps = {
  riskRecord: {
    eid: '',
  },
};
