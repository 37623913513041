import { debounce } from 'lodash';
import { useEffect, useMemo, useRef } from 'react';

/**
 * Hook: useDebounce
 *
 * Reduce the number of calls to a function within specified waiting duration.
 *
 * @param callback
 * @param wait
 * @returns {function(): (*)}
 */
export const useDebounce = (callback = () => {}, wait = 200) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  return useMemo(() => {
    const debouncedCallback = async () => {
      await ref.current?.();
    };

    return debounce(debouncedCallback, wait);
  }, []);
};
