import { TextInput } from '@abyss/web/ui/TextInput';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { useApi } from '@src/context/Api';
import { useDebounce } from '@src/hooks';
import { fieldValidator } from '@src/includes/validation';
import { isUndefined } from 'lodash';
import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect } from 'react';

import { useSave } from '../../../hooks/useSave';
import fields from '../includes/fields.json';
import { Styles } from './includes/styles';

const { name: fieldName } = fields;

/**
 * Field: Name
 *
 * This field is used to display the name of the entity.
 *
 * @returns {Element}
 * @constructor
 */
export const Name = (props) => {
  const { currentEntity, form, refetch, showButton, showLabel } = props;

  const name = form.getValues('name');

  const handleSave = useSave('saveName');

  const { useApiQuery } = useApi();

  const [GetValidCriteriaName, { data, isFetching, isLoading }] = useApiQuery('GetValidCriteriaName', {
    staleTime: 0,
  });

  /**
   * sendRequest
   *
   * Makes a request to a remote API to validateCriteria whether the user provided action path name exists or not.
   *
   * @type {(function(*): Promise<void>)|*}
   */
  const sendRequest = useCallback(async () => {
    if ((await form?.trigger('name')) === true && name !== currentEntity?.name) {
      GetValidCriteriaName({ name });
    }
  }, [name]);

  /**
   * With the API response, if the name is valid, clear the error, otherwise set the name in use error.
   */
  useEffect(() => {
    if (!isUndefined(data)) {
      const isValid = data;

      if (isValid === true) {
        form?.clearErrors('name');
      }

      if (isValid === false) {
        form?.setError('name', { message: 'Name already in use.', type: 'custom' }, { shouldFocus: true });
      }
    }
  }, [data, isLoading, isFetching]);

  /**
   * When the name changes, validate the field.
   */
  useEffect(() => {
    form.validate(
      'name',
      () => {},
      () => {}
    );
  }, [name, currentEntity?.name]);

  const debouncedRequest = useDebounce(sendRequest, 250);

  return (
    <ErrorHandler location="src/routes/private/Admin/screens/CommonCriteria/components/fields/Name/Name.jsx">
      <Styles>
        <TextInput
          {...fieldName}
          hideLabel={!showLabel}
          inputRightElement={
            showButton ? (
              <Button
                onClick={async () => {
                  await handleSave({ ...currentEntity, ...{ name } }, refetch);
                }}
                variant="solid"
              >
                Save
              </Button>
            ) : (
              <React.Fragment />
            )
          }
          onChange={async (event) => {
            if (event?.target?.value?.length >= 3) {
              if ((await form?.trigger('name')) === true) {
                form?.register('name');
                form?.setError(
                  'name',
                  { message: 'Verifying name is available...', type: 'custom' },
                  { shouldFocus: true }
                );
              }
              debouncedRequest();
            }
          }}
          validators={{
            ...fieldName.validators,
            ...{
              validate: {
                customValidator: (value) => {
                  return fieldValidator(fieldName, value);
                },
              },
            },
          }}
          width={`${Number(themeConfiguration?.theme?.space?.xl?.replace('px', '')) * 8}px`}
        />
      </Styles>
    </ErrorHandler>
  );
};

Name.propTypes = {
  currentEntity: PropTypes.shape({
    name: PropTypes.string,
  }),
  form: PropTypes.shape({
    clearErrors: PropTypes.func,
    getValues: PropTypes.func,
    register: PropTypes.func,
    setError: PropTypes.func,
    trigger: PropTypes.func,
    validate: PropTypes.func,
  }),
  refetch: PropTypes.func,
  showButton: PropTypes.bool,
  showLabel: PropTypes.bool,
};

Name.defaultProps = {
  currentEntity: {},
  form: {},
  refetch: () => {},
  showButton: true,
  showLabel: false,
};
