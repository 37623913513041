import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '.abyss-text-input-root': {
    '.abyss-text-input-right-add-on': {
      background: 'none',
      border: 'none',
      fontSize: 'var(--abyss-fontSizes-sm)',
      lineHeight: '100%',
      padding: 0,
    },
    '.abyss-text-input-right-element': {
      '&>div': {
        height: '100%',
        position: 'relative',
      },
      button: {
        borderBottomWidth: '1px !important',
        borderTopWidth: '1px !important',
        height: '100%',
      },
    },
    "*[class*='isInvalid-true']": {
      bottom: 'auto',
    },
    marginRight: 'var(--abyss-space-md)',
  },
});
