import { config } from '@abyss/web/tools/config';
import { Axios } from '@src/context/Api/includes/Axios';
import { logger } from '@src/includes/logger';

/**
 * ReEvaluateActionPath
 *
 * Re-trigger the activation of the action path and will go and find records that meet the entrance criteria that are
 * not currently on the Action Path.
 *
 * @param payload
 * @returns {Promise<any>}
 * @constructor
 */
export const ReEvaluateActionPath = async (payload = {}) => {
  try {
    const theMutationKey = payload?.[0];
    const thePayload = payload?.[1];

    const requestArgs = {
      baseURL: config('API_URL'),
      data: {},
      headers: {
        'x-api-endpoint': `/action-paths/re-evaluate/${thePayload?.actionPathId}`,
      },
      method: 'PUT',
      params: { mutationKey: theMutationKey },
    };

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse.data;
  } catch (error) {
    logger.error('src/requests/mutations/ReEvaluateActionPath.js -> ReEvaluateActionPath()', false, error);

    throw error;
  }
};
