import { Badge } from '@abyss/web/ui/Badge';
import { Flex } from '@abyss/web/ui/Flex';
import { Grid } from '@abyss/web/ui/Grid';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { CreateWithCriteriaButton } from '@src/features/ActionPaths/components/buttons/CreateWithCriteria';
import { AggregationView } from '@src/features/Risk/components/widgets/AggregationView';
import { motion } from 'framer-motion';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { Styles } from './includes/styles';

/**
 * SearchResults
 *
 * Displays the search results for the Risk Analysis screen.
 *
 * @param props
 * @returns {React.JSX.Element|null}
 * @constructor
 */
export const SearchResults = (props) => {
  const { assets, count, results, searchFilters } = props;

  if (isEmpty(results)) {
    return null;
  }

  return (
    <ErrorHandler location="src/routes/private/Analysis/screens/RiskAnalysis/components/SearchResults/SearchResults.jsx">
      <motion.div
        animate="open"
        initial={{ opacity: 0 }}
        variants={{
          closed: { opacity: 0 },
          open: { opacity: 1 },
        }}
      >
        <Styles>
          <Grid css={{ margin: 0, padding: 0, width: '100%' }}>
            <Grid.Col
              css={{ paddingBottom: 'var(--abyss-space-lg)', paddingTop: 'var(--abyss-space-lg)' }}
              span={{ xs: '100%' }}
            >
              <Layout.Group space={themeConfiguration?.theme?.space?.lg}>
                <Layout.Group>
                  <Text fontWeight="bold">Total Records:</Text>
                  <Badge outline variant="info">
                    {Number(count || 0).toLocaleString('en-US')}
                  </Badge>
                </Layout.Group>
                <div>
                  <CreateWithCriteriaButton criteria={searchFilters}>Draft Action Path</CreateWithCriteriaButton>
                </div>
              </Layout.Group>
            </Grid.Col>
            <Grid.Col css={{ margin: 0, padding: 0 }} span={{ lg: '100%', md: '100%', sm: '100%', xs: '100%' }}>
              <Flex
                alignContent="flex-start"
                alignItems="stretch"
                css={{ width: '100%' }}
                direction="row"
                justify="flex-start"
              >
                <React.Fragment>
                  {results.map((aggregation) => {
                    const view = assets?.ListViews?.data?.find((theView) => {
                      return theView?.name === aggregation?.researchAggregation?.name;
                    });

                    return (
                      <AggregationView
                        aggregation={aggregation}
                        assets={assets}
                        filters={searchFilters?.filters}
                        key={aggregation?.researchAggregation?.id}
                        view={view}
                      />
                    );
                  })}
                </React.Fragment>
                {searchFilters?.views?.map((viewId) => {
                  if (viewId === 'risk-records') {
                    return (
                      <AggregationView
                        assets={assets}
                        criteria={searchFilters?.criteria}
                        filters={searchFilters?.filters}
                        key="ListRiskRecords"
                        view={{
                          description: 'Sample risk records that match filter criteria.',
                          id: 'risk-records',
                          name: 'Risk Records Sample Data',
                        }}
                      />
                    );
                  }
                  return null;
                })}
              </Flex>
            </Grid.Col>
          </Grid>
        </Styles>
      </motion.div>
    </ErrorHandler>
  );
};

SearchResults.propTypes = {
  assets: PropTypes.shape({
    ListRiskCodes: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        })
      ),
    }),
    ListViews: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        })
      ),
    }),
  }),
  count: PropTypes.number,
  results: PropTypes.arrayOf(
    PropTypes.shape({
      researchAggregation: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    })
  ),
  searchFilters: PropTypes.shape({
    criteria: PropTypes.shape({
      entrance: PropTypes.shape({
        additional: PropTypes.arrayOf(
          PropTypes.shape({
            column: PropTypes.string,
            condition: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.arrayOf(PropTypes.string)]),
          })
        ),
        commonCriteriaVersionsIds: PropTypes.arrayOf(PropTypes.string),
        commonIds: PropTypes.arrayOf(PropTypes.string),
      }),
      exit: PropTypes.shape({
        additional: PropTypes.arrayOf(
          PropTypes.shape({
            column: PropTypes.string,
            condition: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.arrayOf(PropTypes.string)]),
          })
        ),
        commonCriteriaVersionsIds: PropTypes.arrayOf(PropTypes.string),
        commonIds: PropTypes.arrayOf(PropTypes.string),
      }),
    }),
    filters: PropTypes.shape({
      column: PropTypes.string,
      condition: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.arrayOf(PropTypes.string)]),
    }),
    views: PropTypes.arrayOf(PropTypes.string),
  }),
};

SearchResults.defaultProps = {
  assets: {},
  count: 0,
  results: [],
  searchFilters: {},
};
