import { useForm } from '@abyss/web/hooks/useForm';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Grid } from '@abyss/web/ui/Grid';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { useRoutesContext } from '@src/context/Routes';
import { useSelectedCommonCriteria } from '@src/features/ActionPaths/components/misc/Wizard/hooks/useSelectedCommonCriteria';
import { AdditionalCriteriaWidget } from '@src/features/Criteria/components/widgets/AdditionalCriteria';
import { CommonCriteriaWidget } from '@src/features/Criteria/components/widgets/CommonCriteria';
import { motion } from 'framer-motion';
import { every, isEmpty, isEqual, isUndefined, merge } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { ViewsWidget } from '../../widgets/Views';
import { Styles } from './includes/styles';

/**
 * form: search
 *
 * The search form for the Risk Analysis screen.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Search = (props) => {
  const { assets, handleSearch, isLoadingSearchResults, searchFilters, setSearchFilters, setShowSearchResults } = props;

  const { handleSelected: setSelectedCommonEntrance, selected: selectedCommonEntrance } =
    useSelectedCommonCriteria('entrance');

  const { currentRoute } = useRoutesContext();

  const router = useRouter();

  const defaultValues = {
    criteria: {
      entrance: {
        additional: [
          {
            column: '',
            condition: '',
            value: '',
          },
        ],
        commonCriteriaVersionsIds: [],
        commonIds: [],
      },
      exit: {
        additional: [],
        commonCriteriaVersionsIds: [],
        commonIds: [],
      },
    },
    views: ['filter-by-tags', 'risk-records'],
  };

  const form = useForm({ defaultValues });
  const { isSubmitting, isValid } = form.formState;
  const formValues = form.watch();

  /**
   * Validates the views field
   */
  useEffect(() => {
    if (isUndefined(currentRoute?.params?.key)) {
      form?.setValue('criteria', defaultValues?.criteria);
      form?.setValue('views', defaultValues?.views);
      form.validate(
        `views`,
        () => {},
        () => {}
      );
      setSearchFilters({});
      setShowSearchResults(false);
    } else {
      form?.clearErrors('views');
    }
  }, [currentRoute?.params?.key]);

  /**
   * handleSubmit
   *
   * When the form is submitted, generate the queries based on the search input filters.
   *
   * @param submittedValues
   * @returns {Promise<void>}
   */
  const handleSubmit = async (submittedValues = {}) => {
    if (submittedValues !== searchFilters) {
      setSearchFilters(submittedValues);
    }

    await handleSearch({ submittedValues, views: assets?.ListViews?.data });

    const encoded = Buffer.from(JSON.stringify(submittedValues)).toString('base64');

    if (currentRoute?.params?.key !== encoded) {
      router?.navigate(`/analysis/risk-analysis/${encoded}`);
    }
  };

  /**
   * If filters are passed in the URL, apply them to the form.
   */
  useEffect(() => {
    if (!isUndefined(currentRoute?.params?.key)) {
      const decoded = Buffer.from(currentRoute?.params?.key, 'base64').toString('utf-8');
      const searchCriteria = JSON.parse(decoded);

      const criteria = merge({}, defaultValues?.criteria, searchCriteria?.criteria);

      const data = {
        criteria,
        views: searchCriteria?.views || defaultValues?.views,
      };

      const theFormValues = form.getValues();

      if (isEqual(theFormValues, defaultValues)) {
        setSearchFilters(data);
        form?.reset(data, {
          keepDirty: false,
          keepDirtyValues: false,
          keepErrors: false,
          keepIsValid: false,
          keepSubmitCount: true,
          keepTouched: false,
          keepValues: false,
        });
      }

      setShowSearchResults(true);
      handleSubmit(data);
    }
  }, [currentRoute?.params?.key]);

  useEffect(() => {
    form?.setValue('criteria.entrance.commonIds', selectedCommonEntrance?.commonIds, { shouldDirty: true });
    form?.setValue('criteria.entrance.commonCriteriaVersionsIds', selectedCommonEntrance?.commonCriteriaVersionsIds, {
      shouldDirty: true,
    });
  }, [selectedCommonEntrance]);

  const criteriaIds = selectedCommonEntrance?.commonIds?.filter((item) => {
    return !isEmpty(item);
  });

  const criteriaVersionIds = selectedCommonEntrance?.commonCriteriaVersionsIds?.filter((item) => {
    return !isEmpty(item);
  });

  const additionalCriteria = formValues?.criteria?.entrance?.additional?.filter((item) => {
    return !every(Object.values(item), isEmpty);
  });

  const hasValues = (!isEmpty(criteriaIds) && !isEmpty(criteriaVersionIds)) || !isEmpty(additionalCriteria);
  const hasErrors = !isEmpty(form?.formState?.errors);
  const equalValues = isEqual(searchFilters, formValues);

  return (
    <ErrorHandler location="src/routes/private/Analysis/screens/RiskAnalysis/components/forms/Search/Search.jsx">
      <motion.div
        animate="open"
        initial={{ opacity: 0 }}
        variants={{
          closed: { opacity: 0 },
          open: { opacity: 1 },
        }}
      >
        <Styles>
          <FormProvider autoComplete="off" highlighted onSubmit={handleSubmit} state={form}>
            <Grid>
              <Grid.Col span={{ xs: '100%' }}>
                <CommonCriteriaWidget
                  context="entrance"
                  filterKey="commonCriteria"
                  form={form}
                  isCollapsible
                  setSelected={setSelectedCommonEntrance}
                />
              </Grid.Col>
              <Grid.Col span={{ xs: '100%' }}>
                <AdditionalCriteriaWidget
                  {...{
                    actionPaths: assets?.ListActionPaths?.data,
                    actionStatuses: assets?.ListActionStatuses?.data,
                    filterKey: 'criteria.entrance.additional',
                    form,
                    isLoading: isLoadingSearchResults,
                    tags: assets?.ListTags?.data,
                  }}
                  isCollapsible
                />
              </Grid.Col>
              <Grid.Col span={{ xs: '100%' }}>
                <ViewsWidget assets={assets} form={form}>
                  <Button
                    isDisabled={
                      hasErrors || !isValid || isSubmitting || isLoadingSearchResults || equalValues || !hasValues
                    }
                    type="submit"
                    variant="solid"
                  >
                    Apply
                  </Button>
                </ViewsWidget>
              </Grid.Col>
            </Grid>
          </FormProvider>
        </Styles>
      </motion.div>
    </ErrorHandler>
  );
};

Search.propTypes = {
  assets: PropTypes.shape({
    ListActionPaths: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    ListActionStatuses: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    ListTags: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    ListViews: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
  handleSearch: PropTypes.func,
  isLoadingSearchResults: PropTypes.bool,
  searchFilters: PropTypes.shape({
    criteria: PropTypes.shape({
      entrance: PropTypes.shape({
        additional: PropTypes.arrayOf(
          PropTypes.shape({
            column: PropTypes.string,
            condition: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.arrayOf(PropTypes.string)]),
          })
        ),
        commonCriteriaVersionsIds: PropTypes.arrayOf(PropTypes.string),
        commonIds: PropTypes.arrayOf(PropTypes.string),
      }),
      exit: PropTypes.shape({
        additional: PropTypes.arrayOf(
          PropTypes.shape({
            column: PropTypes.string,
            condition: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.arrayOf(PropTypes.string)]),
          })
        ),
        commonCriteriaVersionsIds: PropTypes.arrayOf(PropTypes.string),
        commonIds: PropTypes.arrayOf(PropTypes.string),
      }),
    }),
    views: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string,
        id: PropTypes.string,
        name: PropTypes.string,
      })
    ),
  }),
  setSearchFilters: PropTypes.func,
  setShowSearchResults: PropTypes.func,
};

Search.defaultProps = {
  assets: {},
  handleSearch: () => {},
  isLoadingSearchResults: false,
  searchFilters: {},
  setSearchFilters: () => {},
  setShowSearchResults: () => {},
};
