import { DeleteActionPath } from './DeleteActionPath';
import { DuplicateActionPath } from './DuplicateActionPath';
import { ReEvaluateActionPath } from './ReEvaluateActionPath';
import { RefreshEIMP } from './RefreshEIMP';
import { RemoteLogger } from './RemoteLogger';
import { RiskRecordActionPathAssociation } from './RiskRecordActionPathAssociation';
import { RiskRecordActionPathDisassociation } from './RiskRecordActionPathDisassociation';
import { RiskRecordTagAssignment } from './RiskRecordTagAssignment';
import { RiskRecordTagUnassignment } from './RiskRecordTagUnassignment';
import { SaveActionPath } from './SaveActionPath';
import { SaveActionPathNote } from './SaveActionPathNote';
import { SaveAlert } from './SaveAlert';
import { SaveAlertConfiguration } from './SaveAlertConfiguration';
import { SaveCode } from './SaveCode';
import { SaveCodeCategory } from './SaveCodeCategory';
import { SaveCommonCriteria } from './SaveCommonCriteria';
import { SaveEvent } from './SaveEvent';
import { SaveTag } from './SaveTag';
import { ScheduleExport } from './ScheduleExport';

/**
 * mutations
 *
 * @type {{RiskRecordActionPathDisassociation: ((function({}=): Promise<*>)|*), SaveActionPath: ((function({}=):
 *   Promise<{}>)|*), SaveActionPathNote: ((function({}=): Promise<*>)|*), SaveCode: ((function({}=): Promise<{}>)|*),
 *   SaveEvent: ((function({}=): Promise<{}>)|*), SaveTag: ((function({}=): Promise<{}>)|*), SaveCodeCategory:
 *   ((function({}=): Promise<{}>)|*), SaveCommonCriteria: ((function({}=): Promise<{}>)|*),
 *   RiskRecordActionPathAssociation: ((function({}=): Promise<*>)|*), RiskRecordTagUnassignment: ((function({}=):
 *   Promise<*>)|*), RefreshEIMP: ((function({}=): Promise<*>)|*), ReEvaluateActionPath: ((function({}=):
 *   Promise<*>)|*), RiskRecordTagAssignment: ((function({}=): Promise<*>)|*), ScheduleExport: ((function({}=):
 *   Promise<*|string>)|*), DuplicateActionPath: ((function({}=): Promise<*>)|*), DeleteActionPath: ((function({}=):
 *   Promise<*>)|*), RemoteLogger: ((function(*): Promise<{}>)|*), SaveAlert: ((function({}=): Promise<{}>)|*),
 *   SaveAlertConfiguration: ((function({}=): Promise<{}>)|*)}}
 */
export const mutations = {
  DeleteActionPath,
  DuplicateActionPath,
  ReEvaluateActionPath,
  RefreshEIMP,
  RemoteLogger,
  RiskRecordActionPathAssociation,
  RiskRecordActionPathDisassociation,
  RiskRecordTagAssignment,
  RiskRecordTagUnassignment,
  SaveActionPath,
  SaveActionPathNote,
  SaveAlert,
  SaveAlertConfiguration,
  SaveCode,
  SaveCodeCategory,
  SaveCommonCriteria,
  SaveEvent,
  SaveTag,
  ScheduleExport,
};
