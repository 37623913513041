import { DropdownMenu } from '@abyss/web/ui/DropdownMenu';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Layout } from '@abyss/web/ui/Layout';
import { ToggleSwitch } from '@abyss/web/ui/ToggleSwitch';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Table as TableComponent } from '@src/components/Table-query';
import { orderBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import configuration from './includes/configuration.json';
import { Styles } from './includes/styles';

/**
 * Table
 *
 * Displays a list of code categories within a DataTable.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Table = (props) => {
  const {
    error,
    handleSave,
    headerLeft,
    isLoading,
    requestArgs,
    requestFunction,
    requestKey,
    rows,
    setCurrentEntity,
    setIsFormModalOpen,
    totalPages,
    totalRecords,
  } = props;

  /**
   * renderCellActions
   *
   * Provides the user with a menu of actionable items to perform on a specific action path within the list of action
   * paths.
   *
   * @param row
   * @returns {Element}
   */
  const renderCellActions = ({ row }) => {
    const menuItems = [
      {
        icon: <IconSymbol icon="edit" />,
        onClick: () => {
          setIsFormModalOpen(true);
          setCurrentEntity(row?.original);
        },
        title: 'Edit',
      },
    ];

    return (
      <div
        style={{
          marginLeft: '-10px',
          marginRight: '-10px',
        }}
      >
        <Layout.Group
          css={{
            height: '100%',
            marginLeft: '2px',
            marginRight: '2px',
          }}
        >
          <DropdownMenu
            before={<IconSymbol color="#6F6F6F" icon="more_vert" size={24} />}
            hideLabel
            label=""
            menuItems={menuItems}
          />
        </Layout.Group>
      </div>
    );
  };

  /**
   * renderCellActive
   *
   * displays a toggle switch to enable or disable the code category.
   *
   * @param row
   * @returns {Element}
   */
  const renderCellActive = ({ row }) => {
    return (
      <ToggleSwitch
        isChecked={row?.original?.enabled}
        isDisabled={!row?.original?.userDefined}
        onChange={async () => {
          await handleSave({
            data: {
              categoryCode: row?.original?.categoryCode,
              code: row?.original?.code,
              enabled: row?.original?.enabled === false,
            },
            mode: 'edit',
          });
        }}
      />
    );
  };

  /**
   * Columns for table.
   */
  const columns = useMemo(() => {
    return orderBy(configuration?.initialColumns, ['order'], ['asc']).map((item) => {
      const column = item;

      if (column.accessor === 'actions') {
        column.Cell = renderCellActions;
      }

      if (column.accessor === 'enabled') {
        column.Cell = renderCellActive;
      }

      return column;
    });
  }, []);
  return (
    <ErrorHandler location="src/routes/private/Admin/screens/Tags/List/components/Table/Table.jsx">
      <Styles>
        <TableComponent
          {...{
            columns,
            configuration,
            error,
            headerLeft,
            isLoading,
            noDataMessage: 'No tags found.',
            requestArgs,
            requestFunction,
            requestKey,
            rows,
            totalPages,
            totalRecords,
          }}
        />
      </Styles>
    </ErrorHandler>
  );
};

Table.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
    variant: PropTypes.string,
  }),
  handleSave: PropTypes.func,
  headerLeft: PropTypes.element,
  isLoading: PropTypes.bool,
  requestArgs: PropTypes.shape({
    categoryList: PropTypes.arrayOf(PropTypes.string),
    page: PropTypes.number,
    size: PropTypes.number,
    sort: PropTypes.string,
    userDefined: PropTypes.bool,
  }),
  requestFunction: PropTypes.func,
  requestKey: PropTypes.string,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      categoryCode: PropTypes.string,
      categoryDesc: PropTypes.string,
      code: PropTypes.string,
      description: PropTypes.string,
      enabled: PropTypes.bool,
      userDefined: PropTypes.bool,
    })
  ),
  setCurrentEntity: PropTypes.func,
  setIsFormModalOpen: PropTypes.func,
  totalPages: PropTypes.number,
  totalRecords: PropTypes.number,
};

Table.defaultProps = {
  error: null,
  handleSave: () => {},
  headerLeft: null,
  isLoading: false,
  requestArgs: {},
  requestFunction: () => {},
  requestKey: '',
  rows: [],
  setCurrentEntity: () => {},
  setIsFormModalOpen: () => {},
  totalPages: 1,
  totalRecords: 0,
};
