import { config } from '@abyss/web/tools/config';
import { Axios } from '@src/context/Api/includes/Axios';
import { logger } from '@src/includes/logger';
import { isUndefined } from 'lodash';

/**
 * GetActionPathCount
 *
 * Retrieves count of risk records affiliated with an action path.
 *
 * @param payload
 * @returns {Promise<any>}
 * @constructor
 */
export const GetActionPathCount = async (payload = {}) => {
  try {
    const requestArgs = {
      baseURL: config('API_URL'),
      headers: {
        'x-api-endpoint': `/action-paths`,
      },
      method: 'GET',
    };

    if (!isUndefined(payload)) {
      requestArgs.params = { queryKey: payload[0] };
      requestArgs.headers['x-api-endpoint'] += `/${payload[1]?.actionPathId}/count`;
    }

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse.data;
  } catch (error) {
    logger.error('src/requests/queries/GetActionPathCount.js -> GetActionPathCount()', false, error);

    throw error;
  }
};
