import { useForm } from '@abyss/web/hooks/useForm';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Grid } from '@abyss/web/ui/Grid';
import { TextInput } from '@abyss/web/ui/TextInput';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { useRoutesContext } from '@src/context/Routes';
import { isEmpty, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';

import { FormSkeleton } from './components/Skeleton';
import fields from './includes/fields.json';

/**
 * Form
 *
 * Provides a form for searching by EID.
 *
 * @returns {Element}
 * @constructor
 */
export const Form = (props) => {
  const { isLoading } = props;

  const { currentRoute } = useRoutesContext();
  const router = useRouter();

  const eid =
    !isUndefined(currentRoute?.params?.eid) && !isEmpty(currentRoute?.params?.eid) ? currentRoute?.params?.eid : '';

  const defaultValues = {
    eid,
  };

  const form = useForm({ defaultValues });
  const { isSubmitting, isValid } = form.formState;

  /**
   * If an EID is passed in the URL, set the EID value in the form state.
   */
  useEffect(() => {
    if (!isEmpty(eid)) {
      form.setValue('eid', eid);
    } else {
      form.setValue('eid', '');
      form?.setFocus('eid');
    }
  }, [eid]);

  const handleSubmit = useCallback(
    (submittedValues) => {
      if (!isSubmitting && isValid) {
        router?.navigate(`/analysis/eid-search/${submittedValues?.eid}`);
      }
    },
    [isSubmitting, isValid]
  );

  return (
    <ErrorHandler location="src/routes/private/Analysis/screens/EidSearch/components/Layout/Header/components/Form/Form.jsx">
      <FormProvider autoComplete="off" highlighted onSubmit={handleSubmit} state={form}>
        <Grid css={{ margin: 0 }} id="searchContainer">
          <Grid.Col
            css={{ paddingLeft: 0 }}
            span={{
              xs: '100%',
            }}
          >
            {isLoading ? (
              <FormSkeleton />
            ) : (
              <TextInput
                {...fields?.eid}
                highlighted
                onChange={() => {
                  form.validate(
                    `eid`,
                    () => {},
                    () => {}
                  );
                }}
                onClickSearch={() => {
                  handleSubmit(form?.getValues());
                }}
              />
            )}
          </Grid.Col>
        </Grid>
      </FormProvider>
    </ErrorHandler>
  );
};

Form.propTypes = {
  isLoading: PropTypes.bool,
};

Form.defaultProps = {
  isLoading: false,
};
