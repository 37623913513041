import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { Alert } from '@abyss/web/ui/Alert';
import { DataTable } from '@abyss/web/ui/DataTable';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { logger } from '@src/includes/logger';
import { motion } from 'framer-motion';
import { isArray, isEmpty, isFunction, isNull, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useLayoutEffect, useMemo } from 'react';
import { createRoot } from 'react-dom/client';

import { GoToPage } from './components/GoToPage';
import tableConfiguration from './includes/configuration.json';
import { Styles } from './includes/styles';

/**
 * Table
 *
 * Reusable table component to be used/reused throughout various areas in the UI.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Table = (props) => {
  const {
    columns,
    configuration,
    dataKey,
    fetch,
    isError,
    reload,
    requestParameters,
    reset,
    rows,
    setRequestParameters,
    stickyHeaders,
  } = props;

  /**
   * getFetchParameters
   *
   * parse the page, pageSize, and sortBy parameters to be used in the fetch call.
   *
   * @param page
   * @param pageSize
   * @param sortBy
   * @returns {{}}
   */
  const getFetchParameters = (page, pageSize, sortBy) => {
    let theParameters = {};

    if (!isEmpty(requestParameters)) {
      const parameters = {
        ...requestParameters,
        ...{
          page,
          size: pageSize,
        },
      };

      if (!isUndefined(sortBy?.[0]?.id) && !isUndefined(sortBy?.[0]?.desc)) {
        parameters.sort = `${sortBy?.[0]?.id},${sortBy?.[0]?.desc === true ? 'desc' : 'asc'}`;
      }

      if (!isNull(setRequestParameters) && requestParameters !== parameters) {
        setRequestParameters(parameters);
        theParameters = parameters;
      }
    }

    return theParameters;
  };

  /**
   * fetchData
   *
   * Fetch/refetch data from the API to load within the table.
   *
   * @param page
   * @param pageSize
   * @param sortBy
   * @returns {Promise<{count: number, results: []}>}
   */
  const fetchData = async (page, pageSize, sortBy) => {
    let response = {
      count: 0,
      results: [],
    };

    if (pageSize === 10) {
      return response;
    }

    try {
      let remoteResponse;

      const parameters = getFetchParameters(page, pageSize, sortBy);

      if (!isEmpty(parameters)) {
        remoteResponse = await fetch(parameters);
      } else {
        remoteResponse = await fetch();
      }

      if (!isUndefined(remoteResponse?.content)) {
        response.results = remoteResponse?.content;
      } else if (isArray(remoteResponse)) {
        response.results = remoteResponse;
      }

      if (!isUndefined(remoteResponse?.totalElements)) {
        response.count = remoteResponse?.totalElements;
      } else if (isArray(remoteResponse)) {
        response.count = remoteResponse.length;
      }
    } catch (error) {
      logger.error('src/components/Table/Table.jsx -> fetchData()', false, error);

      response = error?.message;
    }

    return response;
  };

  const abyssConfiguration = useMemo(() => {
    const theConfiguration = {
      ...tableConfiguration,
      ...configuration,
      ...{
        errorMessage: !isNull(isError) && <Alert title="Error">{isError?.message}</Alert>,
        initialColumns: columns || [],
        initialData: rows || [],
        uniqueStorageId: `table-${dataKey}`,
      },
    };

    if (theConfiguration?.usePagination === true) {
      theConfiguration.apiPaginationCall = fetchData;
    }

    return theConfiguration;
  }, [tableConfiguration, configuration, columns]);

  /**
   * Table configuration.
   */
  const state = useDataTable(abyssConfiguration);

  /**
   * Allows calling components to tell the table to reload the data from the API.
   */
  useEffect(() => {
    if (reload === true) {
      state.reloadTableData();
    }
  }, [reload]);

  /**
   * refresh table data.
   */
  useEffect(() => {
    if (isNull(fetch)) {
      if (!isEmpty(state?.data) && !isEmpty(rows) && state?.data !== rows) {
        state?.setData(rows);
      }
    }
  }, [rows]);

  /**
   * set initial table data.
   */
  useEffect(() => {
    if (isNull(fetch)) {
      if (isEmpty(state?.data) && !isEmpty(rows) && state?.data !== rows) {
        state?.setData(rows);
      }
    }
  }, [state?.data, rows]);

  /**
   * sync table rows with form rows
   */
  useEffect(() => {
    if (isFunction(reset) && isFunction(fetch)) {
      if (!isEmpty(state?.data) && !isEmpty(rows)) {
        if (state?.data !== rows) {
          reset(state?.data);
        }
      }
    }
  }, [state?.data, rows]);

  /**
   * Since Abyss doesn't allow adding components to the top right of the table, the component is dynamically injected.
   */
  useEffect(() => {
    if (abyssConfiguration?.showTopPagination === true) {
      const headerContainer = document.querySelector('.abyss-data-table-pagination-top-inner-container');

      if (!isNull(headerContainer)) {
        const paginationContainer = document.querySelector(
          '.abyss-data-table-pagination-top-inner-container .abyss-data-table-pagination-top-sub-container'
        );

        if (!isNull(paginationContainer)) {
          const goToPageContainer = document.querySelector(
            '.abyss-data-table-pagination-top-inner-container .pagination-go-to-page'
          );

          if (isNull(goToPageContainer)) {
            const goToPageElement = document.createElement('div');
            goToPageElement.classList.add('pagination-go-to-page');

            headerContainer.appendChild(goToPageElement);
          }
        }
      }

      const goToPageContainer = document.querySelector(
        '.abyss-data-table-pagination-top-inner-container .pagination-go-to-page'
      );

      if (!isNull(goToPageContainer)) {
        const root = createRoot(goToPageContainer);
        root.render(<GoToPage currentPage={state?.state?.pageIndex} navigate={state?.pagination?.gotoPage} />);
      }
    }
  }, [state?.state?.pageIndex]);

  /**
   * pin table headers to the top of the table and follow as user scrolls.
   */
  useLayoutEffect(() => {
    if (stickyHeaders) {
      const tableHead = document.querySelector('.abyss-table-head');

      if (!isNull(tableHead)) {
        tableHead.setAttribute('style', 'z-index: 300');
        const tableRoot = [...document.querySelectorAll('.abyss-data-table-root')];
        tableRoot.map((item) => {
          item.setAttribute('style', 'max-height: calc(100vh - 25px)');
          return item;
        });
      }
    }
  }, [stickyHeaders]);

  return (
    <ErrorHandler location="src/components/Table/Table.jsx">
      <motion.div
        animate="open"
        initial={{ opacity: 0 }}
        variants={{
          closed: { opacity: 0 },
          open: { opacity: 1 },
        }}
      >
        <Styles>
          <DataTable hideTitleHeader tableState={state} title="" />
        </Styles>
      </motion.div>
    </ErrorHandler>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      accessor: PropTypes.string,
      Cell: PropTypes.func,
      Filter: PropTypes.func,
      Header: PropTypes.string,
      sortable: PropTypes.bool,
      width: PropTypes.number,
    })
  ),
  configuration: PropTypes.shape({
    usePagination: PropTypes.bool,
  }),
  dataKey: PropTypes.string,
  fetch: PropTypes.func,
  isError: PropTypes.shape({
    message: PropTypes.string,
  }),
  reload: PropTypes.bool,
  requestParameters: PropTypes.shape({
    page: PropTypes.number,
    size: PropTypes.number,
    sort: PropTypes.string,
  }),
  reset: PropTypes.func,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
  setRequestParameters: PropTypes.func,
  stickyHeaders: PropTypes.bool,
};

Table.defaultProps = {
  columns: [],
  configuration: {},
  dataKey: 'table',
  fetch: null,
  isError: null,
  reload: false,
  requestParameters: {},
  reset: null,
  rows: [],
  setRequestParameters: null,
  stickyHeaders: false,
};
