import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '.abyss-data-table-root *': {
    border: 'none',
  },
  '.abyss-divider-root': {
    backgroundColor: 'var(--abyss-colors-gray2)',
    marginBottom: 'var(--abyss-space-lg) !important',
    marginTop: 'var(--abyss-space-xl) !important',
  },
  '.abyss-table-root': {
    '.abyss-table-styled-header': {
      '.abyss-table-header-data': {
        '.abyss-layout-group > div': {
          marginLeft: 0,
          marginRight: 'var(--abyss-space-md)',
        },
        width: '100%',
      },
      '.abyss-table-header-sort-arrow-container': {
        alignItems: 'end',
        cursor: 'pointer !important',
        height: '100%',
        justifyContent: 'center',
        margin: 0,
        position: 'absolute',
        right: '0',
        width: '100%',
      },
      '[role=separator]': {
        display: 'none',
        visibility: 'hidden',
      },
    },
    fontFamily: 'menlo',
    'tfoot tr:first-child td': {
      border: 'none',
    },
    thead: {
      outline: 'none',
    },
    tr: {
      backgroundColor: 'transparent',
      borderTop: '1px solid var(--abyss-colors-gray2)',
      'th,td': {
        backgroundColor: 'transparent',
      },
    },
  },
});
