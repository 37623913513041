import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '.abyss-accordion-root': {
    '.abyss-accordion-item': {
      '.abyss-accordion-content-container': {
        borderBottom: '1px solid var(--abyss-colors-gray4)',
        borderTop: '1px solid var(--abyss-colors-gray4)',
      },
      '.abyss-accordion-trigger': {
        '&:last-child': {
          borderBottom: 'none',
        },
        backgroundColor: 'var(--abyss-colors-info2) !important',
        borderBottom: '1px solid var(--abyss-colors-gray4)',
        boxShadow: 'none',
        height: '64px',
      },
      '&:last-child': {
        '.abyss-accordion-content-container': {
          borderBottom: 'none',
        },
        borderBottom: 'none',
      },
      '&[data-state="closed"]': {
        borderBottom: '1px solid var(--abyss-colors-gray4)',
      },
      borderColor: 'var(--abyss-colors-gray4)',
      borderRadius: '0 !important',
    },
    border: '1px solid var(--abyss-colors-gray4)',
    borderRadius: '0 !important',
  },
});

export const EditNameStyles = styled('div', {
  '.abyss-text-input-root': {
    '.abyss-text-input-right-add-on': {
      background: 'none',
      border: 'none',
      fontSize: 'var(--abyss-fontSizes-sm)',
      lineHeight: '100%',
      padding: 0,
    },
    '.abyss-text-input-right-element': {
      '&>div': {
        height: '100%',
        position: 'relative',
      },
      button: {
        borderBottomWidth: '1px !important',
        borderTopWidth: '1px !important',
        height: '100%',
      },
    },
    marginRight: 'var(--abyss-space-md)',
  },
});
