import { Box } from '@abyss/web/ui/Box';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Widget } from '@src/components/Widget';
import { abbrNum } from '@src/includes/functions';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Widget: SourceRecordsRemediated
 *
 * Displays information about how long records are sitting in the Action Path.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const SourceRecordsRemediated = (props) => {
  const { data } = props;

  return (
    <ErrorHandler location="src/features/ActionPaths/components/widgets/SourceRecordsRemediated/SourceRecordsRemediated.jsx">
      <Widget description="Remediation Duration" title="Source Records Remediated">
        <Layout.Group grow space={themeConfiguration?.theme?.space?.xl}>
          <Box css={{ backgroundColor: 'var(--abyss-colors-success2)', padding: 'var(--abyss-space-lg)' }}>
            <Layout.Stack alignLayout="center">
              <Text>
                <small>Minimum Time</small>
              </Text>
              <Text>
                <strong>
                  {abbrNum(data?.minDays)} {abbrNum(data?.minDays) === 1 ? 'day' : 'days'}
                </strong>
              </Text>
            </Layout.Stack>
          </Box>
          <Box css={{ backgroundColor: 'var(--abyss-colors-warning2)', padding: 'var(--abyss-space-lg)' }}>
            <Layout.Stack alignLayout="center">
              <Text>
                <small>Average Time</small>
              </Text>
              <Text>
                <strong>
                  {abbrNum(data?.avgDays)} {abbrNum(data?.avgDays) === 1 ? 'day' : 'days'}
                </strong>
              </Text>
            </Layout.Stack>
          </Box>
          <Box css={{ backgroundColor: 'var(--abyss-colors-error2)', padding: 'var(--abyss-space-lg)' }}>
            <Layout.Stack alignLayout="center">
              <Text>
                <small>Minimum Time</small>
              </Text>
              <Text>
                <strong>
                  {abbrNum(data?.maxDays)} {abbrNum(data?.maxDays) === 1 ? 'day' : 'days'}
                </strong>
              </Text>
            </Layout.Stack>
          </Box>
        </Layout.Group>
      </Widget>
    </ErrorHandler>
  );
};

SourceRecordsRemediated.propTypes = {
  data: PropTypes.shape({
    avgDays: PropTypes.number,
    maxDays: PropTypes.number,
    minDays: PropTypes.number,
  }),
};

SourceRecordsRemediated.defaultProps = {
  data: {},
};
