import { ErrorHandler } from '@src/components/ErrorHandler';
import { Table as TableComponent } from '@src/components/Table-static';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import configuration from './includes/configuration.json';

/**
 * Table: Actions
 *
 * Displays a list of actions within a DataTable.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Actions = (props) => {
  const { rows } = props;

  /**
   * Columns for table.
   */
  const columns = useMemo(() => {
    return configuration?.initialColumns;
  }, []);

  return (
    <ErrorHandler location="src/features/ActionPaths/components/tables/Actions/Actions.jsx">
      <TableComponent
        {...{
          columns,
          configuration,
          dataKey: `actionPaths-actions`,
          rows,
        }}
      />
    </ErrorHandler>
  );
};

Actions.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({})),
};

Actions.defaultProps = {
  rows: [],
};
