import { config } from '@abyss/web/tools/config';
import { Axios } from '@src/context/Api/includes/Axios';
import { logger } from '@src/includes/logger';
import { isUndefined } from 'lodash';

/**
 * GetTagHistory
 *
 * Get the trend data for tags.
 *
 * @param payload
 * @returns {Promise<*>}
 * @constructor
 */
export const GetTagHistory = async (payload = {}) => {
  try {
    const requestArgs = {
      baseURL: config('API_URL'),
      data: {},
      headers: {
        'x-api-endpoint': '/dashboard/operational/tag-trend',
      },
      method: 'POST',
      params: {},
    };

    const thePayload = { ...payload?.[1] };

    if (!isUndefined(payload?.[0])) {
      requestArgs.params = { ...{ queryKey: payload?.[0] } };
    }

    requestArgs.data = thePayload;

    const remoteResponse = await Axios.request(requestArgs);

    const response = remoteResponse;

    return response?.data;
  } catch (error) {
    logger.error('src/requests/queries/GetTagHistory.js -> GetTagHistory()', false, error);

    throw error;
  }
};
