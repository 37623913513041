import { Card } from '@abyss/web/ui/Card';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Layout } from '@abyss/web/ui/Layout';
import { ErrorHandler } from '@src/components/ErrorHandler';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * NotFound
 *
 * This component is used to display a not found message.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const NotFound = (props) => {
  const { button, icon, message, title } = props;

  return (
    <ErrorHandler location="src/features/Criteria/components/misc/Filters/components/NotFound/NotFound.jsx">
      <Layout.Group
        alignLayout="center"
        css={{
          padding: `var(--abyss-space-xs) 0px`,
        }}
      >
        <Card
          css={{
            background: 'transparent',
            borderStyle: 'dashed',
            boxShadow: 'none',
          }}
        >
          <Layout.Stack
            css={{
              padding: `var(--abyss-space-md) var(--abyss-space-xl)`,
            }}
            space={0}
          >
            <IconSymbol color="var(--abyss-colors-gray5)" icon={icon} size="48px" variant="outlined" />
            <div>
              <strong>{title}</strong>
            </div>
            <div style={{ fontWeight: 'normal' }}>{message}</div>
            <div style={{ marginTop: 'var(--abyss-space-lg)' }}>{button}</div>
          </Layout.Stack>
        </Card>
      </Layout.Group>
    </ErrorHandler>
  );
};

NotFound.propTypes = {
  button: PropTypes.element,
  icon: PropTypes.string,
  message: PropTypes.string,
  title: PropTypes.string,
};

NotFound.defaultProps = {
  button: null,
  icon: '',
  message: '',
  title: '',
};
