import { useToast } from '@abyss/web/hooks/useToast';
import { Flex } from '@abyss/web/ui/Flex';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { useApi } from '@src/context/Api';
import { motion } from 'framer-motion';
import React, { useState } from 'react';

import { FormModal } from './components/FormModal';
import { Table } from './components/Table';

/**
 * List
 *
 * Provides the user with a screen listing the existing code categories.
 *
 * @returns {Element}
 * @constructor
 */
export const List = () => {
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [currentEntity, setCurrentEntity] = useState({});

  const { clearApiCache, useApiMutation, useApiQuery } = useApi();

  const [ListCodeCategories, { data, error, isFetching, isLoading, refetch }] = useApiQuery('ListCodeCategories');
  const [SaveCodeCategory] = useApiMutation('SaveCodeCategory');

  const { toast } = useToast();

  /**
   * handleSave
   *
   * Handles the saving of a code category.
   *
   * @param payload
   * @returns {Promise<void>}
   */
  const handleSave = async (payload = {}) => {
    const toastId = 'save-code-category';

    toast.show({
      ariaLoadingLabel: 'Saving Code Category',
      autoClose: false,
      id: `${toastId}-info`,
      isLoading: true,
      message: 'Code Category is preparing to save.',
      title: 'Saving Code Category...',
      variant: 'info',
    });

    await SaveCodeCategory(payload, {
      onError: () => {
        toast.hide(`${toastId}-info`);
        toast.show({
          id: `${toastId}-error`,
          message: `Unable to save code category.`,
          title: 'Code Category Save Failed',
          variant: 'error',
        });
      },
      onSuccess: () => {
        clearApiCache(['ListCodeCategories']);
        refetch();
        toast.hide(`${toastId}-info`);
        toast.show({
          id: `${toastId}-success`,
          message: `Code Category has been saved.`,
          title: 'Saved Code Category',
          variant: 'success',
        });
      },
    });
  };

  return (
    <ErrorHandler location="src/routes/private/Admin/screens/CodeCategories/List/List.jsx">
      <motion.div
        animate="open"
        initial={{ opacity: 0 }}
        variants={{
          closed: { opacity: 0 },
          open: { opacity: 1 },
        }}
      >
        <Grid>
          <Grid.Col
            span={{
              xs: '100%',
            }}
          >
            <Flex alignItems="center" direction="row">
              <Heading offset={0}>Code Categories</Heading>
              <Button
                before={<IconSymbol icon="add" />}
                css={{
                  marginLeft: themeConfiguration?.theme?.space?.md,
                }}
                isDisabled={isLoading || isFetching}
                onClick={() => {
                  setIsFormModalOpen(true);
                  setCurrentEntity({});
                }}
                size="$sm"
                variant="outline"
              >
                Create
              </Button>
            </Flex>
          </Grid.Col>
          <Grid.Col
            span={{
              xs: '100%',
            }}
          >
            <Table
              error={error}
              isLoading={isLoading || isFetching}
              refetch={refetch}
              requestArgs={{
                page: 0,
                size: 25,
                sort: 'categoryCode,asc',
              }}
              requestFunction={ListCodeCategories}
              requestKey="ListCodeCategories"
              rows={data?.content || []}
              setCurrentEntity={setCurrentEntity}
              setIsFormModalOpen={setIsFormModalOpen}
              totalPages={data?.totalPages || 1}
              totalRecords={data?.totalElements || 0}
            />
            {isFormModalOpen && (
              <FormModal
                currentEntity={currentEntity}
                handleSave={handleSave}
                isOpen={isFormModalOpen}
                setCurrentEntity={setCurrentEntity}
                setIsOpen={setIsFormModalOpen}
              />
            )}
          </Grid.Col>
        </Grid>
      </motion.div>
    </ErrorHandler>
  );
};
