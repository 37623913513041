import { config } from '@abyss/web/tools/config';
import { Axios } from '@src/context/Api/includes/Axios';
import { logger } from '@src/includes/logger';
import { isUndefined } from 'lodash';

/**
 * ListImpactedSystems
 *
 * Retrieves a list of impacted systems from the remote API via an authenticated request.
 *
 * @returns {Promise<[]>}
 * @constructor
 */
export const ListImpactedSystems = async (payload = {}) => {
  try {
    const requestArgs = {
      baseURL: config('API_URL'),
      headers: {
        'x-api-endpoint': '/code-lists/categories/ImpactedSystem',
      },
      method: 'GET',
    };

    if (!isUndefined(payload)) {
      requestArgs.params = { ...{ queryKey: payload[0] }, ...payload[1] };
    }

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse.data;
  } catch (error) {
    logger.error('src/requests/queries/ListImpactedSystems.js -> ListImpactedSystems()', false, error);

    throw error;
  }
};
