import { Box } from '@abyss/web/ui/Box';
import { Chart } from '@src/components/Chart';
import { chartTypes } from '@src/components/Chart/includes/chartTypes';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Widget } from '@src/components/Widget';
import { RiskRecordsTable as RiskRecordsTable } from '@src/features/Risk/components/tables/RiskRecords';
import { isEmpty, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import { Styles } from './includes/styles';

/**
 * Widget: AggregationView
 *
 * @TODO Needs description.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const AggregationView = (props) => {
  const { aggregation, criteria, view } = props;

  const [currentChartType, setCurrentChartType] = useState(aggregation?.researchAggregation?.defaultChartType);
  const [data, setData] = useState(aggregation?.aggregationResults);

  let widgetMenuOptions = [];

  if (!view?.id?.includes('risk-records')) {
    widgetMenuOptions = [
      {
        label: 'View as...',
        onChange: setCurrentChartType,
        radios: aggregation?.researchAggregation?.chartTypes.map((chartType) => {
          return {
            label: chartTypes?.[chartType]?.label,
            value: chartType,
          };
        }),
        title: 'View as...',
        value: currentChartType,
      },
    ];
  }

  /**
   * Custom sorting based on view.
   */
  useEffect(() => {
    let theData = aggregation?.aggregationResults;

    if (['last-reopened-date', 'last-streamed-date'].includes(view?.id)) {
      const order = ['1 week', '1 week - 1 month', '1 month - 3 months', '3 months - 9 months', '> 9 months'];

      theData = sortBy(aggregation?.aggregationResults, (item) => {
        return order.indexOf(String(item.name).toLowerCase());
      });
    }

    if (data !== theData) {
      setData(theData);
    }
  }, [view?.id, aggregation?.aggregationResults]);

  /**
   * tableColumns
   *
   * Map the table columns based on the aggregation data.
   *
   * @type {*[]}
   */
  const tableConfiguration = useMemo(() => {
    const theConfiguration = {
      initialColumns: [],
      initialState: {
        sortBy: [
          {
            desc: true,
            id: 'value',
          },
        ],
      },
    };

    if (!isEmpty(aggregation?.researchAggregation?.dataTableColumnHeaders)) {
      Object.keys(aggregation?.researchAggregation?.dataTableColumnHeaders).forEach((accessor) => {
        theConfiguration.initialColumns.push({
          accessor,
          Cell: ({ cell }) => {
            // Formats number to display with commas.
            if (accessor === 'value') {
              return <React.Fragment>{Number(cell?.value).toLocaleString('en-US')}</React.Fragment>;
            }

            // Formats a number to display as a decimal.
            if (accessor === 'perc') {
              return <React.Fragment>{cell?.value}%</React.Fragment>;
            }

            return cell?.value;
          },
          /* eslint-disable react/prop-types */
          Header: aggregation?.researchAggregation?.dataTableColumnHeaders?.[accessor],
        });
      });
    }

    return theConfiguration;
  }, [aggregation?.researchAggregation?.dataTableColumnHeaders]);
  return (
    <ErrorHandler location="src/features/Risk/components/widgets/AggregationView/AggregationView.jsx">
      <Styles css={view?.id === 'risk-records' && { width: '100%' }}>
        <Box color="" height="" key={view?.id}>
          <Widget description={view?.description || ''} menu={widgetMenuOptions || []} title={view?.name || ''}>
            {view?.id === 'risk-records' ? (
              <RiskRecordsTable allowExport={false} criteria={criteria} showHeaderLeft={false} showHeaderTop={false} />
            ) : (
              <Chart data={data} legendLimit={15} tableConfiguration={tableConfiguration} type={currentChartType} />
            )}
          </Widget>
        </Box>
      </Styles>
    </ErrorHandler>
  );
};

AggregationView.propTypes = {
  aggregation: PropTypes.shape({
    aggregationResults: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        perc: PropTypes.number,
        value: PropTypes.number,
      })
    ),
    researchAggregation: PropTypes.shape({
      chartTypes: PropTypes.arrayOf(PropTypes.string),
      dataTableColumnHeaders: PropTypes.shape({
        accessor: PropTypes.string,
      }),
      defaultChartType: PropTypes.string,
    }),
  }),
  assets: PropTypes.shape({
    description: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  criteria: PropTypes.shape({
    entrance: PropTypes.shape({
      additional: PropTypes.arrayOf(
        PropTypes.shape({
          column: PropTypes.string,
          condition: PropTypes.string,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.arrayOf(PropTypes.string)]),
        })
      ),
      commonCriteriaVersionsIds: PropTypes.arrayOf(PropTypes.string),
      commonIds: PropTypes.arrayOf(PropTypes.string),
    }),
    exit: PropTypes.shape({
      additional: PropTypes.arrayOf(
        PropTypes.shape({
          column: PropTypes.string,
          condition: PropTypes.string,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.arrayOf(PropTypes.string)]),
        })
      ),
      commonCriteriaVersionsIds: PropTypes.arrayOf(PropTypes.string),
      commonIds: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
  view: PropTypes.shape({
    description: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
  }),
};

AggregationView.defaultProps = {
  aggregation: {
    aggregationResults: [],
    researchAggregation: {
      chartTypes: [],
      dataTableColumnHeaders: {},
      defaultChartType: '',
    },
  },
  assets: {
    description: '',
    id: '',
    name: '',
  },
  criteria: {
    entrance: {
      additional: [],
      commonCriteriaVersionsIds: [],
      commonIds: [],
    },
    exit: {
      additional: [],
      commonCriteriaVersionsIds: [],
      commonIds: [],
    },
  },
  view: {
    description: '',
    id: '',
    name: '',
  },
};
