import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '.flexGrid': {
    alignContent: 'stretch !important',
    alignItems: 'stretch !important',
    display: 'flex !important',
    flexDirection: 'row !important',
    flexWrap: 'wrap !important ',
    justifyContent: 'flex-start',
  },
  '.grow': {
    '> div': {
      '.widget': {
        '.widget-inner': {
          height: '100%',
          width: '100%',
        },
        height: '100%',
        width: '100%',
      },
      height: '100%',
      width: '100%',
    },
    display: 'flex !important',
    flex: '1 1 33.33% !important',
    flexBasis: 'unset !important',
    flexWrap: 'wrap !important',
    maxWidth: 'unset !important',
    width: '33.33%',
  },
});
