import { SelectInput } from '@abyss/web/ui/SelectInput';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { isEmpty, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

/**
 * Field: Version
 *
 * This field is used to display the version of the entity, with options to select a different version.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Version = (props) => {
  const { criteriaVersions, currentEntity } = props;

  const versionOptions = useMemo(() => {
    const theVersions = [];

    if (!isUndefined(criteriaVersions) && !isEmpty(criteriaVersions)) {
      criteriaVersions?.forEach((criteriaVersion) => {
        const isCurrent = criteriaVersion?.version === currentEntity?.activeVersionNbr;

        theVersions.push({
          label: `v${criteriaVersion?.version} ${isCurrent ? '(Current)' : ''}`,
          value: criteriaVersion?.version,
        });
      });
    }

    return theVersions;
  }, [criteriaVersions, currentEntity]);

  return (
    <ErrorHandler location="src/routes/private/Admin/screens/CommonCriteria/components/fields/Version/Version.jsx">
      <div
        style={{
          width: `${Number(themeConfiguration?.theme?.space?.xl?.replace('px', '')) * 3}px`,
        }}
      >
        <SelectInput label="Version" model="version" options={versionOptions} />
      </div>
    </ErrorHandler>
  );
};

Version.propTypes = {
  criteriaVersions: PropTypes.arrayOf(
    PropTypes.shape({
      version: PropTypes.number,
    })
  ),
  currentEntity: PropTypes.shape({
    activeVersionNbr: PropTypes.number,
  }),
};

Version.defaultProps = {
  criteriaVersions: [],
  currentEntity: {},
};
