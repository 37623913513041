import { Checkbox } from '@abyss/web/ui/Checkbox';
import { Divider } from '@abyss/web/ui/Divider';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { Button } from '@src/components/Button';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Styles } from './includes/styles';

/**
 * PopoverContent
 *
 * @TODO Needs description.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const PopoverContent = (props) => {
  const { currentEntity, handleExport, setIsOpen } = props;
  const [isChecked, setIsChecked] = useState(false);

  return (
    <Styles>
      <Layout.Stack alignItems="left" alignLayout="left" grow space={themeConfiguration?.theme?.space?.md}>
        <div>
          <Text fontWeight="bold">Warning:</Text>
        </div>
        <div>
          <Text>Risk record count is greater then one million items.</Text>
        </div>
        <div>
          <Checkbox
            isChecked={isChecked}
            label="Allow Export"
            onChange={(event) => {
              setIsChecked(event?.target?.checked);
            }}
            size="sm"
          />
        </div>
      </Layout.Stack>
      <Divider color="" height={1} margin={themeConfiguration?.theme?.space?.sm} />
      <Layout.Group alignLayout="right">
        <Button
          isDisabled={!isChecked}
          onClick={async () => {
            setIsOpen(false);
            await handleExport(currentEntity?.id);
          }}
          variant="solid"
        >
          Export
        </Button>
        <Button
          onClick={() => {
            setIsOpen(false);
          }}
          variant="outline"
        >
          Close
        </Button>
      </Layout.Group>
    </Styles>
  );
};

PopoverContent.propTypes = {
  currentEntity: PropTypes.shape({
    id: PropTypes.string,
  }),
  handleExport: PropTypes.func,
  setIsOpen: PropTypes.func,
};

PopoverContent.defaultProps = {
  currentEntity: {},
  handleExport: () => {},
  setIsOpen: () => {},
};
