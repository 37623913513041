import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '.abyss-chip-icon': {
    '.abyss-icon': {
      fontSize: '1.025rem',
      marginTop: '-0.025rem',
    },
    marginRight: 'var(--abyss-space-xs) !important',
  },
});
