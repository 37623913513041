import { config } from '@abyss/web/tools/config';
import { Axios } from '@src/context/Api/includes/Axios';
import { translateAssignments, translateCriteria } from '@src/features/ActionPaths/includes/functions';
import { logger } from '@src/includes/logger';
import { isNil } from 'lodash';

/**
 * GetActionPath
 *
 * Retrieves a single action path from the remote API via an authenticated request.
 *
 * @param payload
 * @returns {Promise<any>}
 * @constructor
 */
export const GetActionPath = async (payload = {}) => {
  try {
    const theQueryKey = payload?.[0];
    const thePayload = payload?.[1];

    const requestArgs = {
      baseURL: config('API_URL'),
      headers: {
        'x-api-endpoint': `/action-paths/${thePayload?.actionPathId}`,
      },
      method: 'GET',
      params: { queryKey: theQueryKey },
    };

    const remoteResponse = await Axios.request(requestArgs);

    if (!isNil(remoteResponse?.data?.criteria)) {
      remoteResponse.data.criteria = translateCriteria(remoteResponse?.data?.criteria, 'incoming');
    }

    if (!isNil(remoteResponse?.data?.assignments)) {
      remoteResponse.data.assignments = translateAssignments(remoteResponse?.data?.assignments, 'incoming');
    }

    return remoteResponse?.data;
  } catch (error) {
    logger.error('src/requests/queries/GetActionPath.js -> GetActionPath()', false, error);

    throw error;
  }
};
