import { Divider } from '@abyss/web/ui/Divider';
import { Grid } from '@abyss/web/ui/Grid';
import { Skeleton as AbyssSkeleton } from '@abyss/web/ui/Skeleton';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { motion } from 'framer-motion';
import React from 'react';

/**
 * Skeleton
 *
 * Displays a roughed-in idea/placeholder of what the UI should look like while the data is fetching from the API.
 *
 * @returns {Element}
 * @constructor
 */
export const Skeleton = () => {
  return (
    <ErrorHandler location="eerh/eerh-rem-ui/products/web/src/routes/private/Notifications/screens/Events/View/components/Skeleton/Skeleton.jsx">
      <motion.div
        animate="open"
        initial={{ opacity: 0 }}
        variants={{
          closed: { opacity: 0 },
          open: { opacity: 1 },
        }}
      >
        <Grid>
          <Grid.Col
            css={{ paddingTop: themeConfiguration?.theme?.space?.lg }}
            span={{
              xs: '100%',
            }}
          >
            <AbyssSkeleton height={50} width={`${50}%`} />
          </Grid.Col>
          <Grid.Col
            css={{ paddingTop: themeConfiguration?.theme?.space?.lg }}
            span={{
              xs: '50%',
            }}
          >
            <AbyssSkeleton height={400} />
          </Grid.Col>
          <Grid.Col
            css={{ paddingTop: themeConfiguration?.theme?.space?.lg }}
            span={{
              xs: '50%',
            }}
          >
            <AbyssSkeleton height={400} />
          </Grid.Col>
        </Grid>
        <Divider color="transparent" />
        <Grid>
          <Grid.Col
            css={{ paddingTop: themeConfiguration?.theme?.space?.lg }}
            span={{
              xs: '100%',
            }}
          >
            <AbyssSkeleton height={400} />
          </Grid.Col>
        </Grid>
      </motion.div>
    </ErrorHandler>
  );
};
