import { config } from '@abyss/web/tools/config';
import { Axios } from '@src/context/Api/includes/Axios';
import { logger } from '@src/includes/logger';
import { isEmpty, isUndefined } from 'lodash';

/**
 * DuplicateActionPath
 *
 * Duplicates a single action path from the remote API via an authenticated request.
 *
 * @param payload
 * @returns {Promise<any>}
 * @constructor
 */
export const DuplicateActionPath = async (payload = {}) => {
  try {
    const requestArgs = {
      baseURL: config('API_URL'),
      data: {},
      headers: {
        'x-api-endpoint': `/action-paths`,
      },
      method: 'POST',
    };

    if (!isUndefined(payload)) {
      requestArgs.params = { ...{ mutationKey: payload[0] }, ...payload[1] };
      requestArgs.headers['x-api-endpoint'] += `/${payload[1]?.id}/duplicate`;
    }

    if (!isEmpty(payload[1]?.name)) {
      requestArgs.data.actionPathName = payload[1]?.name;
    }

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse.data;
  } catch (error) {
    logger.error('src/requests/mutations/DuplicateActionPath.js -> DuplicateActionPath()', false, error);

    throw error;
  }
};
