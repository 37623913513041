import { config } from '@abyss/web/tools/config';
import { Axios } from '@src/context/Api/includes/Axios';
import { logger } from '@src/includes/logger';
import { isUndefined } from 'lodash';

/**
 * RiskRecordActionPathAssociation
 *
 * Associates EID's to Action Paths and vice versa.
 *
 * @param payload
 * @returns {Promise<any>}
 * @constructor
 */
export const RiskRecordActionPathAssociation = async (payload = {}) => {
  try {
    const requestArgs = {
      baseURL: config('API_URL'),
      data: payload?.[1],
      headers: {
        'x-api-endpoint': '/risk-records/manual/associate',
      },
      method: 'POST',
    };

    if (!isUndefined(payload?.[0])) {
      requestArgs.params = { ...{ mutationKey: payload?.[0] } };
    }

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse.data;
  } catch (error) {
    logger.error(
      'src/requests/mutations/RiskRecordActionPathAssociation.js -> RiskRecordActionPathAssociation()',
      false,
      error
    );

    throw error;
  }
};
