import { useToast } from '@abyss/web/hooks/useToast';
import { useApi } from '@src/context/Api';
import { useCallback } from 'react';

import actions from './includes/actions.json';

/**
 * Hook: useSave
 *
 * Makes an API request to save the action path.
 *
 * @param action
 * @returns {{saveEvent: *, handleSave: (function({}=, function()=): Promise<*>)}}
 */
export const useSave = (action = null) => {
  const currentAction = actions?.[action];

  const { clearApiCache, useApiMutation } = useApi();
  const [SaveActionPath, mutation] = useApiMutation('SaveActionPath');

  const { toast } = useToast();

  const handleSave = useCallback(async (variables = {}, refetch = () => {}) => {
    const toastId = `action-paths-${action}`;

    toast.show({
      autoClose: false,
      id: `${toastId}-info`,
      isLoading: true,
      message: currentAction?.info?.message,
      title: currentAction?.info?.title,
      variant: 'info',
    });

    const payload = {
      ...variables,
      ...{
        action,
      },
    };

    return await SaveActionPath(payload, {
      onError: () => {
        toast.hide(`${toastId}-info`);
        toast.show({
          id: `${toastId}-error`,
          message: currentAction?.error?.message,
          title: currentAction?.error?.title,
          variant: 'error',
        });
      },
      onSuccess: () => {
        toast.hide(`${toastId}-info`);

        toast.show({
          id: `${toastId}-success`,
          message: currentAction?.success?.message,
          title: currentAction?.success?.title,
          variant: 'success',
        });

        clearApiCache([
          'ListActionPaths',
          'ListCommonCriteria',
          'GetActionPath',
          'GetCommonCriteria',
          'GetCommonCriteriaVersion',
        ]);

        refetch();
      },
    });
  }, []);

  return { handleSave, saveEvent: mutation };
};
