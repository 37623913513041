import { TextInputArea } from '@abyss/web/ui/TextInputArea';
import { ErrorHandler } from '@src/components/ErrorHandler';
import fields from '@src/features/ActionPaths/includes/fields.json';
import PropTypes from 'prop-types';
import React from 'react';

const { notes: notesField } = fields;

/**
 * Field: Notes
 *
 * Add notes to the action path.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Notes = (props) => {
  const { form } = props;

  return (
    <ErrorHandler location="src/features/ActionPaths/components/misc/Wizard/components/fields/Notes/Notes.jsx">
      <TextInputArea
        {...notesField}
        onChange={() => {
          form?.validate(
            `remediation[notes]`,
            () => {},
            () => {}
          );
        }}
      />
    </ErrorHandler>
  );
};

Notes.propTypes = {
  form: PropTypes.shape({
    validate: PropTypes.func,
  }),
};

Notes.defaultProps = {
  form: {
    validate: () => {},
  },
};
