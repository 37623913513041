import { Grid } from '@abyss/web/ui/Grid';
import { Layout } from '@abyss/web/ui/Layout';
import { Skeleton as AbyssSkeleton } from '@abyss/web/ui/Skeleton';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { motion } from 'framer-motion';
import React from 'react';

export const Skeleton = () => {
  return (
    <ErrorHandler location="src/routes/private/Analysis/screens/EidSearch/components/Layout/Header/components/Skeleton/Skeleton.jsx">
      <motion.div
        animate="open"
        initial={{ opacity: 0 }}
        style={{ width: '240px' }}
        variants={{
          closed: { opacity: 0 },
          open: { opacity: 1 },
        }}
      >
        <Grid>
          <Grid.Col
            span={{
              xs: '100%',
            }}
          >
            <Layout.Stack alignItems="left" grow>
              <AbyssSkeleton height="400px" width="100%" />
            </Layout.Stack>
          </Grid.Col>
        </Grid>
      </motion.div>
    </ErrorHandler>
  );
};
