import { useForm } from '@abyss/web/hooks/useForm';
import { useToast } from '@abyss/web/hooks/useToast';
import { Divider } from '@abyss/web/ui/Divider';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Grid } from '@abyss/web/ui/Grid';
import { Layout } from '@abyss/web/ui/Layout';
import { Modal } from '@abyss/web/ui/Modal';
import { SelectInputMulti } from '@abyss/web/ui/SelectInputMulti';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Loader } from '@src/components/Loader';
import { useApi } from '@src/context/Api';
import { logger } from '@src/includes/logger';
import { isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

/**
 * AttachActionPaths
 *
 * Displays a modal to attach action paths to the risk record.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const AttachActionPaths = (props) => {
  const { eid, handleSearch, isOpen, recordActionPaths, setIsOpen } = props;

  const { clearApiCache, useApiMutation, useApiQuery } = useApi();

  const [ListActionPaths, { data: actionPaths, isFetching, isLoading, refetch: refetchActionPaths }] =
    useApiQuery('ListActionPaths');
  const [RiskRecordActionPathAssociation] = useApiMutation('RiskRecordActionPathAssociation', {
    excludedHttpCodes: [400],
  });

  const defaultValues = {
    actionPaths: [],
  };

  const form = useForm({ defaultValues });
  const { toast } = useToast();

  /**
   * Retrieve the list of action paths.
   */
  useEffect(() => {
    if (isUndefined(actionPaths)) {
      ListActionPaths({
        manualAssociation: 'true',
        statusList: ['ACTIVE'],
      });
    }
  }, [actionPaths]);

  /**
   * handleSubmit
   *
   * Handles the form submission.
   *
   * @param submittedValues
   * @returns {Promise<void>}
   */
  const handleSubmit = async (submittedValues) => {
    try {
      const toastId = `actionPath-association-${eid}`;
      toast.show({
        ariaLoadingLabel: 'Attaching Action Paths to EID',
        autoClose: false,
        id: `${toastId}-info`,
        isLoading: true,
        message: 'Attaching Action Paths to EID...',
        title: 'Attaching Action Paths to EID',
        variant: 'info',
      });

      const payload = {
        actionPaths: submittedValues?.actionPaths,
        eids: [eid],
      };

      await RiskRecordActionPathAssociation(payload, {
        onError: () => {
          toast.hide(`${toastId}-info`);
          toast.show({
            id: `${toastId}-error`,
            message: 'Unable to attach Action Paths.',
            title: 'Attaching Action Paths Failed',
            variant: 'error',
          });
          setIsOpen(false);
          form?.setValue('actionPaths', []);
        },
        onSuccess: () => {
          setIsOpen(false);
          clearApiCache(['GetRiskRecord', 'ListActionPaths']);
          handleSearch();
          refetchActionPaths();
          toast.hide(`${toastId}-info`);
          toast.show({
            id: `${toastId}-success`,
            message: 'Acton Paths have been successfully attached.',
            title: 'Attached Action Paths to EID',
            variant: 'success',
          });
          form?.setValue('actionPaths', []);
        },
      });
    } catch (error) {
      logger.error(
        'src/routes/private/Analysis/screens/EidSearch/components/Layout/Header/components/Details/components/AttachActionPaths/AttachActionPaths.jsx -> handleSubmit()',
        false,
        error
      );
    }
  };

  return (
    <ErrorHandler location="src/routes/private/Analysis/screens/EidSearch/components/Layout/Header/components/Details/components/AttachActionPaths/AttachActionPaths.jsx">
      <Modal
        isOpen={isOpen}
        onClose={() => {
          return setIsOpen(false);
        }}
        title="Attach Action Paths"
      >
        <FormProvider autoComplete="off" onSubmit={handleSubmit} state={form}>
          <Modal.Section>
            <Grid>
              {isLoading || isFetching || isUndefined(actionPaths) ? (
                <Grid.Col
                  span={{
                    xs: '100%',
                  }}
                >
                  <Loader height="100%" width="100%" />
                </Grid.Col>
              ) : (
                <Grid.Col
                  span={{
                    xs: '50%',
                  }}
                >
                  <SelectInputMulti
                    isSearchable
                    label="Action Paths"
                    model="actionPaths"
                    options={actionPaths?.content
                      ?.filter((actionPath) => {
                        return !recordActionPaths?.find((recordActionPath) => {
                          return recordActionPath?.actionPathId === actionPath?.id;
                        });
                      })
                      ?.map((actionPath) => {
                        return {
                          label: actionPath?.name,
                          value: actionPath?.id,
                        };
                      })}
                    placeholder="Select Action Paths --"
                  />
                </Grid.Col>
              )}
            </Grid>
          </Modal.Section>
          <Modal.Section>
            <Divider height={1} />
            <Layout.Group alignLayout="right">
              <Button
                onClick={() => {
                  setIsOpen(false);
                }}
                variant="outline"
              >
                Cancel
              </Button>
              <Button isDisabled={!form?.formState?.isDirty} type="submit" variant="solid">
                Attach
              </Button>
            </Layout.Group>
          </Modal.Section>
        </FormProvider>
      </Modal>
    </ErrorHandler>
  );
};

AttachActionPaths.propTypes = {
  eid: PropTypes.string,
  handleSearch: PropTypes.func,
  isOpen: PropTypes.bool,
  recordActionPaths: PropTypes.arrayOf(
    PropTypes.shape({
      actionPathId: PropTypes.string,
      actionPathName: PropTypes.string,
    })
  ),
  setIsOpen: PropTypes.func,
};

AttachActionPaths.defaultProps = {
  eid: '',
  handleSearch: () => {},
  isOpen: false,
  recordActionPaths: [],
  setIsOpen: () => {},
};
