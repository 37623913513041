import { useApi } from '@src/context/Api';
import { isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

/**
 * useAssets
 *
 * Makes API requests to retrieve the assets required for the Risk Analysis screen.
 *
 * @returns {{isLoading: boolean, data: *}}
 */
export const useAssets = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { useApiQueries } = useApi();

  const theAssets = ['ListRiskCodes', 'ListViews'];

  const assets = useApiQueries(theAssets);

  /**
   * Determines the overall loading state of all asset queries.
   */
  useEffect(() => {
    if (
      !isEmpty(assets) &&
      Object.keys(assets).length === theAssets.length &&
      isEmpty(
        Object.keys(assets).filter((assetKey) => {
          const asset = assets[assetKey];
          return !(!asset?.isLoading && !asset?.isFetching);
        })
      )
    ) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [assets, theAssets]);

  return useMemo(() => {
    return { data: assets, isLoading };
  }, [isLoading, assets]);
};
