import { useToast } from '@abyss/web/hooks/useToast';
import { dayjs } from '@abyss/web/tools/dayjs';
import { Alert } from '@abyss/web/ui/Alert';
import { Button } from '@abyss/web/ui/Button';
import { Flex } from '@abyss/web/ui/Flex';
import { Grid } from '@abyss/web/ui/Grid';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Text } from '@abyss/web/ui/Text';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { useApi } from '@src/context/Api';
import { logger } from '@src/includes/logger';
import { isEmpty, isNull, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Records } from './components/Records';
import { SplitMerge } from './components/SplitMerge';

/**
 * Eimp
 *
 * Displays risk records from EIMP.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Eimp = (props) => {
  const { riskRecord } = props;

  const eid = riskRecord?.eid;
  const trustedRecords = riskRecord?.identityInformation?.eimpSourceRecords?.trustedEIMPRecords || [];
  const untrustedRecords = riskRecord?.identityInformation?.eimpSourceRecords?.untrustedEIMPRecords || [];
  const changeData = riskRecord?.identityInfo?.entityChange || {};
  const lastUpdated = riskRecord?.identityInformation?.eimpSourceRecords?.lastRetrievedDate || '';

  const [isLoading, setIsLoading] = useState(false);

  const dateFormatted = dayjs(lastUpdated).format('ddd, MMM D, YYYY h:mm A');
  const { toast } = useToast();
  const { useApiMutation } = useApi();

  const [RefreshEIMP] = useApiMutation('RefreshEIMP');

  const rows = orderBy(
    [
      ...trustedRecords.map((trustedRecord) => {
        return { ...trustedRecord, ...{ status: 'trusted' } };
      }),
      ...untrustedRecords.map((untrustedRecord) => {
        return { ...untrustedRecord, ...{ status: 'untrusted' } };
      }),
    ],
    ['status'],
    ['asc']
  );

  /**
   * handleRefresh
   *
   * Handle the refresh of EIMP data.
   *
   * @returns {Promise<void>}
   */
  const handleRefresh = async () => {
    try {
      const toastId = 'RefreshEIMP';

      toast.show({
        autoClose: true,
        id: `${toastId}-info`,
        isPending: true,
        message: `The EID is preparing to schedule an EIMP refresh.`,
        title: `Scheduling EIMP Refresh...`,
        variant: 'info',
      });

      setIsLoading(true);

      await RefreshEIMP(
        {
          eid,
        },
        {
          onError: () => {
            toast.hide(`${toastId}-info`);
            toast.show({
              id: `${toastId}-error`,
              message: `There was an error refreshing the EIMP.`,
              title: `Failed to Refresh EIMP`,
              variant: 'error',
            });
            setIsLoading(false);
          },
          onSuccess: () => {
            toast.hide(`${toastId}-info`);
            toast.show({
              id: `${toastId}-success`,
              message: 'Refresh times may vary',
              title: `EIMP Refresh Scheduled`,
              variant: 'success',
            });
            setIsLoading(false);
          },
        }
      );
    } catch (error) {
      logger.error(
        'src/routes/private/Analysis/screens/EidSearch/components/Layout/Main/components/Results/widgets/Eimp/Eimp.jsx -> handleRefresh()',
        false,
        error
      );
    }
  };

  return (
    <ErrorHandler location="src/routes/private/Analysis/screens/EidSearch/components/Layout/Main/components/Results/widgets/Eimp/Eimp.jsx">
      <Grid>
        <Grid.Col span={12}>
          <Flex justify="space-between">
            <Button
              ariaLoadingLabel="refreshing eimp data"
              before={<IconSymbol icon="refresh" />}
              isLoading={isLoading}
              onClick={() => {
                return handleRefresh();
              }}
            >
              Refresh Data
            </Button>
            <Text size="$sm">{`Last Updated: ${dateFormatted}`}</Text>
          </Flex>
        </Grid.Col>
        {isEmpty(rows) ? (
          <Grid.Col span={{ lg: '100%', md: '100%', sm: '100%', xs: '100%' }}>
            <Alert title="There is no EIMP trusted/untrusted data for this risk record." variant="info" />
          </Grid.Col>
        ) : (
          <Grid.Col span={{ xs: '100%' }}>
            <Records rows={rows} />
          </Grid.Col>
        )}
        {!isEmpty(changeData) && !isNull(changeData) && (
          <Grid.Col span={{ xs: '100%' }}>
            <SplitMerge changeData={changeData} />
          </Grid.Col>
        )}
      </Grid>
    </ErrorHandler>
  );
};

Eimp.propTypes = {
  riskRecord: PropTypes.shape({
    eid: PropTypes.string,
    identityInfo: PropTypes.shape({
      entityChange: PropTypes.shape({
        changeDate: PropTypes.string,
        changeDescription: PropTypes.string,
        changeReason: PropTypes.string,
        changeSource: PropTypes.string,
        changeStatus: PropTypes.string,
        changeStatusDate: PropTypes.string,
        changeStatusDescription: PropTypes.string,
        changeStatusReason: PropTypes.string,
        changeType: PropTypes.string,
      }),
    }),
    identityInformation: PropTypes.shape({
      eimpSourceRecords: PropTypes.shape({
        lastRetrievedDate: PropTypes.string,
        trustedEIMPRecords: PropTypes.arrayOf(
          PropTypes.shape({
            eid: PropTypes.string,
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            middleName: PropTypes.string,
            status: PropTypes.string,
          })
        ),
        untrustedEIMPRecords: PropTypes.arrayOf(
          PropTypes.shape({
            eid: PropTypes.string,
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            middleName: PropTypes.string,
            status: PropTypes.string,
          })
        ),
      }),
    }),
  }),
};

Eimp.defaultProps = {
  riskRecord: {},
};
