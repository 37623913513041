import { config } from '@abyss/web/tools/config';
import { Axios } from '@src/context/Api/includes/Axios';
import { logger } from '@src/includes/logger';
import { isEmpty, isString, isUndefined } from 'lodash';

/**
 * GetRiskRecordsDownloadUrl
 *
 * Retrieves a url for the user to export/download/save risk records from the remote API via an authenticated request.
 *
 * @param id
 * @returns {Promise<string>}
 * @constructor
 */
export const GetRiskRecordsDownloadUrl = async (id = '') => {
  try {
    if (isUndefined(id) || isEmpty(id) || !isString(id)) {
      return 'missing ID';
    }

    const requestArgs = {
      baseURL: config('API_URL'),
      headers: {
        'x-api-endpoint': `/action-paths/${id}/extract`,
      },
      method: 'GET',
    };

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse.data;
  } catch (error) {
    logger.error('src/requests/queries/GetRiskRecordsDownloadUrl.js -> GetRiskRecordsDownloadUrl()', false, error);

    throw error;
  }
};
