import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '.eventTimeline': {
    '.abyss-slide-container': {
      '.abyss-slide-content-wrapper': {
        '.eventTimelineFlex': {
          '.abyss-timeline-container': {
            '.abyss-timeline-item': {
              '.abyss-timeline-item-bullet': {
                '.abyss-layout-group': {
                  '.abyss-icon': {
                    backgroundColor: '#ffffff',
                    color: 'var(--abyss-colors-primary1) !important',
                  },
                  backgroundColor: '#ffffff',
                },
                alignItems: 'center',
                backgroundColor: '#ffffff !important',
                zIndex: 1,
              },
              '.abyss-timeline-item-content': {
                '.abyss-text-root': {
                  color: 'var(--abyss-colors-gray9)',
                },
              },
              '.abyss-timeline-item-title': {
                color: 'var(--abyss-colors-gray7)',
                marginBottom: 'calc(var(--abyss-space-lg) * 2.25)',
                marginTop: 'calc(calc(var(--abyss-space-xl) - var(--abyss-space-xs)) * -1)',
              },
              '&::before, &::after': {
                borderColor: 'var(--abyss-colors-primary1) !important',
              },
              width: '20%',
            },
            '&.hide-1': {
              '.abyss-timeline-item:nth-child(1)': {
                '&::after': {
                  display: 'none',
                },
              },
              '.abyss-timeline-item:nth-child(2)': {
                '.abyss-timeline-item-bullet': {
                  display: 'none',
                },
                '&::before, &::after': {
                  display: 'none',
                },
              },
            },
            '&.hide-2': {
              '.abyss-timeline-item:nth-child(2)': {
                '&::after': {
                  display: 'none',
                },
              },
              '.abyss-timeline-item:nth-child(3)': {
                '.abyss-timeline-item-bullet': {
                  display: 'none',
                },
                '&::before, &::after': {
                  display: 'none',
                },
              },
            },
            '&.hide-3': {
              '.abyss-timeline-item:nth-child(3)': {
                '&::after': {
                  display: 'none',
                },
              },
              '.abyss-timeline-item:nth-child(4)': {
                '.abyss-timeline-item-bullet': {
                  display: 'none',
                },
                '&::before, &::after': {
                  display: 'none',
                },
              },
            },
            '&.hide-4': {
              '.abyss-timeline-item:nth-child(4)': {
                '&::after': {
                  display: 'none',
                },
              },
              '.abyss-timeline-item:nth-child(5)': {
                '.abyss-timeline-item-bullet': {
                  display: 'none',
                },
                '&::before, &::after': {
                  display: 'none',
                },
              },
            },
            flexGrow: 1,
            justifyContent: 'space-evenly',
            marginTop: 'var(--abyss-space-xl)',
            position: 'relative',
            width: '100%',
          },
          '.abyss-timeline-root': {
            display: 'flex',
            paddingBottom: 'var(--abyss-space-sm)',
            paddingTop: 'var(--abyss-space-sm)',
            position: 'relative',
            width: '100%',
          },
          marginLeft: 'calc(calc(var(--abyss-space-xl) *2) * -1)',
          marginRight: 'calc(calc(var(--abyss-space-xl) *2) * -1)',
          maxHeight: 'calc(var(--abyss-space-xl) * 5)',
          minHeight: 'calc(var(--abyss-space-xl) * 4)',
          position: 'relative',
        },
        padding: 0,
        position: 'relative',
        width: '100%',
      },
      backgroundColor: 'transparent',
      position: 'relative',
      width: '100%',
    },
    position: 'relative',
    width: '100%',
  },
});
