import { config } from '@abyss/web/tools/config';
import { Axios } from '@src/context/Api/includes/Axios';
import { logger } from '@src/includes/logger';
import { isUndefined } from 'lodash';

/**
 * GetValidCriteriaName
 *
 * Verifies whether the common criteria name exists or not from the remote API via an authenticated request.
 *
 * @param payload
 * @returns {Promise<any>}
 * @constructor
 */
export const GetValidCriteriaName = async (payload = {}) => {
  try {
    const theQueryKey = payload[0];
    const thePayload = payload[1];

    const requestArgs = {
      baseURL: config('API_URL'),
      headers: {
        'x-api-endpoint': `/criteria/validate-unique`,
      },
      method: 'GET',
      params: {
        queryKey: theQueryKey,
      },
    };

    if (!isUndefined(thePayload?.name)) {
      requestArgs.headers['x-api-endpoint'] += `/${thePayload?.name}`;
    }

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse?.data;
  } catch (error) {
    logger.error('src/requests/queries/GetValidCriteriaName.js -> GetValidCriteriaName()', false, error);

    throw error;
  }
};
