import { LineChart } from '@src/components/Chart/components/LineChart';
import React from 'react';

import { CandlestickChart } from '../components/CandlestickChart';
import { DataTable } from '../components/DataTable';
import { DonutChart } from '../components/DonutChart';
import { HorizontalBarChart } from '../components/HorizontalBarChart';
import { PieChart } from '../components/PieChart';
import { PolarAreaChart } from '../components/PolarAreaChart';
import { ScatterChart } from '../components/ScatterChart';
import { VerticalBarChart } from '../components/VerticalBarChart';

export const chartTypes = {
  AREA_CHART: {
    component: React.Fragment,
    key: 'Area',
    label: 'Area Chart',
  },
  BUBBLE_CHART: {
    component: React.Fragment,
    key: 'Bubble',
    label: 'Bubble Chart',
  },
  CANDLESTICK_CHART: {
    component: CandlestickChart,
    key: 'Candlestick',
    label: 'Candlestick Chart',
  },
  DATA_TABLE: {
    component: DataTable,
    key: 'DataTable',
    label: 'Data Table',
  },
  DOUGHNUT_CHART: {
    component: DonutChart,
    key: 'Donut',
    label: 'Donut Chart',
  },
  GROUPED_BAR_CHART: {
    component: React.Fragment,
    key: 'GroupedBar',
    label: 'Grouped Bar Chart',
  },
  HORIZONTAL_BAR_CHART: {
    component: HorizontalBarChart,
    key: 'HorizontalBar',
    label: 'Horizontal Bar Chart',
  },
  LINE_CHART: {
    component: LineChart,
    key: 'Line',
    label: 'Line Chart',
  },
  MULTIAXIS_LINE_CHART: {
    component: React.Fragment,
    key: 'MultiAxisLine',
    label: 'Multi Axis Line Chart',
  },
  PIE_CHART: {
    component: PieChart,
    key: 'Pie',
    label: 'Pie Chart',
  },
  POLAR_AREA_CHART: {
    component: PolarAreaChart,
    key: 'PolarArea',
    label: 'Polar Area Chart',
  },
  RADAR_CHART: {
    component: React.Fragment,
    key: 'Radar',
    label: 'Radar Chart',
  },
  SCATTER_CHART: {
    component: ScatterChart,
    key: 'Scatter',
    label: 'Scatter Chart',
  },
  STACKED_BAR_CHART: {
    component: React.Fragment,
    key: 'StackedBar',
    label: 'Stacked Bar Chart',
  },
  VERTICAL_BAR_CHART: {
    component: VerticalBarChart,
    key: 'VerticalBar',
    label: 'Vertical Bar Chart',
  },
};
