import { ErrorHandler } from '@src/components/ErrorHandler';
import { Widget } from '@src/components/Widget';
import PropTypes from 'prop-types';
import React from 'react';

import { HorizontalBarChart } from './components/HorizontalBarChart';
import { Styles } from './includes/styles';

/**
 * Widget: RiskCodes
 *
 * Displays the count of enterprise IDs associated with IRE risk codes.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const RiskCodes = (props) => {
  const { data, redRiskCodes, riskCodes } = props;

  return (
    <ErrorHandler location="src/routes/private/Dashboards/screens/Risk/components/widgets/RiskCodes/RiskCodes.jsx">
      <Styles>
        <Widget description="Count of Enterprise Ids associated with IRE risk code" title="Risk Codes">
          <HorizontalBarChart data={data} redRiskCodes={redRiskCodes} riskCodes={riskCodes} />
        </Widget>
      </Styles>
    </ErrorHandler>
  );
};

RiskCodes.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      countTotal: PropTypes.number,
      riskCode: PropTypes.string,
    })
  ),
  redRiskCodes: PropTypes.arrayOf(PropTypes.string),
  riskCodes: PropTypes.arrayOf(
    PropTypes.shape({
      codeDesc: PropTypes.string,
      codeId: PropTypes.string,
    })
  ),
};

RiskCodes.defaultProps = {
  data: [],
  redRiskCodes: [],
  riskCodes: [],
};
