import { config } from '@abyss/web/tools/config';
import { Axios } from '@src/context/Api/includes/Axios';
import { logger } from '@src/includes/logger';
import { isEmpty, isUndefined } from 'lodash';

/**
 * ListEvents
 *
 * Retrieves a list of events from the remote API via an authenticated request.
 *
 * @param payload
 * @returns {Promise<*>}
 * @constructor
 */
export const ListEvents = async (payload = {}) => {
  try {
    const requestArgs = {
      baseURL: config('API_URL'),
      headers: {
        'x-api-endpoint': '/events/search',
      },
      method: 'POST',
      params: {},
    };

    const thePayload = { ...payload?.[1] };

    if (!isUndefined(payload?.[0])) {
      requestArgs.params = { ...requestArgs.params, ...{ queryKey: payload[0] } };
    }

    if (!isUndefined(payload?.[1]?.page)) {
      requestArgs.params = { ...requestArgs.params, ...{ page: payload?.[1]?.page } };
      delete thePayload?.page;
    }

    if (!isUndefined(payload?.[1]?.size)) {
      requestArgs.params = { ...requestArgs.params, ...{ size: payload?.[1]?.size } };
      delete thePayload?.size;
    }

    if (!isUndefined(payload?.[1]?.sort)) {
      requestArgs.params = { ...requestArgs.params, ...{ sort: payload?.[1]?.sort } };
      delete thePayload?.sort;
    }

    if (!isEmpty(thePayload)) {
      requestArgs.data = thePayload;
    }

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse.data;
  } catch (error) {
    logger.error('src/requests/queries/ListEvents.js -> ListEvents()', false, error);

    throw error;
  }
};
