import { isEmpty, isEqual, isUndefined } from 'lodash';
import { useMemo, useState } from 'react';

export const useSelectedCommonCriteria = (context = '') => {
  const [selected, setSelected] = useState({
    commonCriteriaVersionsIds: [],
    commonIds: [],
  });

  const handleSelected = (selectedRows = []) => {
    const criteriaIds = [];
    const versionIds = [];

    if (!isEmpty(selectedRows)) {
      selectedRows?.forEach((selectedRow) => {
        criteriaIds.push(selectedRow?.original?.id);

        let versionId = selectedRow?.original?.activeCommonCriteriaVersion?.id;

        if (!isUndefined(selectedRow?.original?.actionDetails?.from?.id)) {
          versionId = selectedRow?.original?.actionDetails?.from?.id;
        }

        versionIds.push(versionId);
      });
    }

    const data = {
      commonCriteriaVersionsIds: versionIds,
      commonIds: criteriaIds,
    };

    if (!isEqual(selected, data)) {
      setSelected(data);
    }
  };

  return useMemo(() => {
    return {
      context,
      handleSelected,
      selected,
    };
  }, [selected]);
};
