import { useToast } from '@abyss/web/hooks/useToast';
import { useApi } from '@src/context/Api';
import { useCallback } from 'react';

import reports from './includes/reports.json';

/**
 * Hook: useSave
 *
 * Makes an API request to save the action path.
 *
 * @param reportId
 * @returns {{saveEvent: *, handleSave: (function({}=, function()=): Promise<*>)}}
 */
export const useSave = (reportId = null) => {
  const currentReport = reports?.[reportId];

  const { clearApiCache, useApiMutation } = useApi();
  const [SaveAlertConfiguration, mutation] = useApiMutation('SaveAlertConfiguration');

  const { toast } = useToast();

  const handleSave = useCallback(
    async (variables = {}, refetch = () => {}) => {
      const toastId = `admin-alert-config-${reportId}`;

      toast.show({
        autoClose: false,
        id: `${toastId}-info`,
        isLoading: true,
        message: currentReport?.info?.message,
        title: currentReport?.info?.title,
        variant: 'info',
      });

      const mutations = [];

      variables?.[reportId]?.forEach((payload = {}) => {
        mutations.push(
          SaveAlertConfiguration(payload, {
            onError: () => {
              toast.hide(`${toastId}-info`);
              toast.show({
                id: `${toastId}-error`,
                message: currentReport?.error?.message,
                title: currentReport?.error?.title,
                variant: 'error',
              });
            },
            onSuccess: () => {
              toast.hide(`${toastId}-info`);

              toast.show({
                id: `${toastId}-success`,
                message: currentReport?.success?.message,
                title: currentReport?.success?.title,
                variant: 'success',
              });

              clearApiCache(['ListAlertConfigurations']);

              refetch();
            },
          })
        );
      });

      await Promise.all(mutations);
    },
    [currentReport, reportId]
  );

  return { handleSave, saveEvent: mutation };
};
