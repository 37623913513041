import { useRouter } from '@abyss/web/hooks/useRouter';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Link } from '@abyss/web/ui/Link';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

/**
 * Button: AnalyzeRisks
 *
 * Button which when clicked will open the Risk Analysis screen with the supplied entrance criteria.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const AnalyzeRisks = (props) => {
  const { actionPath, criteria, variant } = props;

  const router = useRouter();

  const [theCriteria, setTheCriteria] = useState(criteria);

  /**
   * set filters from action path
   */
  useEffect(() => {
    if (!isUndefined(actionPath)) {
      if (actionPath?.manualAssociation === true) {
        setTheCriteria({
          entrance: {
            additional: [
              {
                column: 'ACTION_PATH_ID',
                condition: 'ANY',
                value: [actionPath?.id],
              },
            ],
          },
        });
      }

      if (actionPath?.manualAssociation === false) {
        setTheCriteria(actionPath?.criteria);
      }
    }
  }, [actionPath]);

  return (
    <ErrorHandler location="src/features/Risk/components/buttons/AnalyzeRisks/AnalyzeRisks.jsx">
      {variant === 'button' && (
        <Button
          before={<IconSymbol icon="bar_chart" variant="outlined" />}
          onClick={async () => {
            const encoded = Buffer.from(JSON.stringify({ criteria: theCriteria })).toString('base64');
            router?.navigate(`/analysis/risk-analysis/${encoded}`);
          }}
          variant="solid"
        >
          Analyze Risks
        </Button>
      )}
      {variant === 'link' && (
        <Link
          before={<IconSymbol icon="bar_chart" variant="outlined" />}
          href="/analysis/risk-analysis"
          onClick={async (event) => {
            event?.preventDefault();
            const encoded = Buffer.from(JSON.stringify({ criteria: theCriteria })).toString('base64');
            router?.navigate(`/analysis/risk-analysis/${encoded}`);
          }}
        >
          Analyze Risks
        </Link>
      )}
    </ErrorHandler>
  );
};

AnalyzeRisks.propTypes = {
  actionPath: PropTypes.shape({
    criteria: PropTypes.shape({
      entrance: PropTypes.shape({
        additional: PropTypes.arrayOf(
          PropTypes.shape({
            column: PropTypes.string,
            condition: PropTypes.string,
            value: PropTypes.string,
          })
        ),
      }),
    }),
    id: PropTypes.string,
    manualAssociation: PropTypes.bool,
  }),
  criteria: PropTypes.shape({
    entrance: PropTypes.shape({
      additional: PropTypes.arrayOf(
        PropTypes.shape({
          column: PropTypes.string,
          condition: PropTypes.string,
          value: PropTypes.string,
        })
      ),
    }),
  }),
  variant: PropTypes.oneOf(['button', 'link']),
};

AnalyzeRisks.defaultProps = {
  actionPath: {},
  criteria: {},
  variant: 'button',
};
