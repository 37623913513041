import { SelectInputMulti } from '@abyss/web/ui/SelectInputMulti';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { useApi } from '@src/context/Api';
import { isEmpty, isFunction, isUndefined } from 'lodash';
import { PropTypes } from 'prop-types';
import React, { useEffect, useMemo } from 'react';

import configuration from './includes/configuration.json';

/**
 * Field: TagSelection
 *
 * This field allows the user to select tags from a list of available system defined or user defined tags.
 *
 * @returns {Element}
 * @constructor
 */
export const TagSelection = (props) => {
  const {
    form,
    handleChange,
    isDisabled,
    isRequired,
    label,
    model,
    showLabel,
    showSearch,
    showSelectAll,
    subText,
    systemDefinedTags,
    userDefinedTags,
    validators,
  } = props;

  const { useApiQuery } = useApi();
  const [ListTags, { data: tags, isFetching, isLoading }] = useApiQuery('ListTags');

  /**
   * Retrieve the list of tags.
   */
  useEffect(() => {
    if (isUndefined(tags)) {
      const payload = {
        page: 0,
        size: 9999,
        sort: 'code,asc',
        userDefined: null,
      };

      if (systemDefinedTags === false && userDefinedTags === true) {
        payload.userDefined = true;
      }

      if (systemDefinedTags === true || userDefinedTags === true) {
        ListTags(payload);
      }
    }
  }, [systemDefinedTags, userDefinedTags, tags]);

  /**
   * tagOptions
   *
   * Returns the tag options with categories for sections.
   *
   * @type {*[]}
   */
  const tagOptions = useMemo(() => {
    const theTags = [];

    if (!isUndefined(tags) && !isEmpty(tags)) {
      // tag category sections
      tags?.content?.forEach((tag) => {
        const theSection = theTags.find((theTag) => {
          return theTag?.section === tag?.categoryCode;
        });

        if (isUndefined(theSection)) {
          theTags.push({
            items: [],
            section: tag?.categoryCode,
          });
        }
      });

      // tag options within sections
      tags?.content?.forEach((tag) => {
        const theSection = theTags.find((theTag) => {
          return theTag?.section === tag?.categoryCode;
        });

        if (!isUndefined(theSection)) {
          theSection?.items.push({
            label: `${tag?.code} (${tag?.categoryCode})`,
            searchAttributes: {
              categoryCode: tag?.categoryCode,
              categoryDescription: tag?.categoryDesc,
              tagCode: tag?.code,
              tagDescription: tag?.description,
            },
            value: tag?.tag,
          });
        }
      });
    }

    return theTags;
  }, [tags]);

  /**
   * fieldProps
   *
   * the props to pass to the SelectInputMulti component
   *
   * @type {{criteria: {}, hideLabel: boolean, isSearchable: boolean, keys: {}, label: string, maxListHeight: string,
   *   model: string, options: {}, placeholder: string, selectAll: boolean, subText: string, validators: {required:
   *   string}}&{isLoading: *, onChange: theProps.onChange, options: *[], isDisabled}}
   */
  const fieldProps = useMemo(() => {
    const theProps = {
      ...configuration,
      isDisabled,
      isLoading: isLoading || isFetching,
      onChange: (values) => {
        if (isFunction(handleChange)) {
          handleChange(values);
        }

        form?.validate(
          model,
          () => {},
          () => {}
        );
      },
      options: tagOptions,
    };

    if (!isEmpty(validators)) {
      theProps.validators = validators;
    }

    if (label !== configuration?.label) {
      theProps.label = label;
    }

    if (subText !== configuration?.subText) {
      theProps.subText = subText;
    }

    if (!showLabel) {
      theProps.hideLabel = true;
    }

    if (!showSearch) {
      theProps.isSearchable = false;
    }

    if (!showSelectAll) {
      theProps.selectAll = false;
    }

    if (!isRequired) {
      theProps.validators = {
        required: false,
      };
    }

    if (!isEmpty(model) && model !== configuration?.model) {
      theProps.model = model;
    }

    return theProps;
  }, [
    configuration,
    handleChange,
    isDisabled,
    isFetching,
    isLoading,
    isRequired,
    label,
    showLabel,
    showSearch,
    showSelectAll,
    subText,
    tagOptions,
    validators,
  ]);

  return (
    <ErrorHandler location="src/common/fields/TagSelection/TagSelection.jsx">
      <SelectInputMulti {...fieldProps} />
    </ErrorHandler>
  );
};

TagSelection.propTypes = {
  form: PropTypes.shape({
    validate: PropTypes.func,
  }),
  handleChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  model: PropTypes.string,
  showLabel: PropTypes.bool,
  showSearch: PropTypes.bool,
  showSelectAll: PropTypes.bool,
  subText: PropTypes.string,
  systemDefinedTags: PropTypes.bool,
  userDefinedTags: PropTypes.bool,
  validators: PropTypes.shape({}),
};

TagSelection.defaultProps = {
  form: {},
  handleChange: () => {},
  isDisabled: false,
  isRequired: true,
  label: configuration?.label,
  model: configuration?.model,
  showLabel: true,
  showSearch: true,
  showSelectAll: true,
  subText: configuration?.subText,
  systemDefinedTags: true,
  userDefinedTags: true,
  validators: {},
};
