import { useToast } from '@abyss/web/hooks/useToast';
import { useApi } from '@src/context/Api';
import { useCallback } from 'react';

/**
 * Hook: useDeletion
 *
 * Makes an API request to delete an action path.
 *
 * @returns {{handleDeletion: (function({}=, function()=): Promise<*>), deletionEvent: *}}
 */
export const useDeletion = () => {
  const { clearApiCache, useApiMutation } = useApi();
  const [DeleteActionPath, mutation] = useApiMutation('DeleteActionPath');

  const { toast } = useToast();

  const handleDeletion = useCallback(async (variables = {}, refetch = () => {}) => {
    const toastId = 'action-paths-deletion';

    toast.show({
      autoClose: false,
      id: `${toastId}-info`,
      isLoading: true,
      message: 'Preparing to activate the Action Path.',
      title: 'Deleting Action Path',
      variant: 'info',
    });

    const payload = {
      ...variables,
    };

    return await DeleteActionPath(payload, {
      onError: () => {
        toast.hide(`${toastId}-info`);
        toast.show({
          id: `${toastId}-error`,
          message: 'An error occurred while deleting the Action Path.',
          title: 'Error Deleting Action Path',
          variant: 'error',
        });
      },
      onSuccess: () => {
        toast.hide(`${toastId}-info`);

        toast.show({
          id: `${toastId}-success`,
          message: 'Successfully deleted the Action Path.',
          title: 'Deleted Action Path',
          variant: 'success',
        });

        clearApiCache([
          'ListActionPaths',
          'ListCommonCriteria',
          'GetActionPath',
          'GetCommonCriteria',
          'GetCommonCriteriaVersion',
        ]);

        refetch();
      },
    });
  }, []);

  return { deletionEvent: mutation, handleDeletion };
};
