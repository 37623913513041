import { Flex } from '@abyss/web/ui/Flex';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { Details } from './components/Details';
import { Form } from './components/Form';
import { HeaderSkeleton } from './components/Skeleton';

export const Header = (props) => {
  const { assets, eid, handleSearch, hasResults, isLoading, isSearching, riskRecord } = props;

  return (
    <ErrorHandler location="src/routes/private/Analysis/screens/EidSearch/components/Layout/Header/Header.jsx">
      <Flex>
        <div style={{ width: '240px' }}>
          <Heading offset={0}>EID Search</Heading>
          <Form isLoading={isLoading} isSearching={isSearching} />
        </div>
        {!isLoading && !isEmpty(eid) && (
          <div style={{ width: 'calc(100% - 240px)' }}>
            {isSearching ? (
              <HeaderSkeleton />
            ) : (
              <React.Fragment>
                {hasResults && (
                  <Grid>
                    <Grid.Col
                      span={{
                        xs: '100%',
                      }}
                    >
                      <Details
                        actionPaths={riskRecord?.recordActionPaths}
                        associatedEids={riskRecord?.associatedEID}
                        eid={riskRecord?.eid}
                        handleSearch={handleSearch}
                        ireRiskRecord={riskRecord?.ireRiskRecord}
                        riskCodes={assets?.ListRiskCodes?.data}
                        riskRecord={riskRecord}
                        tags={riskRecord?.tags}
                      />
                    </Grid.Col>
                  </Grid>
                )}
              </React.Fragment>
            )}
          </div>
        )}
      </Flex>
    </ErrorHandler>
  );
};

Header.propTypes = {
  assets: PropTypes.shape({
    ListRiskCodes: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          code: PropTypes.string,
          description: PropTypes.string,
          riskScore: PropTypes.number,
        })
      ),
    }),
  }),
  eid: PropTypes.string,
  handleSearch: PropTypes.func,
  hasResults: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSearching: PropTypes.bool,
  riskRecord: PropTypes.shape({
    associatedEID: PropTypes.arrayOf(PropTypes.string),
    eid: PropTypes.string,
    ireRiskRecord: PropTypes.shape({
      riskCode: PropTypes.string,
      riskDescription: PropTypes.string,
      riskScore: PropTypes.string,
      riskStatus: PropTypes.string,
      riskStatusDate: PropTypes.string,
      riskStatusDescription: PropTypes.string,
      riskStatusReason: PropTypes.string,
    }),
    recordActionPaths: PropTypes.arrayOf(
      PropTypes.shape({
        actionPath: PropTypes.string,
        actionPathStatus: PropTypes.string,
        actionPathStatusDate: PropTypes.string,
        actionPathStatusDescription: PropTypes.string,
        actionPathStatusReason: PropTypes.string,
        actionPathType: PropTypes.string,
      })
    ),
    tags: PropTypes.arrayOf(PropTypes.string),
  }),
};

Header.defaultProps = {
  assets: {},
  eid: '',
  handleSearch: () => {},
  hasResults: false,
  isLoading: false,
  isSearching: false,
  riskRecord: {},
};
