import { useRouter } from '@abyss/web/hooks/useRouter';
import { DropdownMenu } from '@abyss/web/ui/DropdownMenu';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { ErrorHandler } from '@src/components/ErrorHandler';
import React from 'react';

/**
 * CreateMenu
 *
 * Displays a dropdown menu with options to create an action path with entrance criteria or manually.
 *
 * @returns {Element}
 * @constructor
 */
export const CreateMenu = () => {
  const router = useRouter();

  return (
    <ErrorHandler location="src/features/ActionPaths/components/buttons/CreateMenu/CreateMenu.jsx">
      <DropdownMenu
        after={<IconSymbol icon="keyboard_arrow_down" />}
        className="no-padding no-margin"
        label="Create"
        menuItems={[
          {
            onClick: async () => {
              router?.navigate(`/action-paths/0/create/automatic/draft/step/1`);
            },
            title: 'Create with Entrance Criteria',
          },
          {
            onClick: async () => {
              router?.navigate(`/action-paths/0/create/manual/draft/step/1`);
            },
            title: 'Create Manually',
          },
        ]}
        outline
      />
    </ErrorHandler>
  );
};
