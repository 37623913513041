import { useForm } from '@abyss/web/hooks/useForm';
import { dayjs } from '@abyss/web/tools/dayjs';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Layout } from '@abyss/web/ui/Layout';
import { Button } from '@src/components/Button';
import { DateRange } from '@src/components/DateRange';
import { ErrorHandler } from '@src/components/ErrorHandler';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { Styles } from './includes/styles';

/**
 * Filters
 *
 * Displays a form to filter the data displayed in the table.
 *
 * @returns {Element}
 * @constructor
 */
export const Filters = (props) => {
  const { requestArgs, setRequestArgs } = props;

  const defaultValues = {
    dateRange: {
      end: dayjs(new Date()).add(6, 'months').format('MM/DD/YYYY'),
      start: dayjs(new Date()).subtract(6, 'months').format('MM/DD/YYYY'),
    },
  };

  const form = useForm({ defaultValues });
  const { isSubmitting, isValid } = form.formState;

  /**
   * handleSubmit
   *
   * Retrieves the filtered data from the remote API.
   *
   * @returns {Promise<void>}
   */
  const handleSubmit = useCallback(
    (submittedValues) => {
      if (!isSubmitting && isValid) {
        setRequestArgs({
          ...requestArgs,
          ...{
            endDate: dayjs(submittedValues?.dateRange?.end).format('YYYY-MM-DD'),
            startDate: dayjs(submittedValues?.dateRange?.start).format('YYYY-MM-DD'),
          },
        });
      }
    },
    [isSubmitting, isValid, requestArgs]
  );

  return (
    <ErrorHandler location="src/routes/private/Notifications/screens/Events/List/components/Table/components/Filters/Filters.jsx">
      <Styles>
        <FormProvider autoComplete="off" highlighted onSubmit={handleSubmit} state={form}>
          <Layout.Group alignItems="bottom" alignLayout="left">
            <div>
              <DateRange
                defaultEndDate={form?.getValues('dateRange.end')}
                defaultStartDate={form?.getValues('dateRange.start')}
                isClearable={false}
                isRequired={false}
                maximumDate={dayjs(new Date()).format('MM/DD/YYYY')}
                minimumDate={dayjs(new Date()).format('MM/DD/YYYY')}
                onChange={(event = {}) => {
                  form?.setValue('dateRange.start', String(event?.startDate), {
                    shouldDirty: true,
                    shouldValidate: true,
                  });

                  form?.setValue('dateRange.end', String(event?.endDate), {
                    shouldDirty: true,
                    shouldValidate: true,
                  });

                  form?.validate(
                    `dateRange.start`,
                    () => {},
                    () => {}
                  );

                  form?.validate(
                    `dateRange.end`,
                    () => {},
                    () => {}
                  );
                }}
              />
            </div>
            <div>
              <Button type="submit" variant="solid">
                Apply
              </Button>
            </div>
          </Layout.Group>
        </FormProvider>
      </Styles>
    </ErrorHandler>
  );
};

Filters.propTypes = {
  requestArgs: PropTypes.shape({
    endDate: PropTypes.string,
    startDate: PropTypes.string,
  }),
  setRequestArgs: PropTypes.func,
};

Filters.defaultProps = {
  requestArgs: {},
  setRequestArgs: () => {},
};
