import { Divider } from '@abyss/web/ui/Divider';
import { Layout } from '@abyss/web/ui/Layout';
import { Modal } from '@abyss/web/ui/Modal';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { isEqual, isNull } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { useSave } from '../../../hooks/useSave';

/**
 * Modal: Activation
 *
 * This modal is used to activate the selected version of the entity.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Activation = (props) => {
  const {
    activatedVersion,
    commonCriteriaVersions,
    currentEntity,
    formValues,
    isOpen,
    refetch,
    selectedVersionValue,
    setFocusedEntity,
    setIsOpen,
  } = props;

  const handleActivation = useSave('activate');
  const handleSave = useSave('update');
  const handleCreateVersion = useSave('createVersion');

  /**
   * handleClose
   *
   * Handles the closing of the modal.
   *
   * @returns {Promise<void>}
   */
  const handleClose = () => {
    setIsOpen(false);
  };

  const inactiveToActive =
    isEqual(currentEntity?.activeCommonCriteriaVersion?.criteria, formValues?.filters) ||
    (isNull(currentEntity?.activeCommonCriteriaVersion) && currentEntity?.activeVersionNbr === -1);
  const versionChange = activatedVersion?.version !== selectedVersionValue;
  let content;

  if (inactiveToActive) {
    content = (
      <React.Fragment>
        <div>
          Are you sure you want to activate: <strong>{currentEntity?.name}</strong>
        </div>
      </React.Fragment>
    );
  } else if (versionChange) {
    content = (
      <React.Fragment>
        <div>
          Do you want to activate{' '}
          <strong>
            {currentEntity?.name} v{selectedVersionValue}
          </strong>
          ?
        </div>
        <div style={{ marginTop: '4px' }}>
          This will deactivate v{activatedVersion.version} and make v{selectedVersionValue} the current active version.
        </div>
      </React.Fragment>
    );
  } else {
    content = (
      <React.Fragment>
        <div>
          You made updates to <strong>{currentEntity?.name}</strong>. Do you want to activate a new version v
          {commonCriteriaVersions}{' '}
        </div>
      </React.Fragment>
    );
  }

  return (
    <ErrorHandler location="src/routes/private/Admin/screens/CommonCriteria/components/modals/Activation/Activation.jsx">
      <Modal isOpen={isOpen} onClose={handleClose} title="Activate Common Criteria">
        <Modal.Section>{content}</Modal.Section>
        <Modal.Section>
          <Divider height={1} />
          <Layout.Group alignLayout="right">
            <Button onClick={handleClose} variant="outline">
              Cancel
            </Button>
            <Button
              onClick={async () => {
                if (inactiveToActive) {
                  await handleActivation(
                    {
                      ...currentEntity,
                      ...formValues,
                      ...{
                        activeVersionNbr: formValues?.version,
                        isActive: true,
                      },
                    },
                    refetch
                  );
                } else if (versionChange) {
                  await handleSave({ ...currentEntity, ...formValues }, refetch);
                } else {
                  await handleCreateVersion({ ...currentEntity, ...formValues }, refetch);
                }
                setFocusedEntity(currentEntity?.name);
              }}
              variant="solid"
            >
              Activate
            </Button>
          </Layout.Group>
        </Modal.Section>
      </Modal>
    </ErrorHandler>
  );
};

Activation.propTypes = {
  activatedVersion: PropTypes.shape({
    version: PropTypes.number,
  }),
  commonCriteriaVersions: PropTypes.number,
  currentEntity: PropTypes.shape({
    activeCommonCriteriaVersion: PropTypes.shape({
      criteria: PropTypes.shape({
        filters: PropTypes.shape({
          entrance: PropTypes.shape({
            common: PropTypes.shape({
              ids: PropTypes.arrayOf(PropTypes.string),
            }),
          }),
          exit: PropTypes.shape({
            common: PropTypes.shape({
              ids: PropTypes.arrayOf(PropTypes.string),
            }),
          }),
        }),
      }),
    }),
    activeVersionNbr: PropTypes.number,
    name: PropTypes.string,
  }),
  formValues: PropTypes.shape({
    filters: PropTypes.shape({
      entrance: PropTypes.shape({
        common: PropTypes.shape({
          ids: PropTypes.arrayOf(PropTypes.string),
        }),
      }),
      exit: PropTypes.shape({
        common: PropTypes.shape({
          ids: PropTypes.arrayOf(PropTypes.string),
        }),
      }),
    }),
    version: PropTypes.number,
  }),
  isOpen: PropTypes.bool,
  refetch: PropTypes.func,
  selectedVersionValue: PropTypes.number,
  setFocusedEntity: PropTypes.func,
  setIsOpen: PropTypes.func,
};

Activation.defaultProps = {
  activatedVersion: {},
  commonCriteriaVersions: 0,
  currentEntity: {},
  formValues: {},
  isOpen: false,
  refetch: () => {},
  selectedVersionValue: 0,
  setFocusedEntity: () => {},
  setIsOpen: () => {},
};
