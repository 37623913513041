import { dayjs } from '@abyss/web/tools/dayjs';
import { Grid } from '@abyss/web/ui/Grid';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Widget } from '@src/components/Widget';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { AddModal } from './components/modals/Add';
import { RemoveModal } from './components/modals/Remove';

/**
 * Widget: Notes
 *
 * Allows adding/removing EID's to/from an active/manual action path.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Notes = (props) => {
  const { actionPath } = props;

  const [showAddModal, setShowAddModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  return (
    <ErrorHandler location="src/features/ActionPaths/components/widgets/Notes/Notes.jsx">
      <Widget
        description={`Last updated by: ${actionPath?.lastModifiedBy} on ${dayjs(actionPath?.lastModifiedDate).format(
          'MM/DD/YYYY'
        )}`}
        title="Notes"
      >
        <Grid>
          <Grid.Col span={{ xs: '100%' }}>
            <pre
              style={{
                fontFamily: 'var(--abyss-fonts-primary)',
                whiteSpace: 'pre-wrap',
                wordWrap: 'break-word',
              }}
            >
              {actionPath?.note}
            </pre>
          </Grid.Col>
          <Grid.Col span={{ xs: '50%' }}>
            <Button
              after={<IconSymbol icon="edit" size="20" variant="outlined" />}
              onClick={() => {
                return setShowAddModal(true);
              }}
              variant="solid"
            >
              Edit Note
            </Button>
          </Grid.Col>
          <Grid.Col css={{ textAlign: 'right' }} span={{ xs: '50%' }}>
            <Button
              after={<IconSymbol icon="delete" size="20" variant="outlined" />}
              onClick={() => {
                return setShowRemoveModal(true);
              }}
              variant="destructive"
            >
              Delete Note
            </Button>
          </Grid.Col>
        </Grid>
      </Widget>
      <AddModal actionPath={actionPath} isOpen={showAddModal} setIsOpen={setShowAddModal} />
      <RemoveModal actionPath={actionPath} isOpen={showRemoveModal} setIsOpen={setShowRemoveModal} />
    </ErrorHandler>
  );
};

Notes.propTypes = {
  actionPath: PropTypes.shape({
    id: PropTypes.string,
    lastModifiedBy: PropTypes.string,
    lastModifiedDate: PropTypes.string,
    note: PropTypes.string,
  }),
};

Notes.defaultProps = {
  actionPath: {},
};
