import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '.abyss-action-nav-root': {
    '.abyss-action-nav-header': {
      '.abyss-action-nav-header-card': {
        '.abyss-action-nav-text': {
          flexGrow: 1,
          maxWidth: '100%',
        },
        flexGrow: 1,
        justifyContent: 'flex-start',
      },
      flexGrow: 0,
      maxWidth: '25%',
      width: '100%',
    },
    '.abyss-action-nav-list': {
      '.abyss-action-nav-list-item': {
        '.abyss-action-nav-chevron-after': {
          '&:before': {
            borderLeftColor: 'var(--abyss-colors-gray4)',
            left: 1,
          },
        },
        '.abyss-action-nav-header-text': {
          '.description': {
            color: 'var(--abyss-colors-gray8)',
            fontSize: '12px',
            fontWeight: 'var(--abyss-fontWeights-normal)',
            lineHeight: '16px',
            marginBottom: '0px',
          },
          '.title': {
            fontFamily: 'var(--abyss-fonts-primary)',
            fontSize: 'var(--abyss-fontSizes-lg)',
            fontWeight: 'var(--abyss-fontWeights-bold)',
          },
        },
        '.abyss-action-nav-link-container:before': {
          border: 'none',
        },

        '.abyss-icon:last-child': {
          display: 'none',
        },

        '&.current, &.completed': {
          '.abyss-action-nav-chevron-after': {
            '&:before': {
              left: 'auto',
            },
            '&:before, &:after': {
              borderLeftColor: 'var(--abyss-colors-interactive1)',
            },
            borderColor: 'var(--abyss-colors-interactive1)',
          },
          '.abyss-action-nav-chevron-before': {
            '&:before, &:after': {
              borderBottomColor: 'var(--abyss-colors-interactive1)',
              borderRightColor: 'var(--abyss-colors-interactive1)',
              borderTopColor: 'var(--abyss-colors-interactive1)',
            },
            borderColor: 'transparent',
          },
          '&.current': {
            '.abyss-action-nav-link-container': {
              cursor: 'auto !important',
            },
          },
          '&.disabled': {
            '.abyss-action-nav-link-container': {
              cursor: 'not-allowed !important',
              pointerEvents: 'all !important',
            },
          },
          borderColor: 'transparent',
          'div:nth-of-type(2)': {
            '.abyss-action-nav-link-container': {
              '.abyss-action-nav-link-wrapper': {
                '.abyss-indicator-container': {
                  backgroundColor: 'transparent',
                  borderColor: '#ffffff !important',
                },
                color: 'white',
              },
              '.abyss-icon': {
                color: 'white',
              },
              '&:before': {
                border: 'none',
              },
            },
            backgroundColor: 'var(--abyss-colors-interactive1)',
            border: '1px solid var(--abyss-colors-interactive1)',
          },
        },
        'div:nth-of-type(2)': {
          '.abyss-action-nav-link-container': {
            '.abyss-action-nav-link-wrapper': {
              '.abyss-flex-root': {
                '.abyss-indicator-root': {
                  '.abyss-indicator-container': {
                    backgroundColor: 'var(--abyss-colors-interactive1)',
                    borderColor: 'var(--abyss-colors-interactive1)',
                    left: 'unset',
                    marginRight: 'var(--abyss-space-xs)',
                    position: 'relative',
                    top: 'unset',
                    transform: 'unset',
                  },
                  display: 'block',
                },
                flexWrap: 'nowrap',
              },
              margin: 0,
            },
            alignItems: 'center',
            cursor: 'auto !important',
            flexGrow: 1,
            height: '100%',
            justifyContent: 'center',
            margin: '0 calc(var(--abyss-space-lg) * -1)',
            width: '100%',
          },
          height: '100%',
          width: '100%',
        },
      },
      flexGrow: 0,
      flexWrap: 'nowrap',
      maxWidth: '75%',
      width: '100%',
    },
    '.abyss-text-input-root': {
      '.abyss-text-input-descriptors': {
        '&& > span': {
          backgroundColor: 'var(--abyss-colors-error2)',
          border: '1px solid var(--abyss-colors-error1)',
          bottom: 'unset',
          marginRight: 'var(--abyss-space-lg)',
          padding: '4px',
          position: 'absolute',
          top: 'calc(100% - 6px)',
          zIndex: '999',
        },
      },
    },
    flexWrap: 'nowrap',
    width: '100%',
  },
  '.abyss-button-root': {
    '.abyss-insert-element-before, .abyss-insert-element-after': {
      padding: 0,
    },
  },
  '.capitalize': {
    textTransform: 'capitalize',
  },
});
