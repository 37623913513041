import { AbyssTheme as themeConfiguration } from '@src/client';
import { chartColors } from '@src/components/Chart/includes/chartColors';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { abbrNum } from '@src/includes/functions';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LogarithmicScale,
  Title,
  Tooltip,
} from 'chart.js';
import { isEmpty, isUndefined, shuffle } from 'lodash';
import pattern from 'patternomaly';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Bar } from 'react-chartjs-2';

import { getTooltipFooter, getTooltipLabel, getTooltipTitle } from '../../includes/functions';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, LogarithmicScale);

/**
 * HorizontalBarChart
 *
 * A horizontal bar chart is a graphical representation of data using horizontal bars o display the relative sizes or
 * frequencies of different categories or values.
 *
 * @param props
 * @returns {React.JSX.Element|`\nValue: ${string}\nPercentage: ${*}%\n`|*|string}
 * @constructor
 */
export const HorizontalBarChart = (props) => {
  const { data, legendLimit, showLegend } = props;

  const [labels, setLabels] = useState([]);
  const [dataset, setDataset] = useState([]);

  /**
   * Defines an array of colors and patterns to use for rendering the datasets.
   *
   * @type {Array<CanvasPattern>}
   */
  const patterns = useMemo(() => {
    return pattern.generate(
      shuffle(chartColors).map((colorName) => {
        return themeConfiguration?.theme?.colors?.[`${colorName}`];
      })
    );
  }, [themeConfiguration]);

  /**
   * Configure the labels and datasets to be used by the chart.
   */
  useEffect(() => {
    if (!isEmpty(data)) {
      const theLabels = [];
      const theDataset = [];

      data?.forEach((item) => {
        const name = String(item?.name);
        theLabels.push(name);
        theDataset.push(item?.value);
      });

      if (theLabels !== labels) {
        setLabels(theLabels);
      }

      if (theDataset !== dataset) {
        setDataset(theDataset);
      }
    }
  }, [data]);

  return (
    <ErrorHandler location="src/components/Chart/components/HorizontalBarChart/HorizontalBarChart.jsx">
      <Bar
        data={{
          datasets: [
            {
              backgroundColor: patterns,
              borderWidth: 1,
              data: dataset,
              grouped: false,
              label: '',
            },
          ],
          labels,
        }}
        options={{
          animation: {
            duration: 0, // general animation time
          },
          hover: {
            animationDuration: 0, // duration of animations when hovering an item
          },
          indexAxis: 'y',
          plugins: {
            legend: {
              display: isUndefined(showLegend) ? !isUndefined(legendLimit) && labels.length <= legendLimit : true,
            },
            tooltip: {
              callbacks: {
                footer(args) {
                  return getTooltipFooter(args, data);
                },
                label(args) {
                  return getTooltipLabel(args, data);
                },
                title(args) {
                  return getTooltipTitle(args, data);
                },
              },
            },
          },
          responsive: true,
          responsiveAnimationDuration: 0, // animation duration after a resize
          scales: {
            x: {
              ticks: {
                callback(value) {
                  return `${abbrNum(Number(value), 2)}`;
                },
              },
            },
          },
        }}
      />
    </ErrorHandler>
  );
};

HorizontalBarChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  legendLimit: PropTypes.number,
  showLegend: PropTypes.bool,
};

HorizontalBarChart.defaultProps = {
  legendLimit: 10,
  showLegend: true,
};
