import { useToast } from '@abyss/web/hooks/useToast';
import { dayjs } from '@abyss/web/tools/dayjs';
import { Flex } from '@abyss/web/ui/Flex';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Loader } from '@src/components/Loader';
import { useApi } from '@src/context/Api';
import { motion } from 'framer-motion';
import { isEmpty, isUndefined } from 'lodash';
import React, { useEffect, useState } from 'react';

import { FormModal } from './components/FormModal';
import { Table } from './components/Table';

/**
 * List
 *
 * Provides the user with a screen listing the existing events.
 *
 * @returns {Element}
 * @constructor
 */
export const List = () => {
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [currentEvent, setCurrentEvent] = useState({});
  const [requestArgs, setRequestArgs] = useState({
    endDate: dayjs(new Date()).add(6, 'months').format('YYYY-MM-DD'),
    eventTypeCodeList: [],
    page: 0,
    size: 25,
    sort: 'startDate,desc',
    startDate: dayjs(new Date()).subtract(6, 'months').format('YYYY-MM-DD'),
  });

  const [isLoadingAssets, setIsLoadingAssets] = useState(false);

  const { clearApiCache, useApiMutation, useApiQueries, useApiQuery } = useApi();

  const theAssets = ['ListEventTypes', 'ListImpactedSystems'];
  const assets = useApiQueries(theAssets);

  const [ListEvents, { data, error, isFetching, isLoading, refetch }] = useApiQuery('ListEvents');
  const [SaveEvent] = useApiMutation('SaveEvent');

  const { toast } = useToast();

  /**
   * Determines the overall loading state of all asset queries.
   */
  useEffect(() => {
    if (
      !isEmpty(assets) &&
      Object.keys(assets).length === theAssets.length &&
      isEmpty(
        Object.keys(assets).filter((assetKey) => {
          const asset = assets[assetKey];
          return !(!asset?.isLoading && !asset?.isFetching);
        })
      )
    ) {
      setIsLoadingAssets(false);
    } else {
      setIsLoadingAssets(true);
    }
  }, [assets, theAssets]);

  /**
   * Updates the event type code list in the list events request once the event types are loaded.
   */
  useEffect(() => {
    if (!isUndefined(assets?.ListEventTypes?.data)) {
      const eventTypeCodeList =
        assets?.ListEventTypes?.data?.map((eventType) => {
          return eventType?.codeId;
        }) || [];

      if (eventTypeCodeList !== requestArgs?.eventTypeCodeList) {
        setRequestArgs({ ...requestArgs, ...{ eventTypeCodeList } });
      }
    }
  }, [assets?.ListEventTypes?.data]);

  /**
   * handleSave
   *
   * Handles the saving of an event.
   *
   * @param payload
   * @returns {Promise<void>}
   */
  const handleSave = async (payload = {}) => {
    const toastId = 'save-event';

    toast.show({
      ariaLoadingLabel: 'Saving Event',
      autoClose: false,
      id: `${toastId}-info`,
      isLoading: true,
      message: 'Event is preparing to save.',
      title: 'Saving Event...',
      variant: 'info',
    });

    await SaveEvent(payload, {
      onError: () => {
        toast.hide(`${toastId}-info`);
        toast.show({
          id: `${toastId}-error`,
          message: `Unable to save event.`,
          title: 'Event Save Failed',
          variant: 'error',
        });
      },
      onSuccess: () => {
        clearApiCache(['ListEvents']);
        refetch();
        toast.hide(`${toastId}-info`);
        toast.show({
          id: `${toastId}-success`,
          message: `Event has been saved.`,
          title: 'Saved Event',
          variant: 'success',
        });
      },
    });
  };

  return (
    <ErrorHandler location="src/routes/private/Notifications/screens/Events/List/List.jsx">
      <motion.div
        animate="open"
        initial={{ opacity: 0 }}
        variants={{
          closed: { opacity: 0 },
          open: { opacity: 1 },
        }}
      >
        <Grid>
          <Grid.Col
            span={{
              xs: '100%',
            }}
          >
            <Flex alignItems="center" direction="row">
              <Heading offset={0}>Events</Heading>
              <Button
                before={<IconSymbol icon="add" />}
                css={{
                  marginLeft: themeConfiguration?.theme?.space?.md,
                }}
                isDisabled={isLoadingAssets}
                onClick={() => {
                  setIsFormModalOpen(true);
                  setCurrentEvent({});
                }}
                size="$sm"
                variant="outline"
              >
                Create
              </Button>
            </Flex>
          </Grid.Col>
          {!isLoadingAssets && !isUndefined(assets?.ListEventTypes?.data) && !isLoading ? (
            <Grid.Col
              span={{
                xs: '100%',
              }}
            >
              <Table
                assets={assets}
                error={error}
                isLoading={isLoading || isFetching}
                refetch={refetch}
                requestArgs={{
                  ...requestArgs,
                  ...{
                    eventTypeCodeList: assets?.ListEventTypes?.data?.map((eventType) => {
                      return eventType?.codeId;
                    }),
                  },
                }}
                requestFunction={ListEvents}
                requestKey="ListEvents"
                rows={data?.content || []}
                setCurrentEvent={setCurrentEvent}
                setIsFormModalOpen={setIsFormModalOpen}
                setRequestArgs={setRequestArgs}
                totalPages={data?.totalPages || 1}
                totalRecords={data?.totalElements || 0}
              />
              {isFormModalOpen && (
                <FormModal
                  assets={assets}
                  currentEvent={currentEvent}
                  handleSave={handleSave}
                  isOpen={isFormModalOpen}
                  setCurrentEvent={setCurrentEvent}
                  setIsOpen={setIsFormModalOpen}
                />
              )}
            </Grid.Col>
          ) : (
            <Grid.Col
              span={{
                xs: '100%',
              }}
            >
              <Loader verticalAlignment="top" />
            </Grid.Col>
          )}
        </Grid>
      </motion.div>
    </ErrorHandler>
  );
};
