import { every, isEmpty, isUndefined } from 'lodash';

/**
 * isValidStep
 *
 * validates the current step of the wizard
 *
 * @param currentStep
 * @param validName
 * @param formErrors
 * @param formValues
 * @param mode
 * @param selectedCommonEntrance
 * @param selectedCommonExit
 * @returns {*|boolean}
 */
export function isValidStep(
  currentStep,
  validName,
  formErrors,
  formValues,
  mode,
  selectedCommonEntrance,
  selectedCommonExit
) {
  const theStep = { ...currentStep };

  if (mode === 'manual') {
    theStep.isValid = validateManualStep(currentStep, validName, formErrors);
  }

  if (mode === 'automatic') {
    theStep.isValid = validateAutomaticStep(
      currentStep,
      validName,
      formErrors,
      formValues,
      selectedCommonEntrance,
      selectedCommonExit
    );
  }

  return theStep.isValid;
}

/**
 * validateAutomaticStep
 *
 * validates the current step of the wizard for automatic actions
 *
 * @param currentStep
 * @param validName
 * @param formErrors
 * @param formValues
 * @param selectedCommonEntrance
 * @param selectedCommonExit
 * @returns {*|boolean}
 */
function validateAutomaticStep(
  currentStep,
  validName,
  formErrors,
  formValues,
  selectedCommonEntrance,
  selectedCommonExit
) {
  const theStep = { ...currentStep };

  if (theStep?.order === 1) {
    const criteriaIds = selectedCommonEntrance?.commonIds?.filter((item) => {
      return !isEmpty(item);
    });

    const criteriaVersionIds = selectedCommonEntrance?.commonCriteriaVersionsIds?.filter((item) => {
      return !isEmpty(item);
    });

    const additionalCriteria = formValues?.criteria?.entrance?.additional?.filter((item) => {
      return !every(Object.values(item), isEmpty);
    });

    const hasValues = (!isEmpty(criteriaIds) && !isEmpty(criteriaVersionIds)) || !isEmpty(additionalCriteria);

    theStep.isValid = !!isUndefined(formErrors?.criteria?.entrance) && hasValues;
  }

  if (theStep?.order === 2) {
    theStep.isValid = true;
  }

  if (theStep?.order === 3) {
    const assignments = formValues?.remediation?.assignments?.filter((item) => {
      return !every(Object.values(item), isEmpty);
    });

    const hasValues = !isEmpty(assignments);

    theStep.isValid = !!isEmpty(formErrors?.remediation) && hasValues;
  }

  if (theStep?.order === 4) {
    if (formValues?.exitCriteriaMethod === 'EXIT_CRITERIA_MET') {
      const criteriaIds = selectedCommonExit?.commonIds?.filter((item) => {
        return !isEmpty(item);
      });

      const criteriaVersionIds = selectedCommonExit?.commonCriteriaVersionsIds?.filter((item) => {
        return !isEmpty(item);
      });

      const additionalCriteria = formValues?.criteria?.exit?.additional?.filter((item) => {
        return !every(Object.values(item), isEmpty);
      });

      const hasValues = (!isEmpty(criteriaIds) && !isEmpty(criteriaVersionIds)) || !isEmpty(additionalCriteria);

      theStep.isValid = !!isUndefined(formErrors?.criteria?.exit) && hasValues;
    } else {
      theStep.isValid = true;
    }
  }

  if (theStep?.order === 5) {
    theStep.isValid = !!isEmpty(formErrors?.entranceCriteria) && !!isEmpty(formErrors?.remediation);
  }

  return validName && theStep.isValid;
}

/**
 * validateManualStep
 *
 * validates the current step of the wizard for manual actions
 *
 * @param currentStep
 * @param validName
 * @param formErrors
 * @returns {*|boolean}
 */
function validateManualStep(currentStep, validName, formErrors) {
  const theStep = { ...currentStep };

  if (theStep?.order === 1) {
    theStep.isValid = validName && isEmpty(formErrors?.remediation);
  }

  if (theStep?.order === 2) {
    theStep.isValid = validName && isEmpty(formErrors?.remediation);
  }

  return theStep.isValid;
}
