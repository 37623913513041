import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '#filtersContainer': {
    marginTop: 'calc(calc(var(--abyss-space-xl) + var(--abyss-space-sm)) * -1)',
  },
  '#viewsContainer': {
    '.abyss-flex-root': {
      '.abyss-select-input-multi-root': {
        "*[class*='isInvalid-true']": {
          backgroundColor: 'var(--abyss-colors-error2)',
          border: '1px solid var(--abyss-colors-error1)',
          bottom: 'unset',
          marginRight: 'var(--abyss-space-lg)',
          padding: '4px',
          position: 'absolute',
          right: 'unset',
          top: 'calc(100% - 1px)',
          zIndex: '999',
        },
        position: 'relative',
      },
      '> div:first-of-type': {
        paddingRight: 'var(--abyss-space-md)',
        width: 'calc(100%  - 90px)',
      },
    },
    borderBottom: '1px solid var(--abyss-colors-gray4)',
    borderLeft: '1px solid var(--abyss-colors-gray4)',
    borderRight: '1px solid var(--abyss-colors-gray4)',
  },
});
