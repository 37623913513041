import { useApi } from '@src/context/Api';
import { isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

/**
 * Hook: useAssets
 *
 * Makes API requests to retrieve the assets required for the current experience.
 *
 * @param theAssets
 * @returns {{isLoading: boolean, data: *}}
 */
export const useAssets = (theAssets = []) => {
  const [isLoading, setIsLoading] = useState(false);

  const { useApiQueries } = useApi();
  const assets = useApiQueries(theAssets);

  /**
   * Determines the overall loading state of all asset queries.
   */
  useEffect(() => {
    if (
      !isEmpty(assets) &&
      Object.keys(assets).length === theAssets.length &&
      isEmpty(
        Object.keys(assets).filter((assetKey) => {
          const asset = assets[assetKey];
          return !(!asset?.isLoading && !asset?.isFetching);
        })
      )
    ) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [assets, theAssets]);

  return useMemo(() => {
    return { assets, isLoadingAssets: isLoading };
  }, [assets, isLoading]);
};
