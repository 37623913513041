import { Badge } from '@abyss/web/ui/Badge';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * ModeBadge
 *
 * displays the mode of the action path.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const ModeBadge = (props) => {
  const { actionPath, mode } = props;

  if (mode === 'automatic' || actionPath?.manualAssociation === false) {
    return (
      <Badge
        icon={<IconSymbol icon="autorenew" size={16} style={{ color: 'inherit' }} variant="outlined" />}
        outline
        variant="success"
      >
        Automatic
      </Badge>
    );
  }

  if (mode === 'manual' || actionPath?.manualAssociation === true) {
    return (
      <Badge
        icon={<IconSymbol icon="back_hand" size={16} style={{ color: 'inherit' }} variant="outlined" />}
        outline
        variant="warning"
      >
        Manual
      </Badge>
    );
  }

  return null;
};

ModeBadge.propTypes = {
  actionPath: PropTypes.shape({
    manualAssociation: PropTypes.bool,
  }),
  mode: PropTypes.string,
};

ModeBadge.defaultProps = {
  actionPath: {},
  mode: '',
};
