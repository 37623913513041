import PropTypes from 'prop-types';
import React from 'react';

/**
 * Description
 *
 * Renders a description to be used within a widget component.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Description = (props) => {
  const { remediationType, scopeCode } = props;

  return (
    <React.Fragment>
      {scopeCode === 'CURRENT_FUTURE' && (
        <p style={{ marginBottom: '2px' }}>
          The following actions will be applied to <strong>current records</strong> and <strong>future records</strong>:
        </p>
      )}
      {scopeCode === 'FUTURE' && (
        <p style={{ marginBottom: '2px' }}>
          The following actions will be applied to apply to <strong>future records</strong> only:
        </p>
      )}
      <p style={{ marginBottom: 0 }}>
        Remediation Type: <strong>{remediationType}</strong>
      </p>
    </React.Fragment>
  );
};

Description.propTypes = {
  remediationType: PropTypes.string,
  scopeCode: PropTypes.string,
};

Description.defaultProps = {
  remediationType: '',
  scopeCode: '',
};
