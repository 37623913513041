import { config } from '@abyss/web/tools/config';
import { Axios } from '@src/context/Api/includes/Axios';
import { logger } from '@src/includes/logger';
import { isUndefined } from 'lodash';

/**
 * GetEvent
 *
 * Retrieves a single action path from the remote API via an authenticated request.
 *
 * @param payload
 * @returns {Promise<any>}
 * @constructor
 */
export const GetEvent = async (payload = {}) => {
  try {
    const requestArgs = {
      baseURL: config('API_URL'),
      headers: {
        'x-api-endpoint': `/events`,
      },
      method: 'GET',
    };

    if (!isUndefined(payload)) {
      requestArgs.params = { ...{ queryKey: payload[0] }, ...payload[1] };
      requestArgs.headers['x-api-endpoint'] += `/${payload[1]?.eventId}`;
    }

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse.data;
  } catch (error) {
    logger.error('src/requests/queries/GetEvent.js -> GetEvent()', false, error);

    throw error;
  }
};
