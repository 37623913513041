import { Alert } from '@abyss/web/ui/Alert';
import { Grid } from '@abyss/web/ui/Grid';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { isEmpty, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Table } from './components/Table';

/**
 * Tags
 *
 * Displays affiliated tags with the risk record.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Tags = (props) => {
  const { assets, riskRecord } = props;

  const tags = assets?.ListTags?.data || [];
  const addedTags = riskRecord?.tagModificationDetail?.currentTagList || [];
  const removedTags = riskRecord?.tagModificationDetail?.removedTagList || [];

  const [rows, setRows] = useState([]);

  /**
   * Set the rows for the table.
   */
  useEffect(() => {
    const theTags = [
      ...addedTags.map((addedTag) => {
        const items = addedTag?.tag?.split('-');

        return {
          category: items?.[0],
          description: '',
          lastModified: addedTag?.lastAddedDate,
          name: items?.[1],
          status: 'Added',
        };
      }),
      ...removedTags.map((removedTag) => {
        const items = removedTag?.tag?.split('-');

        return {
          category: items?.[0],
          description: '',
          lastModified: removedTag?.lastRemovedDate,
          name: items?.[1],
          status: 'Removed',
        };
      }),
    ];

    const theRows = theTags?.map((theTag) => {
      const theRow = { ...theTag };

      if (!isUndefined(tags?.tagsList?.[theTag?.category])) {
        const matchedTag = tags?.tagsList?.[theTag?.category].find((tag) => {
          return tag?.categoryCode === theTag?.category && tag?.code === theTag?.name;
        });

        if (!isUndefined(matchedTag)) {
          theRow.description = matchedTag?.categoryDesc;
        }
      }

      return theRow;
    });

    if (theRows !== rows) {
      setRows(theRows);
    }
  }, [addedTags, removedTags, tags]);

  return (
    <ErrorHandler location="src/routes/private/Analysis/screens/EidSearch/components/Layout/Main/components/Results/widgets/Tags/Tags.jsx">
      {isEmpty(rows) ? (
        <Grid.Col span={{ lg: '100%', md: '100%', sm: '100%', xs: '100%' }}>
          <Alert title="There are no tags affiliated with this risk record." variant="info" />
        </Grid.Col>
      ) : (
        <Table rows={rows} />
      )}
    </ErrorHandler>
  );
};

Tags.propTypes = {
  assets: PropTypes.shape({
    ListTags: PropTypes.shape({
      data: PropTypes.shape({
        tagsList: PropTypes.shape({
          categoryCode: PropTypes.string,
          categoryDesc: PropTypes.string,
          code: PropTypes.string,
        }),
      }),
    }),
  }),
  riskRecord: PropTypes.shape({
    tagModificationDetail: PropTypes.shape({
      currentTagList: PropTypes.arrayOf(
        PropTypes.shape({
          lastAddedDate: PropTypes.string,
          tag: PropTypes.string,
        })
      ),
      removedTagList: PropTypes.arrayOf(
        PropTypes.shape({
          lastRemovedDate: PropTypes.string,
          tag: PropTypes.string,
        })
      ),
    }),
  }),
};

Tags.defaultProps = {
  assets: {},
  riskRecord: {},
};
