import { config } from '@abyss/web/tools/config';
import { dayjs } from '@abyss/web/tools/dayjs';
import { Axios } from '@src/context/Api/includes/Axios';
import { logger } from '@src/includes/logger';
import { isUndefined, orderBy } from 'lodash';

/**
 * GetRiskTrendReport
 *
 * Retrieves a list of risk record changes by attribute type and record type.
 *
 * @param payload
 * @returns {Promise<*>}
 * @constructor
 */
export const GetRiskTrendReport = async (payload = {}) => {
  try {
    const requestArgs = {
      baseURL: config('API_URL'),
      data: {},
      headers: {
        'x-api-endpoint': '/dashboard/operational/risk-trend',
      },
      method: 'POST',
      params: {},
    };

    if (!isUndefined(payload)) {
      requestArgs.params = { ...{ queryKey: payload[0] } };
    }

    if (!isUndefined(payload?.[1])) {
      requestArgs.data = payload?.[1];
    }

    const remoteResponse = await Axios.request(requestArgs);

    const response = remoteResponse;

    if (!isUndefined(response?.data?.series)) {
      /**
       * Sort overall data by sourceCode in ascending order, then sort each datapoint from each sourceCode by date in
       * ascending order.
       */
      response.data.series = orderBy(remoteResponse?.data?.series, ['sourceCode'], ['asc']).map((dataset) => {
        const theDataSet = { ...dataset };

        theDataSet.dataPoints = orderBy(
          dataset?.dataPoints,
          [
            (dataPoint) => {
              return dayjs(dataPoint?.date).format('YYYY-MM-DD');
            },
          ],
          ['asc']
        );

        return theDataSet;
      });
    }

    if (!isUndefined(remoteResponse?.data?.sumSeries?.dataPoints)) {
      /**
       * Sort each datapoint in the summary by date in ascending order.
       */
      response.data.sumSeries.dataPoints = remoteResponse?.data?.sumSeries?.dataPoints?.map((dataset) => {
        const theDataSet = { ...dataset };

        theDataSet.dataPoints = orderBy(
          dataset?.dataPoints,
          [
            (dataPoint) => {
              return dayjs(dataPoint?.date).format('YYYY-MM-DD');
            },
          ],
          ['asc']
        );

        return theDataSet;
      });
    }

    return response.data;
  } catch (error) {
    logger.error('src/requests/queries/ListRiskRecords.js -> ListRiskRecords()', false, error);

    throw error;
  }
};
