import { Badge } from '@abyss/web/ui/Badge';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Widget } from '@src/components/Widget';
import { useApi } from '@src/context/Api';
import fields from '@src/features/ActionPaths/includes/fields.json';
import { abbrNum } from '@src/includes/functions';
import { isEmpty, isNull, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import { Table } from './components/Table';
import { Styles } from './includes/styles';

const { exitCriteriaMethod: exitCriteriaMethodField } = fields;

/**
 * Widget: FilterCriteria
 *
 * @TODO Needs description
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const FilterCriteria = (props) => {
  const { context, criteria, criteriaMethod, filterKey, setCountCallback } = props;

  const [count, setCount] = useState(null);

  const { useApiQuery } = useApi();
  const [GetRiskRecordsCount, { data: riskRecordsCount }] = useApiQuery('GetRiskRecordsCount');

  /**
   * Get the total number of risk records if not already fetched.
   */
  useEffect(() => {
    if (isUndefined(riskRecordsCount) && !isEmpty(criteria?.[context]?.merged)) {
      if (context === 'entrance') {
        GetRiskRecordsCount({
          criteria: {
            entrance: criteria?.entrance,
          },
        });
      }

      if (context === 'exit') {
        GetRiskRecordsCount({ criteria });
      }
    }
  }, [riskRecordsCount, criteria]);

  /**
   * Set the count of risk records.
   */
  useEffect(() => {
    if (!isUndefined(riskRecordsCount)) {
      if (context === 'entrance') {
        setCount(riskRecordsCount?.entranceCriteriaCount);
        setCountCallback(riskRecordsCount?.entranceCriteriaCount);
      }

      if (context === 'exit') {
        setCount(riskRecordsCount?.exitCriteriaCount);
        setCountCallback(riskRecordsCount?.exitCriteriaCount);
      }
    }
  }, [riskRecordsCount]);

  /**
   * add the type of criteria to the rows
   * @type {*[]}
   */
  const rows = useMemo(() => {
    const theRows = [];

    // add additional criteria
    if (!isEmpty(criteria?.[context]?.additional)) {
      criteria?.[context]?.additional.forEach((row) => {
        const theRow = {
          ...row,
          ...{
            type: 'additional',
          },
        };
        if (!theRows.includes(theRow)) {
          theRows.push(theRow);
        }
      });
    }

    // add common criteria
    if (!isEmpty(criteria?.[context]?.common)) {
      criteria?.[context]?.common.forEach((row) => {
        const theRow = {
          ...row,
          ...{
            type: 'common',
          },
        };
        if (!theRows.includes(theRow)) {
          theRows.push(theRow);
        }
      });
    }

    return theRows;
  }, [criteria]);

  let title = '';

  if (context === 'entrance') {
    title = 'Entrance Criteria';
  }

  if (context === 'exit') {
    title = 'Exit Criteria';
  }

  const theCriteriaMethod = useMemo(() => {
    return exitCriteriaMethodField?.options?.find((option) => {
      return option?.value === criteriaMethod;
    });
  }, [criteriaMethod, criteria]);

  return (
    <ErrorHandler location="src/features/Criteria/components/widgets/FilterCriteria/FilterCriteria.jsx">
      <Styles>
        <Widget
          button={
            !isUndefined(riskRecordsCount) &&
            !isNull(count) && (
              <Badge outline variant="info">
                {abbrNum(count)} records
              </Badge>
            )
          }
          description=""
          title={title}
        >
          {isEmpty(criteriaMethod) || criteriaMethod === 'EXIT_CRITERIA_MET' ? (
            <Table filterKey={filterKey} rows={rows || []} />
          ) : (
            theCriteriaMethod?.label
          )}
        </Widget>
      </Styles>
    </ErrorHandler>
  );
};

FilterCriteria.propTypes = {
  context: PropTypes.string,
  criteria: PropTypes.shape({
    entrance: PropTypes.shape({
      additional: PropTypes.arrayOf({
        column: PropTypes.string,
        condition: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
      }),
      common: PropTypes.arrayOf({
        column: PropTypes.string,
        condition: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
      }),
      merged: PropTypes.arrayOf({
        column: PropTypes.string,
        condition: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
      }),
    }),
    exit: PropTypes.shape({
      additional: PropTypes.arrayOf({
        column: PropTypes.string,
        condition: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
      }),
      common: PropTypes.arrayOf({
        column: PropTypes.string,
        condition: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
      }),
      merged: PropTypes.arrayOf({
        column: PropTypes.string,
        condition: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
      }),
    }),
  }),
  criteriaMethod: PropTypes.string,
  filterKey: PropTypes.string,
  setCountCallback: PropTypes.func,
};

FilterCriteria.defaultProps = {
  context: '',
  criteria: {
    entrance: {
      additional: [],
      common: [],
      merged: [],
    },
    exit: {
      additional: [],
      common: [],
      merged: [],
    },
  },
  criteriaMethod: '',
  filterKey: 'filters',
  setCountCallback: () => {},
};
