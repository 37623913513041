import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '.abyss-loading-overlay-dialog': {
    left: '10%',
    right: '10%',
    top: 'calc(var(--abyss-space-lg) * 5)',
  },
  '#eidSearchResults': {
    '.abyss-indicator-root': {
      '.abyss-indicator-container': {
        '.abyss-indicator-label': {
          color: 'var(--abyss-colors-info1)',
          fontWeight: 'bold',
        },
        background: 'var(--abyss-colors-info2)',
        borderColor: 'var(--abyss-colors-info2)',
      },
    },
  },
  '#searchContainer': {
    '.abyss-descriptors-error-message': {
      bottom: 'initial',
    },
    position: 'relative',
  },
});
