import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { RiskCodesTooltip } from '@src/components/common/tooltips';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { isEmpty, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * RiskCode
 *
 * Displays a color coded risk code with a tooltip that displays the risk code description.
 *
 * @param props
 * @returns {React.JSX.Element|*}
 * @constructor
 */
export const RiskCode = (props) => {
  const { riskCode: theRiskCode, riskCodes } = props;

  let letter;
  const codeDescriptions = [];

  if (!isEmpty(riskCodes)) {
    const codes = theRiskCode?.split('-');
    letter = codes.shift().toUpperCase();

    codes.forEach((codeNumber) => {
      const match = riskCodes.find((riskCode) => {
        return riskCode?.codeId === String(codeNumber);
      });

      if (!isUndefined(match) && !codeDescriptions.includes(match)) {
        codeDescriptions.push(match);
      }
    });
  }

  return (
    <ErrorHandler location="src/routes/private/Analysis/screens/EidSearch/components/Layout/Header/components/Details/components/RiskCode/RiskCode.jsx">
      <Layout.Group space={themeConfiguration?.theme?.space?.md}>
        {letter === 'R' && (
          <Text color={themeConfiguration?.theme?.colors?.error1} fontWeight="bold">
            <Tooltip content={<RiskCodesTooltip codeDetails={codeDescriptions} />} placement="auto">
              <div>{theRiskCode}</div>
            </Tooltip>
          </Text>
        )}

        {letter === 'Y' && (
          <Text color={themeConfiguration?.theme?.colors?.warning1} fontWeight="bold">
            <Tooltip content={<RiskCodesTooltip codeDetails={codeDescriptions} />} placement="auto">
              <div>{theRiskCode}</div>
            </Tooltip>
          </Text>
        )}

        {letter === 'G' && (
          <Text color={themeConfiguration?.theme?.colors?.success1} fontWeight="bold">
            {theRiskCode}
          </Text>
        )}
      </Layout.Group>
    </ErrorHandler>
  );
};

RiskCode.propTypes = {
  riskCode: PropTypes.string,
  riskCodes: PropTypes.arrayOf(
    PropTypes.shape({
      codeDesc: PropTypes.string,
      codeId: PropTypes.string,
    })
  ),
};

RiskCode.defaultProps = {
  riskCode: '',
  riskCodes: [],
};
