import { useForm } from '@abyss/web/hooks/useForm';
import { Divider } from '@abyss/web/ui/Divider';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Grid } from '@abyss/web/ui/Grid';
import { Layout } from '@abyss/web/ui/Layout';
import { Modal } from '@abyss/web/ui/Modal';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { TextInput } from '@abyss/web/ui/TextInput';
import { TextInputArea } from '@abyss/web/ui/TextInputArea';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { isEmpty, isUndefined, merge } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { Table } from './components/Table';

/**
 * EditAlertModal
 *
 * Prompts the user with a popup window allowing them to edit the alert.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const EditModal = (props) => {
  const { alert, handleSave, isOpen, setAlert, setIsOpen } = props;

  const defaultValues = {
    assignedUser: '',
    id: '',
    notes: '',
    status: '',
    triggeredAlertId: '',
  };

  const form = useForm({ defaultValues });

  /**
   * Mapping data loaded from API to the form state.
   */
  useEffect(() => {
    if (!isEmpty(alert)) {
      const data = merge({}, defaultValues, {
        assignedUser: alert?.assignedUser,
        id: alert?.id,
        notes: alert?.notes,
        status: alert?.status,
        triggeredAlertId: alert?.triggeredAlertId,
      });

      form?.reset(data, {
        keepDirty: false,
        keepDirtyValues: false,
        keepErrors: false,
        keepIsValid: false,
        keepSubmitCount: true,
        keepTouched: false,
        keepValues: false,
      });
    }
  }, [alert]);

  /**
   * handleClose
   *
   * Handles the closing of the modal.
   *
   * @returns {Promise<void>}
   */
  const handleClose = async () => {
    setIsOpen(false);
    setAlert({});
  };

  /**
   * handleSubmit
   *
   * Handles the form submission.
   *
   * @param submittedValues
   * @returns {Promise<void>}
   */
  const handleSubmit = async (submittedValues) => {
    await handleClose();
    await handleSave(submittedValues);
  };

  return (
    <ErrorHandler location="src/routes/private/Notifications/screens/Alerts/List/components/EditModal/EditModal.jsx">
      <Modal isOpen={isOpen} onClose={handleClose} title="Edit Alert">
        <FormProvider autoComplete="off" highlighted onSubmit={handleSubmit} state={form}>
          <Modal.Section>
            <Grid>
              <Grid.Col
                span={{
                  xs: '100%',
                }}
              >
                {!isUndefined(alert) && !isEmpty(alert) && <Table alert={alert} />}
              </Grid.Col>
              <Grid.Col
                span={{
                  xs: '50%',
                }}
              >
                <SelectInput
                  label="Status"
                  model="status"
                  options={[
                    { label: 'Assigned', value: 'ASSIGNED' },
                    { label: 'Closed', value: 'CLOSED' },
                    { label: 'Triggered', value: 'TRIGGERED' },
                  ]}
                  validators={{ required: true }}
                />
              </Grid.Col>
              <Grid.Col
                span={{
                  xs: '50%',
                }}
              >
                <TextInput isClearable label="Assigned" model="assignedUser" validators={{ required: true }} />
              </Grid.Col>
              <Grid.Col
                span={{
                  xs: '100%',
                }}
              >
                <TextInputArea label="Notes" model="notes" validators={{ required: true }} />
              </Grid.Col>
            </Grid>
          </Modal.Section>
          <Modal.Section>
            <Divider height={1} />
            <Layout.Group alignLayout="right">
              <Button onClick={handleClose} variant="outline">
                Cancel
              </Button>
              <Button isDisabled={!form?.formState.isDirty && !form?.formState?.isValid} type="submit" variant="solid">
                Save
              </Button>
            </Layout.Group>
          </Modal.Section>
        </FormProvider>
      </Modal>
    </ErrorHandler>
  );
};

EditModal.propTypes = {
  alert: PropTypes.shape({
    assignedUser: PropTypes.string,
    id: PropTypes.string,
    notes: PropTypes.string,
    status: PropTypes.string,
    triggeredAlertId: PropTypes.string,
  }),
  handleSave: PropTypes.func,
  isOpen: PropTypes.bool,
  setAlert: PropTypes.func,
  setIsOpen: PropTypes.func,
};

EditModal.defaultProps = {
  alert: {},
  handleSave: () => {},
  isOpen: false,
  setAlert: () => {},
  setIsOpen: () => {},
};
