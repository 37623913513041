import { Alert } from '@abyss/web/ui/Alert';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { useWizardContext } from '@src/features/ActionPaths/components/misc/Wizard/context';
import { FilterCriteriaWidget } from '@src/features/Criteria/components/widgets/FilterCriteria';
import { RemediationActions } from '@src/features/Risk/components/widgets/RemediationActions';
import { isEmpty, isNil } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

/**
 * ReviewConfirm
 *
 * Provides the user with a screen to verify the specified entrance criteria (filters) and actions to run on the
 * queried risk records.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const ReviewConfirm = (props) => {
  const { assets, form } = props;

  const [exitCount, setExitCount] = useState(null);

  const { actionPath, currentStep, mode } = useWizardContext();

  if (isNil(actionPath) || isEmpty(actionPath)) {
    return null;
  }

  const scopeCode = form.getValues('remediation.actionPathScopeCode');
  const remediationType = form.getValues('remediation.remediationMethod');
  const assignments = form.getValues('remediation.assignments');

  const criteria = form?.getValues('criteria');
  const exitCriteriaMethod = form?.getValues('exitCriteriaMethod');

  return (
    <ErrorHandler location="src/features/ActionPaths/components/misc/Wizard/components/steps/ReviewConfirm/ReviewConfirm.jsx">
      <Grid>
        <Grid.Col
          css={{ paddingTop: themeConfiguration?.theme?.space?.lg }}
          span={{
            xs: '100%',
          }}
        >
          <Heading offset={1}>{currentStep?.label}</Heading>
          <p>{currentStep?.description}</p>
        </Grid.Col>
        {exitCount === 0 && (
          <Grid.Col
            span={{
              xs: '100%',
            }}
          >
            <Alert title="Warning - Consider Refining Entrance Criteria" variant="warning">
              The Entrance and Exit Criteria contain records entering the action path AND being immediately remediated.
            </Alert>
          </Grid.Col>
        )}
        {mode !== 'manual' && [
          <Grid.Col
            span={{
              xs: '50%',
            }}
          >
            <FilterCriteriaWidget
              {...{
                context: 'entrance',
                criteria,
                filterKey: 'entranceCriteria',
              }}
            />
          </Grid.Col>,
          <Grid.Col
            span={{
              xs: '50%',
            }}
          >
            <FilterCriteriaWidget
              {...{
                context: 'exit',
                criteria,
                criteriaMethod: exitCriteriaMethod,
                filterKey: 'exitCriteria',
                setCountCallback: setExitCount,
              }}
            />
          </Grid.Col>,
        ]}
        <Grid.Col
          span={{
            xs: '100%',
          }}
        >
          <RemediationActions
            {...{
              assignments: assignments.map((assignment) => {
                const theAssignment = { ...assignment };
                const theAction = assets?.ListActions?.data?.content?.find((action) => {
                  return action?.id === theAssignment?.actionId;
                });

                theAssignment.actionName = theAction?.name;
                return theAssignment;
              }),
              remediationType,
              scopeCode,
            }}
          />
        </Grid.Col>
      </Grid>
    </ErrorHandler>
  );
};

ReviewConfirm.propTypes = {
  assets: PropTypes.shape({
    ListActions: PropTypes.shape({
      data: PropTypes.shape({
        content: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
          })
        ),
      }),
    }),
  }),
  form: PropTypes.shape({
    getValues: PropTypes.func,
  }),
};

ReviewConfirm.defaultProps = {
  assets: {},
  form: {},
};
