import { Grid } from '@abyss/web/ui/Grid';
import { Indicator } from '@abyss/web/ui/Indicator';
import { Layout } from '@abyss/web/ui/Layout';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Table as TableComponent } from '@src/components/Table-static';
import { Visibility } from '@src/components/Visibility';
import { isEmpty, isUndefined, merge } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import configuration from './includes/configuration.json';

/**
 * Table: Untrusted
 *
 * Values from Untrusted Sources
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const UntrustedSources = (props) => {
  const { record, row } = props;

  const [sources, setSources] = useState({});
  const [rows, setRows] = useState([]);

  /**
   * sources for values to be displayed in the table.
   */
  useEffect(() => {
    const theSources = {};

    if (!isEmpty(row?.ireRiskRecord?.remediationFindings)) {
      row?.ireRiskRecord?.remediationFindings.forEach((attribute) => {
        attribute?.untrustedRecordSources.forEach((untrustedSource) => {
          if (isUndefined(theSources[untrustedSource?.source])) {
            theSources[untrustedSource?.source] = {};
          }

          if (isUndefined(theSources[untrustedSource?.source][untrustedSource?.value])) {
            theSources[untrustedSource?.source][untrustedSource?.value] = [];
          }

          if (!theSources[untrustedSource?.source][untrustedSource?.value].includes(untrustedSource?.recordId)) {
            theSources[untrustedSource?.source][untrustedSource?.value].push(untrustedSource?.recordId);
          }
        });
      });
    }

    if (!isEmpty(theSources) && theSources !== sources) {
      setSources(theSources);
    }
  }, [row, record]);

  /**
   * Rows of data to be used in the data table.
   */
  useEffect(() => {
    const theRows = [];

    Object.keys(sources).forEach((untrustedSource) => {
      Object.keys(sources[untrustedSource]).forEach((value) => {
        const item = {
          ids: [],
          source: untrustedSource,
          type: '',
          value: '',
        };

        item.ids = sources[untrustedSource][value];

        item.value = value;
        if (!theRows.includes(item)) {
          theRows.push(item);
        }
      });
    });

    if (!isEmpty(theRows) && theRows !== rows) {
      setRows(theRows);
    }
  }, [sources]);

  /**
   * renderCellUntrustedValue
   *
   * Displays the untrusted value from the source within a cell in the table.
   *
   * @param args
   * @returns {*}
   */
  const renderCellUntrustedValue = (args) => {
    const { cell } = args;

    return cell?.value;
  };

  /**
   * renderCellUntrustedSource
   *
   * Displays the untrusted source name within a cell in the table.
   *
   * @param args
   * @returns {*|JSX.Element}
   */
  const renderCellUntrustedSource = (args) => {
    const { cell, row: theRow } = args;

    if (theRow?.original?.ids?.length > 1) {
      return (
        <div style={{ width: 'auto' }}>
          <Indicator label={theRow?.original?.ids?.length} showZero={false} withBorder>
            <div style={{ marginRight: 'var(--abyss-space-sm)' }}>{cell?.value}</div>
          </Indicator>
        </div>
      );
    }

    return cell?.value;
  };

  /**
   * ExpansionRow
   *
   * Expands/opens a table row on click.
   *
   * @param args
   * @returns {JSX.Element|string}
   * @constructor
   */
  const ExpansionRow = (args) => {
    const { theRow } = args;

    if (!isEmpty(theRow?.ids)) {
      return (
        <Grid css={{ margin: 0 }}>
          <Grid.Col
            span={{
              xs: '100%',
            }}
          >
            <Layout.Stack alignItems="left" grow>
              {theRow?.ids.map((id) => {
                return (
                  <div>
                    <strong>ID:</strong> <code>{id}</code>
                  </div>
                );
              })}
            </Layout.Stack>
          </Grid.Col>
        </Grid>
      );
    }

    return '';
  };

  /**
   * Columns for table.
   */
  const columns = useMemo(() => {
    return configuration?.initialColumns.map((item) => {
      const column = item;

      if (column.Header === 'Untrusted Value') {
        column.Cell = renderCellUntrustedValue;
      }

      if (column.Header === 'Untrusted Source') {
        column.Cell = renderCellUntrustedSource;
      }

      return column;
    });
  }, []);

  return (
    <ErrorHandler location="src/common/tables/RiskRecords/components/ExpansionRow/components/Untrusted/Untrusted.jsx">
      <Visibility>
        <TableComponent
          {...{
            columns,
            configuration: merge({}, configuration, {
              renderSubComponent: ({ original }) => {
                return <ExpansionRow theRow={original} />;
              },
            }),
            dataKey: `actionPaths-RiskRecord-Untrusted-${record?.eid}`,
            rows,
          }}
        />
      </Visibility>
    </ErrorHandler>
  );
};

UntrustedSources.propTypes = {
  record: PropTypes.shape({
    eid: PropTypes.string,
  }),
  row: PropTypes.shape({
    ireRiskRecord: PropTypes.shape({
      remediationFindings: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

UntrustedSources.defaultProps = {
  record: {},
  row: {},
};
