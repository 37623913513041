import { config } from '@abyss/web/tools/config';
import { Axios } from '@src/context/Api/includes/Axios';
import { logger } from '@src/includes/logger';
import { isEmpty } from 'lodash';

/**
 * ListRedRiskCodes
 *
 * Retrieves a list of red risk codes from the remote API via an authenticated request.
 *
 * @returns {Promise<[]>}
 * @constructor
 */
export const ListRedRiskCodes = async (payload = {}) => {
  try {
    const theQueryKey = payload?.[0];
    const thePayload = payload?.[1];

    const requestArgs = {
      baseURL: config('API_URL'),
      headers: {
        'x-api-endpoint': '/code-lists/categories/RedRiskCode',
      },
      method: 'GET',
      params: { queryKey: theQueryKey },
    };

    if (!isEmpty(thePayload)) {
      requestArgs.params = { ...requestArgs.params, ...thePayload };
    }

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse.data;
  } catch (error) {
    logger.error('src/requests/queries/ListRedRiskCodes.js -> ListRedRiskCodes()', false, error);

    throw error;
  }
};
