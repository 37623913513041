import { config } from '@abyss/web/tools/config';
import { Axios } from '@src/context/Api/includes/Axios';
import { logger } from '@src/includes/logger';
import { isUndefined } from 'lodash';

/**
 * DeleteActionPath
 *
 * Deletes an action path from the remote API.
 *
 * @param payload
 * @returns {Promise<any>}
 * @constructor
 */
export const DeleteActionPath = async (payload = {}) => {
  try {
    const requestArgs = {
      baseURL: config('API_URL'),
      headers: {
        'x-api-endpoint': `/action-paths`,
      },
      method: 'delete',
    };

    if (!isUndefined(payload)) {
      requestArgs.params = { ...{ mutationKey: payload[0] } };
      requestArgs.headers['x-api-endpoint'] += `/${payload[1]?.id}`;
    }

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse.data;
  } catch (error) {
    logger.error('src/requests/mutations/DeleteActionPath.js -> DeleteActionPath()', false, error);

    throw error;
  }
};
