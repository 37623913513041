import { Grid } from '@abyss/web/ui/Grid';
import { Layout as AbyssLayout } from '@abyss/web/ui/Layout';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { motion } from 'framer-motion';
import { isEmpty, isNull } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Header } from './Header';
import { Styles } from './includes/styles';
import { Main } from './Main';
import { Sidebar } from './Sidebar';

/**
 * Layout
 *
 * Glues together the various pieces of the layout.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Layout = (props) => {
  const {
    assets,
    currentAccessor,
    currentTarget,
    eid,
    handleSearch,
    hasDOB,
    hasResults,
    hasSSN,
    isLoading,
    isSearching,
    riskRecord,
    setCurrentTarget,
    targetRef,
  } = props;

  const [isSidebarOpen, setIsSidebarOpen] = useState(null);

  /**
   * handles the click event for the expand/collapse button
   * @param value
   */
  const toggleSidebar = (value = null) => {
    if (!isNull(value)) {
      setIsSidebarOpen(value);
    } else {
      setIsSidebarOpen((prev) => {
        return !prev;
      });
    }
  };

  /**
   * reset when searching
   */
  useEffect(() => {
    setIsSidebarOpen(null);
  }, [isSearching]);

  return (
    <ErrorHandler location="src/routes/private/Analysis/screens/EidSearch/components/Layout/Layout.jsx">
      <Styles>
        <motion.div
          animate="open"
          initial={{ opacity: 0 }}
          variants={{
            closed: { opacity: 0 },
            open: { opacity: 1 },
          }}
        >
          <Grid>
            <Grid.Col
              span={{
                xs: '100%',
              }}
            >
              <Header
                assets={assets}
                eid={eid}
                handleSearch={handleSearch}
                hasResults={hasResults}
                isLoading={isLoading}
                isSearching={isSearching}
                riskRecord={riskRecord}
              />
            </Grid.Col>
          </Grid>

          {!isLoading && !isEmpty(eid) && (
            <Grid>
              <Grid.Col
                span={{
                  xs: '100%',
                }}
              >
                <AbyssLayout.Group
                  alignItems="top"
                  css={{
                    '> div': {
                      height: '100%',
                    },
                    '> div:last-child': {
                      width: '100%',
                    },
                    height: '100%',
                  }}
                  space={themeConfiguration?.theme?.space?.md}
                >
                  <Sidebar
                    currentAccessor={currentAccessor}
                    eid={eid}
                    hasDOB={hasDOB}
                    hasResults={hasResults}
                    hasSSN={hasSSN}
                    isOpen={isSidebarOpen}
                    isSearching={isSearching}
                    setCurrentTarget={setCurrentTarget}
                    toggleSidebar={toggleSidebar}
                  />
                  <Main
                    assets={assets}
                    currentTarget={currentTarget}
                    eid={eid}
                    handleSearch={handleSearch}
                    hasResults={hasResults}
                    isSearching={isSearching}
                    riskRecord={riskRecord}
                    targetRef={targetRef}
                  />
                </AbyssLayout.Group>
              </Grid.Col>
            </Grid>
          )}
        </motion.div>
      </Styles>
    </ErrorHandler>
  );
};

Layout.propTypes = {
  assets: PropTypes.shape({}),
  currentAccessor: PropTypes.string,
  currentTarget: PropTypes.string,
  eid: PropTypes.string,
  handleSearch: PropTypes.func,
  hasDOB: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
  hasResults: PropTypes.bool,
  hasSSN: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
  isLoading: PropTypes.bool,
  isSearching: PropTypes.bool,
  riskRecord: PropTypes.shape({
    riskScore: PropTypes.number,
  }),
  setCurrentTarget: PropTypes.func,
  targetRef: PropTypes.shape({
    current: PropTypes.shape({}),
  }),
};

Layout.defaultProps = {
  assets: {},
  currentAccessor: '',
  currentTarget: '',
  eid: '',
  handleSearch: () => {},
  hasDOB: false,
  hasResults: false,
  hasSSN: false,
  isLoading: false,
  isSearching: false,
  riskRecord: {},
  setCurrentTarget: () => {},
  targetRef: {},
};
