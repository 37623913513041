import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '.abyss-grid': {
    '.abyss-grid-col': {
      height: 'inherit',
      maxHeight: 'inherit',
      minHeight: 'inherit',
      overflowY: 'scroll',
      position: 'relative',
    },
    height: 'inherit',
    maxHeight: 'inherit',
    minHeight: 'inherit',
    position: 'relative',
  },
  height: '100%',
  margin: '0px auto',
  maxHeight: '648px',
  minHeight: '324px',
  position: 'relative',
});
