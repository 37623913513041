import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '#addField': {
    '.abyss-icon': {
      marginRight: 'var(--abyss-space-xs)',
      marginTop: 'var(--abyss-space-xs)',
    },
    '&:hover': {
      'span.abyss-icon-symbol:first-of-type': {
        display: 'inline-block',
        visibility: 'visible',
      },
      'span.abyss-icon-symbol:last-of-type': {
        display: 'none',
        visibility: 'hidden',
      },
    },
    fontWeight: 'bold !important',
    'span.abyss-icon-symbol:first-of-type': {
      display: 'none',
      visibility: 'hidden',
    },
    'span.abyss-icon-symbol:last-of-type': {
      display: 'inline-block',
      visibility: 'visible',
    },
  },
});
