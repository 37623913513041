import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Table as TableComponent } from '@src/components/Table-static';
import { sumBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import configuration from './includes/configuration.json';
import { Styles } from './includes/styles';

/**
 * Table
 *
 * Displays a table.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Table = (props) => {
  const { data, labels } = props;

  /**
   * renderHeaderType
   *
   * Displays total text.
   *
   * @returns {Element}
   */
  const renderHeaderType = () => {
    return <Layout.Group alignLayout="left">Type</Layout.Group>;
  };

  /**
   * renderHeaderPercent
   *
   * Displays total percentage of entities as a particular type.
   *
   * @returns {Element}
   */
  const renderHeaderPercent = () => {
    return <Layout.Group alignLayout="center">Percentage (%)</Layout.Group>;
  };

  /**
   * renderHeaderAmount
   *
   * Displays total amount of entities as a particular type.
   *
   * @returns {Element}
   */
  const renderHeaderAmount = () => {
    return <Layout.Group alignLayout="right">Amount</Layout.Group>;
  };

  /**
   * renderCellType
   *
   * Displays the type of entity.
   *
   * @param args
   * @returns {Element}
   */
  const renderCellType = (args) => {
    const { cell } = args;

    const value = labels?.find((label) => {
      return label?.accessor === cell?.value;
    });

    return (
      <Layout.Group alignLayout="left">
        <Text>{value?.text || cell?.value}</Text>
      </Layout.Group>
    );
  };

  /**
   * renderCellPercent
   *
   * Displays the percentage of the entities as a particular type.
   *
   * @param args
   * @returns {Element}
   */
  const renderCellPercent = (args) => {
    const { cell } = args;

    return (
      <Layout.Group alignLayout="center">
        <Text>{cell?.value}%</Text>
      </Layout.Group>
    );
  };

  /**
   * renderCellAmount
   *
   * Displays the amount of entities as a particular type.
   *
   * @param args
   * @returns {Element}
   */
  const renderCellAmount = (args) => {
    const { cell } = args;

    return (
      <Layout.Group alignLayout="right">
        <Text>{cell?.value}</Text>
      </Layout.Group>
    );
  };

  /**
   * renderFooterType
   *
   * Displays total text.
   *
   * @returns {Element}
   */
  const renderFooterType = () => {
    return (
      <Layout.Group alignLayout="left">
        <Text>Total</Text>
      </Layout.Group>
    );
  };

  /**
   * renderFooterPercent
   *
   * Displays total percentage of entities as a particular type.
   *
   * @param args
   * @returns {Element}
   */
  const renderFooterPercent = (args) => {
    const { rows } = args;

    const total = sumBy(rows?.values, 'percent');

    return (
      <Layout.Group alignLayout="center">
        <Text>{total}%</Text>
      </Layout.Group>
    );
  };

  /**
   * renderFooterAmount
   *
   * Displays total amount of entities as a particular type.
   *
   * @param args
   * @returns {Element}
   */
  const renderFooterAmount = (args) => {
    const { rows } = args;

    const total = sumBy(rows?.values, 'amount');

    return (
      <Layout.Group alignLayout="right">
        <Text>{total}</Text>
      </Layout.Group>
    );
  };

  /**
   * Columns for table.
   */
  const theColumns = useMemo(() => {
    return configuration?.initialColumns.map((column) => {
      const theColumn = column;

      switch (theColumn.accessor) {
        case 'amount':
          theColumn.Header = renderHeaderAmount;
          theColumn.Cell = renderCellAmount;
          theColumn.Footer = renderFooterAmount;
          break;
        case 'percent':
          theColumn.Header = renderHeaderPercent;
          theColumn.Cell = renderCellPercent;
          theColumn.Footer = renderFooterPercent;
          break;
        case 'type':
          theColumn.Header = renderHeaderType;
          theColumn.Cell = renderCellType;
          theColumn.Footer = renderFooterType;
          break;
        default:
          break;
      }

      return theColumn;
    });
  }, [labels]);

  return (
    <ErrorHandler location="src/features/ActionPaths/components/charts/Donut/components/Table/Table.jsx">
      <Styles>
        <TableComponent
          {...{
            columns: theColumns,
            configuration,
            rows: data,
          }}
        />
      </Styles>
    </ErrorHandler>
  );
};

Table.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number,
      percent: PropTypes.number,
      type: PropTypes.string,
    })
  ),
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      accessor: PropTypes.string,
      text: PropTypes.string,
    })
  ),
};

Table.defaultProps = {
  data: [],
  labels: [],
};
