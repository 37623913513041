import { Alert } from '@abyss/web/ui/Alert';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { Results } from './components/Results';
import { MainSkeleton } from './components/Skeleton';

export const Main = (props) => {
  const { assets, currentTarget, eid, handleSearch, hasResults, isSearching, riskRecord, targetRef } = props;

  return (
    <ErrorHandler location="src/routes/private/Analysis/screens/EidSearch/components/Layout/Main/Main.jsx">
      {isSearching ? (
        <MainSkeleton />
      ) : (
        !isEmpty(eid) &&
        !isSearching && (
          <React.Fragment>
            {!hasResults ? (
              <Alert title="Not Found" variant="info">
                The EID you are searching for could not be found.
              </Alert>
            ) : (
              <Results
                assets={assets}
                currentTarget={currentTarget}
                handleSearch={handleSearch}
                isSearching={isSearching}
                riskRecord={riskRecord}
                targetRef={targetRef}
              />
            )}
          </React.Fragment>
        )
      )}
    </ErrorHandler>
  );
};

Main.propTypes = {
  assets: PropTypes.shape({}),
  currentTarget: PropTypes.string,
  eid: PropTypes.string,
  handleSearch: PropTypes.func,
  hasResults: PropTypes.bool,
  isSearching: PropTypes.bool,
  riskRecord: PropTypes.shape({
    riskCode: PropTypes.string,
    riskDescription: PropTypes.string,
    riskScore: PropTypes.number,
  }),
  targetRef: PropTypes.shape({
    current: PropTypes.shape({}),
  }),
};

Main.defaultProps = {
  assets: [],
  currentTarget: '',
  eid: '',
  handleSearch: () => {},
  hasResults: false,
  isSearching: false,
  riskRecord: {},
  targetRef: {},
};
