import { config } from '@abyss/web/tools/config';
import { Axios } from '@src/context/Api/includes/Axios';
import { logger } from '@src/includes/logger';

/**
 * SaveActionPathNote
 *
 * Saves a note to an  action path with the remote API via an authenticated request.
 *
 * @param payload
 * @returns {Promise<any>}
 * @constructor
 */
export const SaveActionPathNote = async (payload = {}) => {
  try {
    const theMutationKey = payload?.[0];
    const thePayload = payload?.[1];

    const requestArgs = {
      baseURL: config('API_URL'),
      data: {
        note: thePayload?.note,
      },
      headers: {
        'x-api-endpoint': `/action-paths/${thePayload?.actionPathId}/note`,
      },
      method: 'PUT',
      params: { mutationKey: theMutationKey },
    };

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse.data;
  } catch (error) {
    logger.error('src/requests/mutations/SaveActionPathNote.js -> SaveActionPathNote()', false, error);

    throw error;
  }
};
