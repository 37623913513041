import { Grid } from '@abyss/web/ui/Grid';
import { Indicator } from '@abyss/web/ui/Indicator';
import { Layout } from '@abyss/web/ui/Layout';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Table as TableComponent } from '@src/components/Table-static';
import { isEmpty, isUndefined, merge } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import configuration from './includes/configuration.json';

/**
 * Table: Trusted
 *
 * Values from Sources
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const TrustedSources = (props) => {
  const { record, row } = props;

  const [sources, setSources] = useState({});
  const [rows, setRows] = useState([]);
  const [values, setValues] = useState([]);

  /**
   * sources for values to be displayed in the table.
   */
  useEffect(() => {
    const theSources = {};

    if (!isEmpty(row?.ireRiskRecord?.trustedRecordSources)) {
      row?.ireRiskRecord?.trustedRecordSources?.forEach((trustedSource) => {
        if (isUndefined(theSources[trustedSource?.source])) {
          theSources[trustedSource?.source] = {};
        }

        if (isUndefined(theSources[trustedSource?.source][trustedSource?.value])) {
          theSources[trustedSource?.source][trustedSource?.value] = [];
        }

        if (!theSources[trustedSource?.source][trustedSource?.value].includes(trustedSource?.recordId)) {
          theSources[trustedSource?.source][trustedSource?.value].push(trustedSource?.recordId);
        }
      });
    }

    if (!isEmpty(theSources) && theSources !== sources) {
      setSources(theSources);
    }
  }, [row, record]);

  /**
   * values for values to be displayed in the table.
   */
  useEffect(() => {
    const theValues = [];

    if (!isEmpty(row?.ireRiskRecord?.remediationFindings)) {
      row?.ireRiskRecord?.remediationFindings.forEach((attribute) => {
        const item = {
          type: attribute.attributeType,
          value: attribute?.trustedValue,
        };

        if (!theValues.includes(item)) {
          theValues.push(item);
        }
      });
    }

    if (!isEmpty(theValues) && theValues !== values) {
      setValues(theValues);
    }
  }, [row]);

  /**
   * Rows of data to be used in the data table.
   */
  useEffect(() => {
    const theRows = [];

    Object.keys(sources).forEach((trustedSource) => {
      Object.keys(sources[trustedSource]).forEach((value) => {
        const item = {
          ids: [],
          source: trustedSource,
          values,
        };

        item.ids = sources[trustedSource][value];

        if (!theRows.includes(item)) {
          theRows.push(item);
        }
      });
    });

    if (!isEmpty(theRows) && theRows !== rows) {
      setRows(theRows);
    }
  }, [sources, values]);

  /**
   * renderCellTrustedValue
   *
   * Displays the trusted value from the source within a cell in the table.
   *
   * @param args
   * @returns {*}
   */
  const renderCellTrustedValue = (args) => {
    const { cell, row: theRow } = args;

    if (!isEmpty(theRow?.original?.values)) {
      const value = [];

      theRow?.original?.values.forEach((item) => {
        value.push(item.value);
      });

      return value.join(', ');
    }

    return cell.value;
  };

  /**
   * renderCellTrustedSource
   *
   * Displays the trusted value from the source within a cell in the table.
   *
   * @param args
   * @returns {*|JSX.Element}
   */
  const renderCellTrustedSource = (args) => {
    const { cell, row: theRow } = args;

    if (theRow?.original?.ids?.length > 1) {
      return (
        <div style={{ width: 'auto' }}>
          <Indicator label={theRow?.original?.ids?.length} showZero={false} withBorder>
            <div style={{ marginRight: 'var(--abyss-space-sm)' }}>{cell?.value}</div>
          </Indicator>
        </div>
      );
    }

    return cell?.value;
  };

  /**
   * ExpansionRow
   *
   * Expands/opens a table row on click.
   *
   * @param args
   * @returns {JSX.Element|string}
   * @constructor
   */
  const ExpansionRow = (args) => {
    const { theRow } = args;

    if (!isEmpty(theRow?.ids)) {
      return (
        <Grid css={{ margin: 0 }}>
          <Grid.Col
            span={{
              xs: '100%',
            }}
          >
            <Layout.Stack alignItems="left" grow>
              {theRow?.ids.map((id) => {
                return (
                  <div>
                    <strong>ID:</strong> <code>{id}</code>
                  </div>
                );
              })}
            </Layout.Stack>
          </Grid.Col>
        </Grid>
      );
    }

    return '';
  };

  /**
   * Columns for table.
   */
  const columns = useMemo(() => {
    return configuration?.initialColumns.map((item) => {
      const column = item;

      if (column.Header === 'Trusted Value') {
        column.Cell = renderCellTrustedValue;
      }

      if (column.Header === 'Trusted Source') {
        column.Cell = renderCellTrustedSource;
      }

      return column;
    });
  }, []);

  return (
    <ErrorHandler location="src/common/tables/RiskRecords/components/ExpansionRow/components/Trusted/Trusted.jsx">
      <TableComponent
        {...{
          columns,
          configuration: merge({}, configuration, {
            renderSubComponent: ({ original }) => {
              return <ExpansionRow theRow={original} />;
            },
          }),
          dataKey: `actionPaths-RiskRecord-Trusted-${record?.eid}`,
          rows,
        }}
      />
    </ErrorHandler>
  );
};

TrustedSources.propTypes = {
  record: PropTypes.shape({
    eid: PropTypes.string,
  }),
  row: PropTypes.shape({
    ireRiskRecord: PropTypes.shape({
      remediationFindings: PropTypes.arrayOf(PropTypes.shape({})),
      trustedRecordSources: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

TrustedSources.defaultProps = {
  record: {
    eid: '',
  },
  row: {
    ireRiskRecord: {
      remediationFindings: [],
      trustedRecordSources: [],
    },
  },
};
