import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '.abyss-table-root': {
    '.abyss-table-body': {
      '.abyss-table-row': {
        'td:first-child': {
          flex: 'none !important',
          minWidth: '52px !important',
          width: '52px !important',
        },
      },
    },
    '.abyss-table-head': {
      '.abyss-table-header': {
        'th:first-child': {
          flex: 'none !important',
          minWidth: '52px !important',
          width: '52px !important',
        },
      },
    },
  },
});
