import { useForm } from '@abyss/web/hooks/useForm';
import { useToast } from '@abyss/web/hooks/useToast';
import { Divider } from '@abyss/web/ui/Divider';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Grid } from '@abyss/web/ui/Grid';
import { Layout } from '@abyss/web/ui/Layout';
import { Modal } from '@abyss/web/ui/Modal';
import { TextInputArea } from '@abyss/web/ui/TextInputArea';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { useApi } from '@src/context/Api';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import fields from './includes/fields.json';

const { note } = fields;

/**
 * Modal: Add
 *
 * Displays a modal popup to add notes to the action path.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Add = (props) => {
  const { actionPath, isOpen, setIsOpen } = props;

  const { toast } = useToast();

  const { clearApiCache, useApiMutation } = useApi();

  const [SaveActionPathNote] = useApiMutation('SaveActionPathNote');

  const defaultValues = {
    note: actionPath?.note,
  };

  const form = useForm({ defaultValues });

  /**
   * handleClose
   *
   * Handles the closing of the modal.
   *
   * @returns {Promise<void>}
   */
  const handleClose = async () => {
    form?.reset(defaultValues, {
      keepDirty: false,
      keepDirtyValues: false,
      keepErrors: false,
      keepIsValid: false,
      keepSubmitCount: true,
      keepTouched: false,
      keepValues: false,
    });

    return setIsOpen(false);
  };

  /**
   * handleSubmit
   *
   * Handles the form submission.
   *
   * @param submittedValues
   * @returns {Promise<void>}
   */
  const handleSubmit = async (submittedValues) => {
    await handleClose();

    const toastId = 'actionPath-notes-add';
    toast.show({
      ariaLoadingLabel: 'Saving Notes to Action Path',
      autoClose: false,
      id: `${toastId}-info`,
      isLoading: true,
      message: 'Action Path is preparing to save the notes.',
      title: 'Saving Notes to Action Path...',
      variant: 'info',
    });

    const payload = {
      actionPathId: actionPath?.id,
      note: submittedValues?.note,
    };

    await SaveActionPathNote(payload, {
      onError: (error) => {
        toast.hide(`${toastId}-info`);
        toast.show({
          id: `${toastId}-error`,
          message: 'Unable to save notes.',
          title: 'Saving Notes Failed',
          variant: 'error',
        });
      },
      onSuccess: () => {
        clearApiCache(['GetActionPath']);
        toast.hide(`${toastId}-info`);

        toast.show({
          id: `${toastId}-success`,
          message: 'Notes have been saved.',
          title: 'Saved Notes to Action Path',
          variant: 'success',
        });
      },
    });

    form?.reset(defaultValues, {
      keepDirty: false,
      keepDirtyValues: false,
      keepErrors: false,
      keepIsValid: false,
      keepSubmitCount: true,
      keepTouched: false,
      keepValues: false,
    });
  };

  return (
    <ErrorHandler location="src/features/ActionPaths/components/widgets/Notes/components/AddModal/AddModal.jsx">
      <Modal isOpen={isOpen} onClose={handleClose} title="Edit Note">
        <FormProvider autoComplete="off" highlighted onSubmit={handleSubmit} state={form}>
          <Modal.Section>
            <Grid>
              <Grid.Col
                span={{
                  xs: '100%',
                }}
              >
                <TextInputArea {...note} />
              </Grid.Col>
            </Grid>
          </Modal.Section>
          <Modal.Section>
            <Divider height={1} />
            <Layout.Group alignLayout="right">
              <Button onClick={handleClose} variant="outline">
                Cancel
              </Button>
              <Button isDisabled={isEmpty(form?.getValues('note'))} type="submit" variant="solid">
                Edit
              </Button>
            </Layout.Group>
          </Modal.Section>
        </FormProvider>
      </Modal>
    </ErrorHandler>
  );
};

Add.propTypes = {
  actionPath: PropTypes.shape({
    id: PropTypes.string,
    note: PropTypes.string,
  }),
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
};

Add.defaultProps = {
  actionPath: {},
  isOpen: false,
  setIsOpen: () => {},
};
