import { dayjs } from '@abyss/web/tools/dayjs';
import { Flex } from '@abyss/web/ui/Flex';
import { Grid } from '@abyss/web/ui/Grid';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { SelectInputMulti } from '@abyss/web/ui/SelectInputMulti';
import { Button } from '@src/components/Button';
import { DateRange } from '@src/components/DateRange';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';

import { Styles } from './includes/styles';

/**
 * Filters
 *
 * Provides form fields to control the data returned from the api.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Filters = (props) => {
  const { assets, form } = props;

  const frequency = form?.getValues('frequency');

  const minimumDate = useMemo(() => {
    let date;

    if (['MONTHS', 'WEEKS'].includes(frequency)) {
      date = dayjs(new Date()).subtract(2, 'year').format('MM/DD/YYYY');
    } else {
      date = dayjs(new Date()).subtract(6, 'months').format('MM/DD/YYYY');
    }

    return date;
  }, [frequency]);

  /**
   * Date range restrictions.
   */
  useEffect(() => {
    const dateFrom = form?.getValues('dateRange.start');

    if (['MONTHS', 'WEEKS'].includes(frequency)) {
      // Only allow maximum of 2 years in the past
      if (
        !dayjs(dateFrom).isBetween(
          dayjs(new Date()).subtract(2, 'years').format('MM/DD/YYYY'),
          dayjs(new Date()).format('MM/DD/YYYY'),
          'day'
        )
      ) {
        form?.setValue('dateRange.start', dayjs(new Date()).subtract(2, 'years').format('MM/DD/YYYY'));
      }
    }

    if (frequency === 'DAYS') {
      // Only allow maximum of 6 months in the past
      if (
        !dayjs(dateFrom).isBetween(
          dayjs(new Date()).subtract(6, 'months').format('MM/DD/YYYY'),
          dayjs(new Date()).format('MM/DD/YYYY'),
          'day'
        )
      ) {
        form?.setValue('dateRange.start', dayjs(new Date()).subtract(6, 'months').format('MM/DD/YYYY'));
      }
    }
  }, [frequency]);

  /**
   * Validates the fields
   */
  useEffect(() => {
    form?.validate(
      `attributeTypes`,
      () => {},
      () => {}
    );

    form?.validate(
      `recordType`,
      () => {},
      () => {}
    );

    form?.validate(
      `frequency`,
      () => {},
      () => {}
    );

    form?.validate(
      `dateRange.start`,
      () => {},
      () => {}
    );

    form?.validate(
      `dateRange.end`,
      () => {},
      () => {}
    );
  }, []);

  return (
    <ErrorHandler location="src/routes/private/Dashboards/screens/Operations/components/Filters/Filters.jsx">
      <Styles>
        <Grid css={{ position: 'relative' }}>
          <Grid.Col span={{ lg: '25%', md: '50%', sm: '50%', xs: '50%' }}>
            <SelectInputMulti
              label="Attribute Type"
              model="attributeTypes"
              options={[
                { label: 'DOB', value: 'dob' },
                { label: 'SSN', value: 'ssn' },
              ]}
              placeholder="Please Select --"
              validators={{ required: true }}
            />
          </Grid.Col>
          <Grid.Col span={{ lg: '25%', md: '50%', sm: '50%', xs: '50%' }}>
            <SelectInput
              label="Record Type"
              model="recordType"
              onChange={() => {
                form?.validate(
                  `recordType`,
                  () => {},
                  () => {}
                );
              }}
              options={[
                { label: 'CIP', value: 'cip' },
                { label: 'All', value: 'allTypes' },
              ]}
              placeholder="Please Select --"
              validators={{ required: true }}
            />
          </Grid.Col>
          <Grid.Col span={{ lg: '25%', md: '50%', sm: '50%', xs: '50%' }}>
            <SelectInput
              isClearable
              label="Frequency"
              model="frequency"
              onChange={() => {
                form?.validate(
                  `frequency`,
                  () => {},
                  () => {}
                );
              }}
              options={assets?.ListChronoUnits?.data
                ?.map((chronoUnit) => {
                  return {
                    label: chronoUnit?.codeDesc,
                    value: chronoUnit?.codeId,
                  };
                })
                .filter((chronoUnit) => {
                  return ['DAYS', 'MONTHS', 'WEEKS'].includes(chronoUnit?.value);
                })}
              placeholder="Please Select --"
              validators={{ required: true }}
            />
          </Grid.Col>
          <Grid.Col span={{ lg: '25%', md: '50%', sm: '50%', xs: '50%' }}>
            <Grid css={{ paddingLeft: 0 }}>
              <Grid.Col css={{ paddingLeft: 0, paddingRight: 0 }} span={{ xs: '75%' }}>
                <DateRange
                  defaultEndDate={form?.getValues('dateRange.end')}
                  defaultStartDate={form?.getValues('dateRange.start')}
                  maximumDate={dayjs(new Date()).format('MM/DD/YYYY')}
                  minimumDate={minimumDate}
                  onChange={(event = {}) => {
                    form?.setValue('dateRange.start', String(event?.startDate), {
                      shouldDirty: true,
                      shouldValidate: true,
                    });

                    form?.setValue('dateRange.end', String(event?.endDate), {
                      shouldDirty: true,
                      shouldValidate: true,
                    });

                    form?.validate(
                      `dateRange.start`,
                      () => {},
                      () => {}
                    );

                    form?.validate(
                      `dateRange.end`,
                      () => {},
                      () => {}
                    );
                  }}
                />
              </Grid.Col>
              <Grid.Col span={{ xs: '25%' }}>
                <Flex
                  alignContent="flex-end"
                  alignItems="flex-end"
                  css={{ height: '100%' }}
                  direction="row"
                  justify="flex-start"
                >
                  <Button isDisabled={!isEmpty(form?.formState?.errors)} type="submit" variant="solid">
                    Apply
                  </Button>
                </Flex>
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>
      </Styles>
    </ErrorHandler>
  );
};

Filters.propTypes = {
  assets: PropTypes.shape({
    ListChronoUnits: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          codeDesc: PropTypes.string,
          codeId: PropTypes.string,
        })
      ),
    }),
  }),
  form: PropTypes.shape({
    formState: PropTypes.shape({
      errors: PropTypes.shape({
        attributeTypes: PropTypes.shape({
          shouldDirty: PropTypes.bool,
          shouldValidate: PropTypes.bool,
        }),
        dateRange: PropTypes.shape({
          end: PropTypes.shape({
            shouldDirty: PropTypes.bool,
            shouldValidate: PropTypes.bool,
          }),
          start: PropTypes.shape({
            shouldDirty: PropTypes.bool,
            shouldValidate: PropTypes.bool,
          }),
        }),
        frequency: PropTypes.shape({
          shouldDirty: PropTypes.bool,
          shouldValidate: PropTypes.bool,
        }),
        recordType: PropTypes.shape({
          shouldDirty: PropTypes.bool,
          shouldValidate: PropTypes.bool,
        }),
      }),
    }),
    getValues: PropTypes.func,
    setValue: PropTypes.func,
    validate: PropTypes.func,
  }),
};

Filters.defaultProps = {
  assets: {},
  form: {},
};
