import { Divider } from '@abyss/web/ui/Divider';
import { Layout } from '@abyss/web/ui/Layout';
import { Modal } from '@abyss/web/ui/Modal';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import PropTypes from 'prop-types';
import React from 'react';

import { useSave } from '../../../hooks/useSave';

/**
 * Modal: Deactivation
 *
 * This modal is used to deactivate the selected version of the entity.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Deactivation = (props) => {
  const { currentEntity, isOpen, refetch, setFocusedEntity, setIsOpen, setShowAll } = props;

  const handleDeactivation = useSave('deactivate');

  /**
   * handleClose
   *
   * Handles the closing of the modal.
   *
   * @returns {Promise<void>}
   */
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <ErrorHandler location="src/routes/private/Admin/screens/CommonCriteria/components/modals/Deactivation/Deactivation.jsx">
      <Modal isOpen={isOpen} onClose={handleClose} title="Deactivate Common Criteria">
        <Modal.Section>
          Are you sure you want to deactivate: <strong>{currentEntity?.name}</strong>?
        </Modal.Section>
        <Modal.Section>
          <Divider height={1} />
          <Layout.Group alignLayout="right">
            <Button onClick={handleClose} variant="outline">
              Cancel
            </Button>
            <Button
              onClick={async () => {
                await handleDeactivation(
                  {
                    ...currentEntity,
                    ...{
                      isActive: false,
                    },
                  },
                  refetch
                );
                setFocusedEntity(currentEntity?.name);
                setShowAll(true);
              }}
              variant="destructive"
            >
              Deactivate
            </Button>
          </Layout.Group>
        </Modal.Section>
      </Modal>
    </ErrorHandler>
  );
};

Deactivation.propTypes = {
  currentEntity: PropTypes.shape({
    name: PropTypes.string,
  }),
  isOpen: PropTypes.bool,
  refetch: PropTypes.func,
  setFocusedEntity: PropTypes.func,
  setIsOpen: PropTypes.func,
  setShowAll: PropTypes.func,
};

Deactivation.defaultProps = {
  currentEntity: {},
  isOpen: false,
  refetch: () => {},
  setFocusedEntity: () => {},
  setIsOpen: () => {},
  setShowAll: () => {},
};
