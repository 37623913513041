import { Flex } from '@abyss/web/ui/Flex';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { ToggleSwitch } from '@abyss/web/ui/ToggleSwitch';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { useApi } from '@src/context/Api';
import { CreateMenuButton } from '@src/features/ActionPaths/components/buttons/CreateMenu';
import { ActionPathsTable } from '@src/features/ActionPaths/components/tables/ActionPaths';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';

/**
 * Route: List
 *
 * Provides the user with a screen listing the existing action paths.
 *
 * @returns {Element}
 * @constructor
 */
export const List = () => {
  const [showAll, setShowAll] = useState(false);

  const { clearApiCache, useApiQuery } = useApi();

  const [ListActionPaths, { data, error, isFetching, isLoading, refetch }] = useApiQuery('ListActionPaths');

  /**
   * Invalidate the query cache when the showAll state changes.
   */
  useEffect(() => {
    clearApiCache(['ListActionPaths']);
  }, [showAll]);

  return (
    <ErrorHandler location="src/routes/private/ActionPaths/screens/List/List.jsx">
      <motion.div
        animate="open"
        initial={{ opacity: 0 }}
        variants={{
          closed: { opacity: 0 },
          open: { opacity: 1 },
        }}
      >
        <Grid>
          <Grid.Col
            span={{
              xs: '100%',
            }}
          >
            <Flex alignItems="center" direction="row">
              <Heading offset={0}>Action Paths</Heading>
              <div
                style={{
                  marginLeft: themeConfiguration?.theme?.space?.md,
                }}
              >
                <CreateMenuButton />
              </div>
            </Flex>
          </Grid.Col>
          <Grid.Col
            span={{
              xs: '100%',
            }}
          >
            {!showAll && (
              <ActionPathsTable
                error={error}
                headerLeft={
                  <ToggleSwitch
                    isChecked={showAll}
                    label="Show All Action Paths"
                    onChange={(event) => {
                      return setShowAll(event?.target?.checked);
                    }}
                  />
                }
                isLoading={isLoading || isFetching}
                refetch={refetch}
                requestArgs={{ page: 0, size: 25, sort: 'lastModifiedDate,desc', statusList: ['ACTIVE'] }}
                requestFunction={ListActionPaths}
                requestKey="ListActionPaths"
                rows={data?.content || []}
                totalPages={data?.totalPages || 1}
                totalRecords={data?.totalElements || 0}
              />
            )}

            {showAll && (
              <ActionPathsTable
                error={error}
                headerLeft={
                  <ToggleSwitch
                    isChecked={showAll}
                    label="Show All Action Paths"
                    onChange={(event) => {
                      return setShowAll(event?.target?.checked);
                    }}
                  />
                }
                isLoading={isLoading || isFetching}
                refetch={refetch}
                requestArgs={{ page: 0, size: 25, sort: 'lastModifiedDate,desc' }}
                requestFunction={ListActionPaths}
                requestKey="ListActionPaths"
                rows={data?.content || []}
                totalPages={data?.totalPages || 1}
                totalRecords={data?.totalElements || 0}
              />
            )}
          </Grid.Col>
        </Grid>
      </motion.div>
    </ErrorHandler>
  );
};
