import { config } from '@abyss/web/tools/config';
import { Axios } from '@src/context/Api/includes/Axios';
import { translateCriteria } from '@src/features/ActionPaths/includes/functions';
import { logger } from '@src/includes/logger';
import { isUndefined, omit } from 'lodash';

/**
 * ListRiskRecords
 *
 * Retrieves a list of risk records from the remote API via an authenticated request.
 *
 * @param payload
 * @returns {Promise<any>}
 * @constructor
 */
export const ListRiskRecords = async (payload = {}) => {
  try {
    const theQueryKey = payload[0];
    const thePayload = omit(payload?.[1], ['page', 'size', 'sort']);

    const requestArgs = {
      baseURL: config('API_URL'),
      data: {},
      headers: {
        'x-api-endpoint': '/risk-records/searchByCriteria',
      },
      method: 'POST',
      params: { queryKey: theQueryKey },
    };

    if (!isUndefined(payload?.[1]?.page)) {
      requestArgs.params.page = payload?.[1]?.page;
    }

    if (!isUndefined(payload?.[1]?.size)) {
      requestArgs.params.size = payload?.[1]?.size;
    }

    if (!isUndefined(payload?.[1]?.sort)) {
      requestArgs.params.sort = payload?.[1]?.sort;
    }

    delete thePayload?.criteria?.entrance?.mergedStr;
    delete thePayload?.criteria?.exit?.mergedStr;

    if (!isUndefined(thePayload?.criteria)) {
      requestArgs.data.criteria = translateCriteria(thePayload?.criteria, 'outgoing');
    }

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse?.data;
  } catch (error) {
    logger.error('src/requests/queries/ListRiskRecords.js -> ListRiskRecords()', false, error);

    throw error;
  }
};
