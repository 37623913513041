import { useForm } from '@abyss/web/hooks/useForm';
import { dayjs } from '@abyss/web/tools/dayjs';
import { Alert } from '@abyss/web/ui/Alert';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Loader } from '@src/components/Loader';
import { useApi } from '@src/context/Api';
import { useRoutesContext } from '@src/context/Routes';
import { motion } from 'framer-motion';
import { isEmpty, pull } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

import { Filters, Tabs } from './components';

/**
 * Operations
 *
 * Provides a screen to visualize a risk trend report.
 *
 * @returns {Element}
 * @constructor
 */
export const Operations = () => {
  const { currentRoute } = useRoutesContext();

  const defaultValues = {
    attributeTypes: ['dob'],
    dateRange: {
      end: dayjs(new Date()).format('MM/DD/YYYY'),
      start: dayjs(new Date()).subtract(1, 'year').format('MM/DD/YYYY'),
    },
    frequency: 'WEEKS',
    recordType: 'cip',
  };

  const [hasData, setHasData] = useState(false);

  const form = useForm({ defaultValues });

  const { isSubmitting, isValid } = form.formState;

  const [previouslySubmittedValues, setPreviouslySubmittedValues] = useState(defaultValues);
  const [isLoadingAssets, setIsLoadingAssets] = useState(false);

  const theAssets = ['ListChronoUnits', 'ListEventTypes'];

  const [isLoading, setIsLoading] = useState(false);
  const [theQueries, setTheQueries] = useState([
    {
      args: {
        attributeType: defaultValues?.attributeTypes[0],
        beginDate: dayjs(defaultValues?.dateRange?.start).format('YYYY-MM-DD'),
        endDate: dayjs(defaultValues?.dateRange?.end).format('YYYY-MM-DD'),
        limitToCIPIngested: defaultValues?.recordType === 'cip' ? 'true' : 'false',
        timeUnit: defaultValues?.frequency,
      },
      key: `GetRiskTrendReport-${defaultValues?.attributeTypes[0]}`,
    },
  ]);

  const { useApiQueries } = useApi();

  const assets = useApiQueries(theAssets);
  const queries = useApiQueries(theQueries);

  /**
   * Determines the overall loading state of all queries.
   */
  useEffect(() => {
    if (
      !isEmpty(assets) &&
      Object.keys(assets).length === theAssets.length &&
      isEmpty(
        Object.keys(assets).filter((queryKey) => {
          const query = assets[queryKey];
          return !(!query?.isLoading && !query?.isFetching);
        })
      )
    ) {
      setIsLoadingAssets(false);
    } else {
      setIsLoadingAssets(true);
    }
  }, [assets, theAssets]);

  /**
   * Determines the overall loading state of all queries.
   */
  useEffect(() => {
    if (
      !isEmpty(queries) &&
      Object.keys(queries).length === theQueries.length &&
      isEmpty(
        Object.keys(queries).filter((queryKey) => {
          const query = queries[queryKey];
          return !(!query?.isLoading && !query?.isFetching);
        })
      )
    ) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [queries, theQueries]);

  useEffect(() => {
    if (!isEmpty(queries)) {
      const valuesFound = [];
      Object.keys(queries).forEach((queryKey) => {
        const query = queries[queryKey];

        if (isEmpty(query?.data?.series)) {
          valuesFound.push(false);
        } else {
          valuesFound.push(true);
        }
      });
      pull(valuesFound, false);
      if (!isEmpty(valuesFound)) {
        setHasData(true);
      } else {
        setHasData(false);
      }
    }
  }, [queries]);

  /**
   * handleSubmit
   *
   * Calls a remote API to save the form data into a database.
   *
   * @returns {Promise<void>}
   */
  const handleSubmit = useCallback(
    (submittedValues) => {
      if (!isSubmitting && isValid) {
        setPreviouslySubmittedValues(submittedValues);
        const queriesList = [];

        submittedValues?.attributeTypes.forEach((attributeType) => {
          const payload = {
            attributeType,
            beginDate: dayjs(submittedValues?.dateRange?.start).format('YYYY-MM-DD'),
            endDate: dayjs(submittedValues?.dateRange?.end).format('YYYY-MM-DD'),
            limitToCIPIngested: submittedValues?.recordType === 'cip' ? 'true' : 'false',
            timeUnit: submittedValues?.frequency,
          };

          queriesList.push({
            args: payload,
            key: `GetRiskTrendReport-${attributeType}`,
          });
        });
        setTheQueries(queriesList);
      }
    },
    [isSubmitting, isValid, theQueries]
  );

  return (
    <ErrorHandler location="src/routes/private/Dashboards/screens/Operations/Operations.jsx">
      <motion.div
        animate="open"
        initial={{ opacity: 0 }}
        variants={{
          closed: { opacity: 0 },
          open: { opacity: 1 },
        }}
      >
        <FormProvider autoComplete="off" highlighted onSubmit={handleSubmit} state={form}>
          <Grid>
            <Grid.Col span={{ xs: '100%' }}>
              <Heading offset={0}>{currentRoute?.screenTitle}</Heading>
            </Grid.Col>
            {isLoadingAssets ? (
              <Grid.Col span={{ xs: '100%' }}>
                <Loader verticalAlignment="top" />
              </Grid.Col>
            ) : (
              <React.Fragment>
                <Grid.Col span={{ xs: '100%' }}>
                  <Filters assets={assets} form={form} isLoading={isLoading} queries={queries} />
                </Grid.Col>
                <Grid.Col span={{ xs: '100%' }}>
                  {isLoading || isSubmitting ? (
                    <Loader verticalAlignment="top" />
                  ) : (
                    <React.Fragment>
                      {hasData ? (
                        <Tabs
                          assets={assets}
                          form={form}
                          queries={queries}
                          submittedValues={previouslySubmittedValues}
                        />
                      ) : (
                        <Alert title="No data found with specified filters." variant="info" />
                      )}
                    </React.Fragment>
                  )}
                </Grid.Col>
              </React.Fragment>
            )}
          </Grid>
        </FormProvider>
      </motion.div>
    </ErrorHandler>
  );
};
