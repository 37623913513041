import { Flex } from '@abyss/web/ui/Flex';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { useWizardContext } from '@src/features/ActionPaths/components/misc/Wizard/context';
import { RiskRecordsTable } from '@src/features/Risk/components/tables/RiskRecords';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * RiskRecords
 *
 * Provides the user with a screen to browse the risk records found based on the entrance criteria (filters) specified.
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const RiskRecords = (props) => {
  const { form } = props;

  const { actionPath, currentStep } = useWizardContext();

  const criteria = form?.getValues('criteria');

  return (
    <ErrorHandler location="src/features/ActionPaths/components/misc/Wizard/components/steps/RiskRecords/RiskRecords.jsx">
      <Grid>
        <Grid.Col
          span={{
            xs: '100%',
          }}
        >
          <Flex alignContent="flex-start" direction="column">
            <Flex alignItems="center" direction="row">
              <Heading offset={1}>{currentStep?.label}</Heading>
            </Flex>
            <p>{currentStep?.description}</p>
          </Flex>
        </Grid.Col>
        <Grid.Col
          span={{
            xs: '100%',
          }}
        >
          <RiskRecordsTable
            allowExport
            context="entrance"
            criteria={criteria}
            currentEntity={{
              id: actionPath?.id,
              name: actionPath?.name,
              type: 'actionPath',
            }}
            showAnalyzeRisks
            showFilters
            showTotalRecords
          />
        </Grid.Col>
      </Grid>
    </ErrorHandler>
  );
};

RiskRecords.propTypes = {
  form: PropTypes.shape({
    getValues: PropTypes.func,
  }),
};

RiskRecords.defaultProps = {
  form: {},
};
