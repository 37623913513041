import { useToast } from '@abyss/web/hooks/useToast';
import { Flex } from '@abyss/web/ui/Flex';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { useApi } from '@src/context/Api';
import { motion } from 'framer-motion';
import { isEmpty, isUndefined } from 'lodash';
import React, { useEffect, useState } from 'react';

import { FormModal } from './components/FormModal';
import { Table } from './components/Table';

/**
 * List
 *
 * Provides the user with a screen listing the existing codes.
 *
 * @returns {Element}
 * @constructor
 */
export const List = () => {
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [currentEntity, setCurrentEntity] = useState({});

  const [isLoadingAssets, setIsLoadingAssets] = useState(false);
  const [isLoadingQueries, setIsLoadingQueries] = useState(false);

  const { clearApiCache, useApiMutation, useApiQueries } = useApi();

  const theAssets = [
    {
      args: { page: 0, size: 9999, sort: 'categoryCode,asc' },
      key: 'ListCodeCategories',
    },
  ];

  const assets = useApiQueries(theAssets);

  const [theQueries, setTheQueries] = useState([]);

  const queries = useApiQueries(theQueries);

  const [SaveCode] = useApiMutation('SaveCode');

  const { toast } = useToast();

  /**
   * Determines the overall loading state of all assets.
   */
  useEffect(() => {
    if (
      !isEmpty(assets) &&
      Object.keys(assets).length === theAssets.length &&
      isEmpty(
        Object.keys(assets).filter((assetKey) => {
          const asset = assets[assetKey];
          return !(!asset?.isLoading && !asset?.isFetching);
        })
      )
    ) {
      setIsLoadingAssets(false);
    } else {
      setIsLoadingAssets(true);
    }
  }, [assets, theAssets]);

  /**
   * Sets the default query to be used based on the assets.
   */
  useEffect(() => {
    if (
      isEmpty(theQueries) &&
      !isUndefined(assets?.ListCodeCategories?.data?.content?.[0]?.categoryCode) &&
      !isEmpty(assets?.ListCodeCategories?.data?.content?.[0]?.categoryCode)
    ) {
      const categoryCode = assets?.ListCodeCategories?.data?.content?.[0]?.categoryCode;

      setTheQueries([
        {
          args: {
            categoryCode,
            page: 0,
            size: 9999,
            sort: 'codeId,asc',
          },
          key: `ListCodes-${categoryCode}`,
        },
      ]);
    }
  }, [assets, theQueries]);

  /**
   * Determines the overall loading state of all queries.
   */
  useEffect(() => {
    if (
      !isEmpty(queries) &&
      Object.keys(queries).length === theQueries.length &&
      isEmpty(
        Object.keys(queries).filter((assetKey) => {
          const query = queries[assetKey];
          return !(!query?.isLoading && !query?.isFetching);
        })
      )
    ) {
      setIsLoadingQueries(false);
    } else {
      setIsLoadingQueries(true);
    }
  }, [queries, theQueries]);

  /**
   * handleSave
   *
   * Handles the saving of a code.
   *
   * @param payload
   * @returns {Promise<void>}
   */
  const handleSave = async (payload = {}) => {
    const toastId = 'save-code';

    toast.show({
      ariaLoadingLabel: 'Saving Code',
      autoClose: false,
      id: `${toastId}-info`,
      isLoading: true,
      message: 'Code is preparing to save.',
      title: 'Saving Code...',
      variant: 'info',
    });

    await SaveCode(payload, {
      onError: () => {
        toast.hide(`${toastId}-info`);
        toast.show({
          id: `${toastId}-error`,
          message: `Unable to save code.`,
          title: 'Code Save Failed',
          variant: 'error',
        });
      },
      onSuccess: async () => {
        await clearApiCache(['ListCodes']);

        Object.keys(queries).forEach((queryKey) => {
          const query = queries[queryKey];
          if (query?.refetch) {
            query.refetch();
          }
        });

        toast.hide(`${toastId}-info`);
        toast.show({
          id: `${toastId}-success`,
          message: `Code has been saved.`,
          title: 'Saved Code',
          variant: 'success',
        });
      },
    });
  };

  return (
    <ErrorHandler location="src/routes/private/Admin/screens/Codes/List/List.jsx">
      <motion.div
        animate="open"
        initial={{ opacity: 0 }}
        variants={{
          closed: { opacity: 0 },
          open: { opacity: 1 },
        }}
      >
        <Grid>
          <Grid.Col
            span={{
              xs: '100%',
            }}
          >
            <Flex alignItems="center" direction="row">
              <Heading offset={0}>Codes</Heading>
              <Button
                before={<IconSymbol icon="add" />}
                css={{
                  marginLeft: themeConfiguration?.theme?.space?.md,
                }}
                isDisabled={isLoadingAssets}
                onClick={() => {
                  setIsFormModalOpen(true);
                  setCurrentEntity({});
                }}
                size="$sm"
                variant="outline"
              >
                Create
              </Button>
            </Flex>
          </Grid.Col>
          <Grid.Col
            span={{
              xs: '100%',
            }}
          >
            <Table
              assets={assets}
              isLoading={isLoadingAssets || isLoadingQueries}
              queries={queries}
              setCurrentEntity={setCurrentEntity}
              setIsFormModalOpen={setIsFormModalOpen}
              setTheQueries={setTheQueries}
            />
            {isFormModalOpen && (
              <FormModal
                assets={assets}
                currentEntity={currentEntity}
                handleSave={handleSave}
                isOpen={isFormModalOpen}
                setCurrentEntity={setCurrentEntity}
                setIsOpen={setIsFormModalOpen}
              />
            )}
          </Grid.Col>
        </Grid>
      </motion.div>
    </ErrorHandler>
  );
};
