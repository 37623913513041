import { dayjs } from '@abyss/web/tools/dayjs';
import { Layout } from '@abyss/web/ui/Layout';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Table as TableComponent } from '@src/components/Table-static';
import { useSave } from '@src/features/ActionPaths/hooks/useSave';
import { AppliedFilterCriteria } from '@src/features/Criteria/components/misc/AppliedFilterCriteria';
import { isEmpty, isNull, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import configuration from './includes/configuration.json';
import { Styles } from './includes/styles';

const WithActions = (props) => {
  const { children } = props;

  return <div className="hasActions">{children}</div>;
};

WithActions.propTypes = {
  children: PropTypes.node,
};

WithActions.defaultProps = {
  children: null,
};

/**
 * Table
 *
 * Displays a list of common criteria filters within a DataTable.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Table = (props) => {
  const {
    context,
    fetchCriteria,
    fetchCriteriaVersions,
    filterKey,
    form,
    needsRemoval,
    needsUpdate,
    rows,
    setSelected,
    showActions,
  } = props;

  const criteria = form?.getValues(`criteria`);
  const commonIds = form?.getValues(`criteria.${context}.commonIds`);

  const { handleSave } = useSave();

  /**
   * theRows
   *
   * Merges the rows with the checked state and the needsUpdate and needsRemoval state.
   */
  const theRows = useMemo(() => {
    const items = rows?.map((row) => {
      return {
        ...row,
        ...{
          action: null,
          actionDetails: {},
        },
      };
    });

    if (!isEmpty(needsUpdate)) {
      rows?.forEach((row, index) => {
        if (!isUndefined(needsUpdate[row?.id])) {
          items[index].action = 'update';
          items[index].actionDetails = needsUpdate[row?.id];
          items[index].checkbox = true;
        }
      });
    }

    if (!isEmpty(needsRemoval)) {
      Object.values(needsRemoval).forEach((item) => {
        const theRow = items.find((row) => {
          return row.id === item?.criteria?.id && row?.activeCommonCriteriaVersion?.id === item?.version?.id;
        });

        if (isUndefined(theRow)) {
          items.unshift({
            ...{
              action: 'remove',
              actionDetails: item,
            },
            ...item?.criteria,
          });
        }
      });
    }

    return items;
  }, [rows, needsRemoval, needsUpdate]);

  /**
   * renderCellName
   *
   * Displays the name of the common criteria filter.
   *
   * @param args
   * @returns {*}
   */
  const renderCellName = (args = {}) => {
    const { cell, row } = args;

    return (
      <Tooltip
        align="center"
        content={
          <Layout.Stack alignItems="left" alignLayout="left" grow space={0}>
            <p>
              <strong>Name:</strong>
              <br />
              {row?.original?.name}
            </p>
            {row?.original?.action === 'update' && (
              <p>
                <strong>Version In-Use:</strong>
                <br />
                {row?.original?.actionDetails?.from?.version}
              </p>
            )}

            {row?.original?.isActive === false ? (
              <p>
                <strong>Status:</strong>
                <br />
                Inactive
              </p>
            ) : (
              <p>
                <strong>Activated Version:</strong>
                <br />
                {row?.original?.activeVersionNbr}
              </p>
            )}

            <p>
              <strong>Last Modified On:</strong>
              <br />
              {dayjs(row?.original?.lastModifiedDate).format('MM/DD/YYYY, HH:mm:ss')}
            </p>
            <p>
              <strong>Last Modified By:</strong>
              <br />
              {row?.original?.lastModifiedBy}
            </p>
          </Layout.Stack>
        }
        position="right"
      >
        <span>{cell?.value}</span>
      </Tooltip>
    );
  };

  /**
   * renderCellCriteria
   *
   * Displays the common criteria filters.
   *
   * @param args
   * @returns {Element}
   */
  const renderCellCriteria = (args = {}) => {
    const { row } = args;

    let theFilters = [];

    if (row?.original?.activeCommonCriteriaVersion?.criteria) {
      theFilters = row?.original?.activeCommonCriteriaVersion?.criteria;
    }

    if (row?.original?.actionDetails?.version?.criteria) {
      theFilters = row?.original?.actionDetails?.version?.criteria;
    }

    if (row?.original?.actionDetails?.from?.criteria) {
      theFilters = row?.original?.actionDetails?.from?.criteria;
    }

    return (
      <div style={{ overflow: 'hidden' }}>
        <AppliedFilterCriteria filters={theFilters} showLabel={false} />
      </div>
    );
  };

  /**
   * renderCellActions
   *
   * Displays a button to update the common criteria filter from the latest activated version.
   *
   * @param args
   * @returns {Element}
   */
  const renderCellActions = (args = {}) => {
    const { row } = args;

    return (
      <Layout.Group alignLayout="center" space={0}>
        {isNull(row?.original?.action) && (
          <Button isDisabled variant="solid">
            Update
          </Button>
        )}

        {row?.original?.action === 'update' && (
          <Button
            onClick={async () => {
              const formValues = form?.getValues();

              const payload = { ...formValues };

              payload.criteria.entrance.commonCriteriaVersionsIds =
                payload?.criteria?.entrance?.commonCriteriaVersionsIds.map((id) => {
                  let theId = id;

                  if (id === row?.original?.actionDetails?.from?.id) {
                    theId = row?.original?.actionDetails?.to?.id;
                  }

                  return theId;
                });

              await handleSave(payload);
              fetchCriteria(criteria);
              fetchCriteriaVersions(criteria);
            }}
            variant="solid"
          >
            Update
          </Button>
        )}
        {row?.original?.action === 'remove' && (
          <Button
            onClick={async () => {
              const formValues = form?.getValues();
              const payload = { ...formValues };

              payload.criteria.entrance.commonIds = formValues?.criteria?.entrance?.commonIds.filter((id) => {
                return id !== row?.original?.actionDetails?.criteria?.id;
              });

              payload.criteria.entrance.commonCriteriaVersionsIds =
                formValues?.criteria?.entrance?.commonCriteriaVersionsIds.filter((id) => {
                  return id !== row?.original?.actionDetails?.version?.id;
                });

              const response = await handleSave(payload);
              form?.setValue(`criteria.${context}.commonIds`, response?.criteria?.entrance?.commonIds, {
                shouldDirty: true,
                shouldValidate: true,
              });
              form?.setValue(
                `criteria.${context}.commonCriteriaVersionsIds`,
                response?.criteria?.entrance?.commonCriteriaVersionsIds,
                {
                  shouldDirty: true,
                  shouldValidate: true,
                }
              );
              fetchCriteria(response?.criteria);
              fetchCriteriaVersions(response?.criteria);
            }}
            variant="destructive"
          >
            Remove
          </Button>
        )}
      </Layout.Group>
    );
  };

  /**
   * Columns for the table.
   */
  const columns = useMemo(() => {
    return configuration?.initialColumns
      ?.map((column) => {
        const theColumn = column;

        if (theColumn.Header === 'Name') {
          theColumn.Cell = renderCellName;
        }

        if (theColumn.Header === 'Criteria') {
          theColumn.Cell = renderCellCriteria;
        }

        if (theColumn.Header === 'Actions') {
          theColumn.Cell = renderCellActions;
        }

        return theColumn;
      })
      .filter((column) => {
        if (column.Header === 'Actions') {
          if (showActions === false) {
            return false;
          }

          if (showActions === true && isEmpty(needsUpdate) && isEmpty(needsRemoval)) {
            return false;
          }
        }

        return true;
      });
  }, [showActions, needsUpdate, needsRemoval]);

  const tableConfiguration = useMemo(() => {
    const theConfiguration = {
      ...configuration,
      ...{
        defaultSelectedRows: {},
        showSelection: (row) => {
          return row?.original?.action !== 'remove';
        },
      },
    };

    theRows.forEach((theRow, index) => {
      if (commonIds.includes(theRow?.id)) {
        theConfiguration.defaultSelectedRows[index] = true;
      }

      if (theRow?.action === 'remove') {
        theConfiguration.defaultSelectedRows[index] = true;
      }
    });

    return theConfiguration;
  }, [configuration, theRows, commonIds]);

  return (
    <ErrorHandler location="src/features/Criteria/components/widgets/CommonCriteria/components/Table/Table.jsx">
      <Styles>
        {showActions === false || (showActions === true && isEmpty(needsUpdate) && isEmpty(needsRemoval)) ? (
          <TableComponent
            {...{
              columns,
              configuration: tableConfiguration,
              dataKey: `actionPaths-${filterKey}`,
              onSelection: setSelected,
              rows: theRows,
            }}
          />
        ) : (
          <WithActions>
            <TableComponent
              {...{
                columns,
                configuration: tableConfiguration,
                dataKey: `actionPaths-${filterKey}`,
                onSelection: setSelected,
                rows: theRows,
              }}
            />
          </WithActions>
        )}
      </Styles>
    </ErrorHandler>
  );
};

Table.propTypes = {
  context: PropTypes.string,
  fetchCriteria: PropTypes.func,
  fetchCriteriaVersions: PropTypes.func,
  filterKey: PropTypes.string,
  form: PropTypes.shape({
    getValues: PropTypes.func,
    setValue: PropTypes.func,
  }),
  needsRemoval: PropTypes.shape({
    criteria: PropTypes.shape({
      activeCommonCriteriaVersion: PropTypes.string,
      activeVersionNbr: PropTypes.number,
      context: PropTypes.string,
      createdBy: PropTypes.string,
      createdDate: PropTypes.string,
      id: PropTypes.string,
      isActive: PropTypes.bool,
      lastModifiedBy: PropTypes.string,
      lastModifiedDate: PropTypes.string,
      name: PropTypes.string,
      note: PropTypes.string,
    }),
    version: PropTypes.shape({
      commonCriteriaId: PropTypes.string,
      context: PropTypes.string,
      createdBy: PropTypes.string,
      createdDate: PropTypes.string,
      criteria: PropTypes.arrayOf(
        PropTypes.shape({
          column: PropTypes.string,
          condition: PropTypes.string,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.arrayOf(PropTypes.string)]),
        })
      ),
      id: PropTypes.string,
      lastModifiedBy: PropTypes.string,
      lastModifiedDate: PropTypes.string,
      version: PropTypes.number,
    }),
  }),
  needsUpdate: PropTypes.shape({
    from: PropTypes.shape({
      commonCriteriaId: PropTypes.string,
      context: PropTypes.string,
      createdBy: PropTypes.string,
      createdDate: PropTypes.string,
      criteria: PropTypes.arrayOf(
        PropTypes.shape({
          column: PropTypes.string,
          condition: PropTypes.string,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.arrayOf(PropTypes.string)]),
        })
      ),
      id: PropTypes.string,
      lastModifiedBy: PropTypes.string,
      lastModifiedDate: PropTypes.string,
      version: PropTypes.number,
    }),
    to: PropTypes.shape({
      commonCriteriaId: PropTypes.string,
      createdBy: PropTypes.string,
      createdDate: PropTypes.string,
      criteria: PropTypes.arrayOf(
        PropTypes.shape({
          column: PropTypes.string,
          condition: PropTypes.string,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.arrayOf(PropTypes.string)]),
        })
      ),
      id: PropTypes.string,
      lastModifiedBy: PropTypes.string,
      lastModifiedDate: PropTypes.string,
      version: PropTypes.number,
    }),
  }),
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      activeCommonCriteriaVersion: PropTypes.shape({
        commonCriteriaId: PropTypes.string,
        createdBy: PropTypes.string,
        createdDate: PropTypes.string,
        criteria: PropTypes.arrayOf(
          PropTypes.shape({
            column: PropTypes.string,
            condition: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.arrayOf(PropTypes.string)]),
          })
        ),
        id: PropTypes.string,
        lastModifiedBy: PropTypes.string,
        lastModifiedDate: PropTypes.string,
        version: PropTypes.number,
      }),
      activeVersionNbr: PropTypes.number,
      createdBy: PropTypes.string,
      createdDate: PropTypes.string,
      id: PropTypes.string,
      isActive: PropTypes.bool,
      lastModifiedBy: PropTypes.string,
      lastModifiedDate: PropTypes.string,
      name: PropTypes.string,
      note: PropTypes.string,
    })
  ),
  setSelected: PropTypes.func,
  showActions: PropTypes.bool,
};

Table.defaultProps = {
  context: 'entrance',
  fetchCriteria: () => {},
  fetchCriteriaVersions: () => {},
  filterKey: 'criteria.entrance',
  form: {},
  needsRemoval: {},
  needsUpdate: {},
  rows: [],
  setSelected: () => {},
  showActions: false,
};
