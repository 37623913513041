import { useForm } from '@abyss/web/hooks/useForm';
import { Divider } from '@abyss/web/ui/Divider';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Grid } from '@abyss/web/ui/Grid';
import { Layout } from '@abyss/web/ui/Layout';
import { Modal } from '@abyss/web/ui/Modal';
import { TextInput } from '@abyss/web/ui/TextInput';
import { TextInputArea } from '@abyss/web/ui/TextInputArea';
import { ToggleSwitch } from '@abyss/web/ui/ToggleSwitch';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { fieldValidator } from '@src/includes/validation';
import { isEmpty, merge } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import fields from './includes/fields.json';

const { categoryCode, categoryDesc, code, description, enabled } = fields;

/**
 * FormModal
 *
 * Prompts the user with a popup window allowing them to edit the code category.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const FormModal = (props) => {
  const { currentEntity, handleSave, isOpen, setCurrentEntity, setIsOpen } = props;

  let mode = 'create';

  if (!isEmpty(currentEntity)) {
    mode = 'edit';
  }

  const defaultValues = {
    categoryCode: '',
    categoryDesc: '',
    code: '',
    description: '',
    enabled: true,
  };

  const [isDisabled, setIsDisabled] = useState(true);
  const [initialValues, setInitialValues] = useState({});

  const form = useForm({ defaultValues });

  /**
   * Mapping data loaded from API to the form state.
   */
  useEffect(() => {
    if (!isEmpty(currentEntity)) {
      const data = merge({}, defaultValues, {
        categoryCode: currentEntity?.categoryCode,
        categoryDesc: currentEntity?.categoryDesc,
        code: currentEntity?.code,
        description: currentEntity?.description,
        enabled: currentEntity?.enabled,
      });

      if (isEmpty(initialValues)) {
        setInitialValues(data);
      }

      form?.reset(data, {
        keepDirty: false,
        keepDirtyValues: false,
        keepErrors: false,
        keepIsValid: false,
        keepSubmitCount: true,
        keepTouched: false,
        keepValues: false,
      });
    }
  }, [currentEntity]);

  /**
   * handleClose
   *
   * Handles the closing of the modal.
   *
   * @returns {Promise<void>}
   */
  const handleClose = async () => {
    setIsOpen(false);
    setCurrentEntity({});
  };

  /**
   * handleSubmit
   *
   * Handles the form submission.
   *
   * @param submittedValues
   * @returns {Promise<void>}
   */
  const handleSubmit = async (submittedValues) => {
    await handleClose();

    const payload = {};

    payload.categoryCode = String(submittedValues?.categoryCode);
    payload.categoryDesc = String(submittedValues?.categoryDesc);
    payload.code = String(submittedValues?.code);
    payload.description = String(submittedValues?.description);
    payload.enabled = Boolean(submittedValues?.enabled);

    if (mode === 'create') {
      payload.categoryCode = `X${payload.categoryCode}`;
    }

    await handleSave({
      data: payload,
      mode,
    });
  };

  const formValues = form?.getValues();

  /**
   * isEqual
   *
   * Compares two objects to determine if they are equal.
   *
   * @param object1
   * @param object2
   * @returns {boolean}
   */
  const isEqual = (object1, object2) => {
    return JSON.stringify(object1) === JSON.stringify(object2);
  };

  /**
   * form validation rules, toggling the save button enablement.
   */
  useEffect(() => {
    if (!isEmpty(currentEntity)) {
      if (isEqual(formValues, initialValues) || !form?.formState?.isValid) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    }

    if (isEmpty(currentEntity)) {
      if (!form?.formState?.isValid) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    }
  }, [currentEntity, form?.formState.isDirty, form?.formState?.isValid, formValues, form?.formState?.defaultValues]);

  return (
    <ErrorHandler location="src/routes/private/Admin/screens/Tags/List/components/FormModal/FormModal.jsx">
      <Modal isOpen={isOpen} onClose={handleClose} title={mode === 'edit' ? 'Edit Tag' : 'Create Tag'}>
        <FormProvider autoComplete="off" onSubmit={handleSubmit} state={form}>
          <Modal.Section>
            <Grid>
              <Grid.Col
                span={{
                  xs: '100%',
                }}
              >
                <TextInput
                  {...categoryCode}
                  isDisabled={mode === 'edit'}
                  onChange={(event) => {
                    form?.setValue(`categoryCode`, String(event?.target?.value).toUpperCase());
                    form?.validate(
                      `categoryCode`,
                      () => {},
                      () => {}
                    );
                  }}
                />
              </Grid.Col>
              <Grid.Col
                span={{
                  xs: '100%',
                }}
              >
                <TextInputArea
                  {...categoryDesc}
                  isDisabled={mode === 'edit' && !currentEntity?.userDefined}
                  onChange={() => {
                    form?.validate(
                      `categoryDesc`,
                      () => {},
                      () => {}
                    );
                  }}
                />
              </Grid.Col>
              <Grid.Col
                span={{
                  xs: '100%',
                }}
              >
                <TextInput
                  {...code}
                  isDisabled={mode === 'edit'}
                  onChange={(event) => {
                    form?.setValue(`code`, String(event?.target?.value).toUpperCase());
                    form?.validate(
                      `code`,
                      () => {},
                      () => {}
                    );
                  }}
                  validators={{
                    ...code?.validators,
                    ...{
                      validate: {
                        customValidator: (value) => {
                          return fieldValidator(code, value);
                        },
                      },
                    },
                  }}
                />
              </Grid.Col>
              <Grid.Col
                span={{
                  xs: '100%',
                }}
              >
                <TextInputArea
                  {...description}
                  onChange={() => {
                    form?.validate(
                      `description`,
                      () => {},
                      () => {}
                    );
                  }}
                />
              </Grid.Col>
              {mode === 'edit' && (
                <Grid.Col span={{ xs: '100%' }}>
                  <Layout.Group>
                    <div>Active</div>
                    <ToggleSwitch {...enabled} isDisabled={!currentEntity?.userDefined} />
                  </Layout.Group>
                </Grid.Col>
              )}
            </Grid>
          </Modal.Section>
          <Modal.Section>
            <Divider height={1} />
            <Layout.Group alignLayout="right">
              <Button onClick={handleClose} variant="outline">
                Cancel
              </Button>
              <Button isDisabled={isDisabled} type="submit" variant="solid">
                {mode === 'edit' ? 'Save' : 'Create'}
              </Button>
            </Layout.Group>
          </Modal.Section>
        </FormProvider>
      </Modal>
    </ErrorHandler>
  );
};

FormModal.propTypes = {
  currentEntity: PropTypes.shape({
    categoryCode: PropTypes.string,
    categoryDesc: PropTypes.string,
    code: PropTypes.string,
    description: PropTypes.string,
    enabled: PropTypes.bool,
    userDefined: PropTypes.bool,
  }),
  handleSave: PropTypes.func,
  isOpen: PropTypes.bool,

  setCurrentEntity: PropTypes.func,
  setIsOpen: PropTypes.func,
};

FormModal.defaultProps = {
  currentEntity: {},
  handleSave: () => {},
  isOpen: false,
  setCurrentEntity: () => {},
  setIsOpen: () => {},
};
