import { DrawerMenu } from '@abyss/web/ui/DrawerMenu';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { isNull } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef } from 'react';

import configuration from '../../../../../includes/configuration.json';

/**
 * Navigation
 *
 * @TODO Needs description.
 *
 * @returns {Element}
 * @constructor
 */
export const Navigation = (props) => {
  const { hasDOB, hasSSN, isSidebarOpen, setCurrentTarget, toggleSidebar } = props;
  const buttonRef = useRef(null);

  /**
   * handle the click event for the expand/collapse button
   */
  useEffect(() => {
    const button = document.querySelector('.abyss-drawer-menu-expand-button');

    const handleClick = () => {
      if (isNull(isSidebarOpen) || !isSidebarOpen) {
        toggleSidebar(true);
      } else {
        toggleSidebar(false);
      }
    };

    if (button) {
      button.addEventListener('click', handleClick);
      buttonRef.current = button;

      if (isNull(isSidebarOpen)) {
        buttonRef.current.click();
      }
    }

    return () => {
      if (button) button.removeEventListener('click', handleClick);
    };
  }, [isSidebarOpen]);

  /**
   * add icon component to each menu item
   * add href to each menu item
   * add back to top
   */
  const menuItems = useMemo(() => {
    const theMenuItems = configuration.map((menuItem) => {
      const theMenuItem = { ...menuItem };

      theMenuItem.icon = <IconSymbol color="#000000" icon={menuItem?.icon} variant="outlined" />;
      theMenuItem.href = `#${menuItem?.accessor}`;
      theMenuItem.onClick = () => {
        setCurrentTarget(menuItem?.accessor);
      };

      return theMenuItem;
    });

    theMenuItems.push({
      accessor: 'back-to-top',
      description: '',
      icon: <IconSymbol color="#000000" icon="arrow_circle_up" variant="outlined" />,
      onClick: () => {
        setCurrentTarget(null);
      },
      title: 'Back to Top',
    });

    return theMenuItems.filter((widgetItem) => {
      if (widgetItem?.accessor.includes('dob') && !hasDOB) {
        return false;
      }

      return !(widgetItem?.accessor.includes('ssn') && !hasSSN);
    });
  }, [configuration]);

  return (
    <ErrorHandler>
      <DrawerMenu menuItems={menuItems} />
    </ErrorHandler>
  );
};

Navigation.propTypes = {
  hasDOB: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
  hasSSN: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
  isSidebarOpen: PropTypes.bool,
  setCurrentTarget: PropTypes.func,
  toggleSidebar: PropTypes.func,
};

Navigation.defaultProps = {
  hasDOB: false,
  hasSSN: false,
  isSidebarOpen: false,
  setCurrentTarget: () => {},
  toggleSidebar: () => {},
};
