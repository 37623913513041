import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '.abyss-card-root': {
    '.abyss-card-section': {
      '.pagination': {
        '.abyss-pagination-abbreviated-container, .abyss-pagination-divider': {
          display: 'none',
          visibility: 'hidden',
        },
        '.abyss-pagination-root': {
          border: 'none',
          flexDirection: 'column',
          height: 'auto',
        },
        button: {
          '.abyss-pagination-icon-left, .abyss-pagination-icon-right': {
            '&:hover': {
              color: '#ffffff',
              fill: '#ffffff',
            },
            color: '#0066f5',
            fill: '#0066f5',
            transform: 'rotate(90deg)',
          },
          '&:hover': {
            '.abyss-pagination-icon-left, .abyss-pagination-icon-right': {
              color: '#ffffff',
            },
            backgroundColor: '#0066f5',
            color: '#ffffff',
            fill: '#ffffff',
            transition: 'background-color .33s ease-in-out,border-color .33s ease-in-out',
          },
          backgroundColor: '#ffffff',
          border: '1px solid #0066f5',
          borderRadius: '0px',
          color: '#0066f5',
          fill: '#0066f5',
          height: 'auto',
          margin: 'var(--abyss-space-xs) 0px',
          marginRight: 'calc(var(--abyss-space-xs) + 1.5px)',
          padding: 'var(--abyss-space-xs)',
          transition: 'background-color .33s ease-in-out,border-color .33s ease-in-out',
        },
        display: 'none',
        position: 'absolute',
        right: 'calc(var(--abyss-space-xs) * -1)',
        top: 'calc(50% - 51px)',
        visibility: 'hidden',
      },
      overflowX: 'visible',
      overflowY: 'auto',
      overscrollBehaviorX: 'none',
      overscrollBehaviorY: 'contain',
      padding: 0,
      position: 'relative',
    },
    '&:hover .abyss-card-section .pagination': {
      display: 'flex',
      visibility: 'visible',
    },
    overflowX: 'visible',
    overflowY: 'auto',
    overscrollBehaviorX: 'none',
    overscrollBehaviorY: 'contain',
  },
});
