import { dayjs } from '@abyss/web/tools/dayjs';
import { Grid } from '@abyss/web/ui/Grid';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { SelectInputMulti } from '@abyss/web/ui/SelectInputMulti';
import { Tabs as AbyssTabs } from '@abyss/web/ui/Tabs';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Widget } from '@src/components/Widget';
import { fieldValidator } from '@src/includes/validation';
import fields from '@src/routes/private/Analysis/screens/RiskAnalysis/components/fields/includes/fields.json';
import { isEmpty, isNull, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { LineChart } from '../LineChart';
import { Table } from '../Table';
import { Styles } from './includes/styles';

const options = [
  {
    icon: <IconSymbol icon="timeline" />,
    label: 'Line Chart',
    value: 'LINE_CHART',
  },
  {
    icon: <IconSymbol icon="table_chart" />,
    label: 'Data Table',
    value: 'DATA_TABLE',
  },
];

/**
 * Tabs
 *
 * Displays the data in tbs broken down by Attribute Type and Record Type.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Tabs = (props) => {
  const { assets, queries, submittedValues } = props;

  const [tabs, setTabs] = useState([]);
  const [currentChartType, setCurrentChartType] = useState('LINE_CHART');
  const [selectedEvents, setSelectedEvents] = useState(null);

  /**
   * Defines a list of tabs to render based off data returned from the api.
   */
  useEffect(() => {
    const attributeTypes = submittedValues?.attributeTypes;
    const recordType = submittedValues?.recordType;
    const dateRange = submittedValues?.dateRange;
    const frequency = submittedValues?.frequency;

    if (!isEmpty(attributeTypes) && !isEmpty(queries)) {
      const items = [];

      const theFrequency = assets?.ListChronoUnits?.data?.find((chronoUnit) => {
        return chronoUnit?.codeId === frequency;
      });

      attributeTypes.forEach((attributeType) => {
        const query = queries[`GetRiskTrendReport-${attributeType}`];
        if (!isUndefined(query)) {
          items.push({
            data: query?.data,
            label: String(`${attributeType} - ${recordType}`).toUpperCase(),
            subText: `${dayjs(dateRange?.start).format('MM/DD/YYYY')} - ${dayjs(dateRange?.end).format(
              'MM/DD/YYYY'
            )} - ${theFrequency?.codeDesc}`,
          });
        }
      });

      setTabs(items);
    }
  }, [queries]);

  const widgetMenuOptions = [
    {
      label: 'View as...',
      onChange: setCurrentChartType,
      radios: options,
      title: 'View as...',
      value: currentChartType,
    },
  ];

  return (
    <ErrorHandler location="src/routes/private/Dashboards/screens/Operations/components/Tabs/Tabs.jsx">
      <Styles>
        <AbyssTabs grow={false}>
          {tabs?.map((tab) => {
            return (
              <AbyssTabs.Tab key={tab?.label} label={tab?.label} subText={tab?.subText}>
                <Widget
                  description="Number of entities with each untrusted source over time."
                  menu={widgetMenuOptions || []}
                  title="Untrusted Sources"
                  withCard={false}
                >
                  <Grid>
                    {currentChartType === 'LINE_CHART' && (
                      <React.Fragment>
                        <Grid.Col span={{ xs: '75%' }}>
                          <LineChart
                            data={tab?.data}
                            eventTypes={assets?.ListEventTypes?.data}
                            selectedEvents={
                              isNull(selectedEvents)
                                ? tab?.data?.events?.map((event) => {
                                    return event?.eventId;
                                  })
                                : selectedEvents
                            }
                          />
                        </Grid.Col>
                        <Grid.Col span={{ xs: '25%' }}>
                          <SelectInputMulti
                            label="Events"
                            maxListHeight="400px"
                            onChange={setSelectedEvents}
                            options={tab?.data?.events?.map((event) => {
                              return {
                                label: event?.title,
                                value: event?.eventId,
                              };
                            })}
                            placeholder="Select Event(s)"
                            selectAll
                            validators={{
                              ...fields?.views?.validators,
                              ...{
                                validate: {
                                  customValidator: (value) => {
                                    return fieldValidator(fields?.views, value);
                                  },
                                },
                              },
                            }}
                            value={
                              isNull(selectedEvents)
                                ? tab?.data?.events?.map((event) => {
                                    return event?.eventId;
                                  })
                                : selectedEvents
                            }
                          />
                        </Grid.Col>
                      </React.Fragment>
                    )}
                    {currentChartType === 'DATA_TABLE' && (
                      <Grid.Col span={{ xs: '100%' }}>
                        <Table data={tab?.data} />
                      </Grid.Col>
                    )}
                  </Grid>
                </Widget>
              </AbyssTabs.Tab>
            );
          })}
        </AbyssTabs>
      </Styles>
    </ErrorHandler>
  );
};

Tabs.propTypes = {
  assets: PropTypes.shape({
    ListChronoUnits: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          codeDesc: PropTypes.string,
          codeId: PropTypes.string,
        })
      ),
    }),
    ListEventTypes: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          eventId: PropTypes.string,
          title: PropTypes.string,
        })
      ),
    }),
  }),
  queries: PropTypes.shape({
    GetRiskTrendReport: PropTypes.shape({
      data: PropTypes.shape({
        data: PropTypes.arrayOf(
          PropTypes.shape({
            count: PropTypes.number,
            date: PropTypes.string,
            percChange: PropTypes.number,
          })
        ),
        events: PropTypes.arrayOf(
          PropTypes.shape({
            eventId: PropTypes.string,
            title: PropTypes.string,
          })
        ),
      }),
    }),
  }),
  submittedValues: PropTypes.shape({
    attributeTypes: PropTypes.arrayOf(PropTypes.string),
    dateRange: PropTypes.shape({
      end: PropTypes.string,
      start: PropTypes.string,
    }),
    frequency: PropTypes.string,
    recordType: PropTypes.string,
  }),
};

Tabs.defaultProps = {
  assets: {},
  queries: {},
  submittedValues: {},
};
