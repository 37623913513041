import { config } from '@abyss/web/tools/config';
import { Axios } from '@src/context/Api/includes/Axios';
import { logger } from '@src/includes/logger';
import { orderBy } from 'lodash';

/**
 * GetRiskRecordTimeline
 *
 * Retrieves a single risk record from the remote API via an authenticated request.
 *
 * @param payload
 * @returns {Promise<any>}
 * @constructor
 */
export const GetRiskRecordTimeline = async (payload = {}) => {
  try {
    const theQueryKey = payload?.[0];
    const thePayload = payload?.[1];

    const requestArgs = {
      baseURL: config('API_URL'),
      headers: {
        'x-api-endpoint': `/risk-records/${thePayload?.eid}/timeline`,
      },
      method: 'GET',
      params: { queryKey: theQueryKey },
    };

    const remoteResponse = await Axios.request(requestArgs);
    const sortedTimelines = orderBy(Object.entries(remoteResponse?.data?.timelines), [0], ['desc']);

    return { timelines: Object.fromEntries(sortedTimelines) };
  } catch (error) {
    logger.error('src/requests/queries/GetRiskRecordTimeline.js -> GetRiskRecordTimeline()', false, error);

    throw error;
  }
};
