import { orderBy } from 'lodash';

/**
 * generateTableRowsFromData
 *
 * This function is used to generate the table rows from the data.
 *
 * @param data
 * @returns {*[]}
 */
export function generateTableRowsFromData(data) {
  const theRows = [];

  orderBy(data?.tagInfoList, ['tag'], ['asc']).forEach((tagInfo) => {
    orderBy(tagInfo?.deviationInfoList, ['statDate'], ['asc']).forEach((tagData) => {
      const theRow = {
        [`${tagInfo?.tag}-count`]: tagData?.tagCount,
        date: tagData?.statDate,
        [tagInfo?.tag]: {
          decrease: tagData?.varianceDetails?.totalRemoved,
          increase: tagData?.varianceDetails?.totalAdded,
        },
      };

      const matchedDate = theRows.findIndex((row) => {
        return row?.date === tagData?.statDate;
      });

      if (matchedDate !== -1) {
        theRows[matchedDate] = { ...theRows[matchedDate], ...theRow };
      } else {
        theRows.push(theRow);
      }
    });
  });

  return theRows;
}
