import { useToast } from '@abyss/web/hooks/useToast';
import { useApi } from '@src/context/Api';
import { useCallback } from 'react';

/**
 * Hook: useDuplication
 *
 * Makes an API request to duplicate an action path.
 *
 * @returns {{duplicationEvent: *, handleDuplication: (function({}=, function()=): Promise<*>)}}
 */
export const useDuplication = () => {
  const { clearApiCache, useApiMutation } = useApi();
  const [DuplicateActionPath, mutation] = useApiMutation('DuplicateActionPath');

  const { toast } = useToast();

  const handleDuplication = useCallback(async (variables = {}, refetch = () => {}) => {
    const toastId = 'action-paths-duplication';

    toast.show({
      autoClose: false,
      id: `${toastId}-info`,
      isLoading: true,
      message: 'Preparing to duplicate the Action Path.',
      title: 'Duplicating Action Path',
      variant: 'info',
    });

    const payload = {
      ...variables,
    };

    return await DuplicateActionPath(payload, {
      onError: () => {
        toast.hide(`${toastId}-info`);
        toast.show({
          id: `${toastId}-error`,
          message: 'An error occurred while duplicating the Action Path.',
          title: 'Error Duplicating Action Path',
          variant: 'error',
        });
      },
      onSuccess: () => {
        toast.hide(`${toastId}-info`);

        toast.show({
          id: `${toastId}-success`,
          message: 'Successfully duplicated the Action Path.',
          title: 'Duplicated Action Path',
          variant: 'success',
        });

        clearApiCache([
          'ListActionPaths',
          'ListCommonCriteria',
          'GetActionPath',
          'GetCommonCriteria',
          'GetCommonCriteriaVersion',
        ]);

        refetch();
      },
    });
  }, []);

  return { duplicationEvent: mutation, handleDuplication };
};
